import { useMemo } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import {
    InfiniteData,
    useInfiniteQuery,
    useMutation,
    useQuery,
    useQueryClient,
} from '@tanstack/react-query';
import {
    compareTimeOffset,
    convertOptionToOptionDto,
    durationToDurationDto,
    getProductReferenceToProductMinimal,
    getProductDtoToProduct,
    pricingToPricingDto,
    productCapacityToCapacityDto,
    routeStopsToStopsDto,
} from './product.converters';
import {
    ProductsService,
    ProductType,
    UpdateProduct1111ReqDto,
    UpdateProductReqDto as UpdateProductReq0Dto,
    UpdateProductReq1Dto,
    type GetProductRes1Dto,
    type GetProductResDto as GetProductRes0Dto,
    type CreateProductReqDto as CreateProductReq0Dto,
    type CreateProductReq1Dto,
} from '../../requests';
import {
    CustomInfiniteQueryOptions,
    CustomMutationOptions,
    CustomQueryOptions,
    DurationValue,
} from '../common.types';
import {
    Option,
    Product,
    ProductCapacityItem,
    ProductFinancial,
    ProductMinimal,
    ProductScheduleItem,
    Stop,
} from './product.types';
import { convertItemsToActionItemsDto } from '../common.converters';
import { ScheduleItem } from '../schedule/schedule.types';
import { productScheduleToScheduleDto } from '../schedule/schedule.converters';

export const useProductsLazyKey = 'useProductsLazyKey';
export const useProductsKey = 'useProductsKey';
export const useProductKey = 'useProductKey';

const mergeItems = (item: Product, newItem: Product): Product => ({
    ...item,
    ...newItem,
    type: newItem.type || item.type,
});

type GetProductResDto = GetProductRes0Dto | GetProductRes1Dto;
type CreateProductReqDto = CreateProductReq0Dto | CreateProductReq1Dto;

export const useProductCache = () => {
    const queryClient = useQueryClient();

    const update = (id: string, newItem: Product) => {
        queryClient
            .getQueriesData({
                queryKey: [useProductsLazyKey],
                exact: false,
            })
            .forEach(([queryKey]) => {
                queryClient.setQueryData<InfiniteData<Product[]>>(
                    queryKey,
                    data => {
                        if (!data?.pages) return undefined;
                        const pages = data.pages.map(page => {
                            return (
                                page.map(item =>
                                    item.id === id
                                        ? mergeItems(item, newItem)
                                        : item
                                ) || []
                            );
                        });
                        return { ...data, pages };
                    }
                );
            });
        queryClient
            .getQueriesData({
                queryKey: [useProductKey, id],
                exact: true,
            })
            .forEach(([queryKey]) => {
                queryClient.setQueryData<Product>(queryKey, data => {
                    return data ? mergeItems(data, newItem) : undefined;
                });
            });
    };

    return {
        update,
    };
};

export const useProduct = (
    id?: string,
    options: CustomQueryOptions<Product> = {}
) => {
    const { getAccessTokenSilently } = useAuth0();
    return useQuery({
        queryKey: [useProductKey, id],
        queryFn: async () => {
            const token = await getAccessTokenSilently();
            const authorization = `Bearer ${token}`;
            const response = await ProductsService.getProduct(
                id as string,
                authorization
            );
            return getProductDtoToProduct(response);
        },
        enabled: !!id,
        ...options,
    });
};

// Get new product name
export const useProductName = (
    options: CustomQueryOptions<
        ReturnType<typeof ProductsService.generateNameProducts>
    > = {}
) => {
    const { getAccessTokenSilently } = useAuth0();
    return useQuery({
        queryKey: [],
        queryFn: async () => {
            const token = await getAccessTokenSilently();
            const authorization = `Bearer ${token}`;
            return ProductsService.generateNameProducts(authorization);
        },
        cacheTime: 0,
        ...options,
    });
};

export interface UseProductsData {
    type?: ProductType;
    name?: string;
    startTs?: number;
    endTs?: number;
    currency?: string;
    minPrice?: number;
    maxPrice?: number;
    at_start?: number;
    at_end?: number;
    by?: string;
    action?: 'created' | 'last_updated' | 'deleted';
    text_search?: string;
    groupId?: Array<string>;
    pageNumber?: number;
    pageSize?: number;
}

export const useProducts = (
    params: UseProductsData = {},
    options: CustomQueryOptions<ProductMinimal[]> = {}
) => {
    const { getAccessTokenSilently } = useAuth0();
    return useQuery({
        queryKey: [useProductsKey, params],
        queryFn: async () => {
            const token = await getAccessTokenSilently();
            const authorization = `Bearer ${token}`;
            const response = await ProductsService.getProducts1(
                authorization,
                // TODO uncomment this after back-end supports filters
                // params.type,
                // params.name,
                // params.startTs,
                // params.endTs,
                // params.currency,
                // params.minPrice,
                // params.maxPrice,
                // params.location,
                // params.groupId,
                params.pageNumber,
                params.pageSize || 1000
            );
            return response.items.map(getProductReferenceToProductMinimal);
        },
        staleTime: 30 * 60 * 1000,
        cacheTime: 5 * 60 * 1000,
        ...options,
    });
};

// Get all products lazy
type UseProductsLazyData = UseProductsData;

export const useProductsLazy = (
    params: UseProductsLazyData = {},
    options: CustomInfiniteQueryOptions<Product[]> = {}
) => {
    const { getAccessTokenSilently } = useAuth0();
    const pageSize = params.pageSize || 20;

    const { data, ...other } = useInfiniteQuery({
        queryKey: [useProductsLazyKey, params],
        queryFn: async ({ pageParam = 0 }) => {
            const token = await getAccessTokenSilently();
            const authorization = `Bearer ${token}`;
            const result = await ProductsService.getProducts(
                authorization,
                params.action,
                params.at_start,
                params.at_end,
                undefined,
                params.by,
                params.type ? [params.type] : undefined,
                params.text_search,
                pageParam,
                params.pageSize
            );
            return result.items?.map(getProductDtoToProduct) || [];
        },
        getNextPageParam: (lastPage, allPages) => {
            if (!lastPage || lastPage.length < pageSize) return undefined;
            return allPages.length;
        },
        ...options,
    });

    // TODO move this too common converters
    const parsedData = useMemo(
        () =>
            data?.pages
                ? data.pages.reduce((arr, cur) => [...arr, ...cur], [])
                : undefined,
        [data]
    );

    return {
        data: parsedData,
        ...other,
    };
};

// Add Product

export const useAddProduct = (
    options?: CustomMutationOptions<CreateProductReqDto, Product>
) => {
    const queryClient = useQueryClient();
    const { getAccessTokenSilently } = useAuth0();

    return useMutation({
        mutationFn: async data => {
            const token = await getAccessTokenSilently();
            const authorization = `Bearer ${token}`;
            const response = await ProductsService.createProduct(
                authorization,
                data
            );
            return getProductDtoToProduct(response);
        },
        ...options,
        onSuccess: (...args) => {
            queryClient.invalidateQueries({
                queryKey: [useProductsLazyKey],
                exact: false,
            });
            queryClient.invalidateQueries({
                queryKey: [useProductsKey],
                exact: false,
            });
            options?.onSuccess?.(...args);
        },
    });
};

export const useDeleteProduct = (
    options?: CustomMutationOptions<
        {
            productId: string;
        },
        Product
    >
) => {
    const queryClient = useQueryClient();
    const { getAccessTokenSilently } = useAuth0();

    return useMutation({
        mutationFn: async ({ productId }) => {
            const token = await getAccessTokenSilently();
            const authorization = `Bearer ${token}`;
            const response = await ProductsService.deleteProduct(
                productId,
                authorization
            );
            return getProductDtoToProduct(response as GetProductResDto);
        },
        ...options,
        onSuccess: (...args) => {
            queryClient.invalidateQueries({
                queryKey: [useProductsLazyKey],
                exact: false,
            });
            queryClient.invalidateQueries({
                queryKey: [useProductsKey],
                exact: false,
            });
            queryClient.invalidateQueries({
                queryKey: [useProductKey, args[1].productId],
                exact: true,
            });
            options?.onSuccess?.(...args);
        },
    });
};

// Update Product
export const useUpdateProductConfiguration = (
    options?: CustomMutationOptions<
        { productId: string; data: UpdateProduct1111ReqDto },
        Product
    >
) => {
    const { getAccessTokenSilently } = useAuth0();
    const { update } = useProductCache();

    return useMutation({
        mutationFn: async ({ productId, data }) => {
            const token = await getAccessTokenSilently();
            const authorization = `Bearer ${token}`;
            const response = await ProductsService.updateProduct1111(
                productId,
                authorization,
                data
            );
            return getProductDtoToProduct(response as GetProductResDto);
        },
        ...options,
        onSuccess: (...args) => {
            update(args[1].productId, args[0]);
            options?.onSuccess?.(...args);
        },
        onError: (error, ...args) => {
            // @ts-ignore
            if (error?.status === 409) {
                options?.onSuccess?.({} as Product, ...args);
            } else {
                options?.onError?.(error, ...args);
            }
        },
    });
};

export const useUpdateProductDetails = (
    options?: CustomMutationOptions<
        {
            productId: string;
            data: UpdateProductReq0Dto | UpdateProductReq1Dto;
        },
        Product
    >
) => {
    const { getAccessTokenSilently } = useAuth0();
    const { update } = useProductCache();

    return useMutation({
        mutationFn: async ({ productId, data }) => {
            const token = await getAccessTokenSilently();
            const authorization = `Bearer ${token}`;
            const response = await ProductsService.updateProduct(
                productId,
                authorization,
                data
            );
            return getProductDtoToProduct(response as GetProductResDto);
        },
        ...options,
        onSuccess: (...args) => {
            update(args[1].productId, args[0]);
            options?.onSuccess?.(...args);
        },
    });
};

export const useUpdateProductRoute = (
    oldItems: Stop[],
    options?: CustomMutationOptions<
        {
            productId: string;
            items: Stop[];
        },
        Product | null
    >
) => {
    const { getAccessTokenSilently } = useAuth0();
    const { update } = useProductCache();

    return useMutation({
        mutationFn: async ({ productId, items }) => {
            const requestItems = convertItemsToActionItemsDto(
                routeStopsToStopsDto(oldItems),
                routeStopsToStopsDto(items),
                'id',
                (oldItem, newItem) =>
                    oldItem.location?.name !== newItem.location?.name ||
                    !compareTimeOffset(
                        oldItem.schedule?.stop_duration,
                        newItem.schedule?.stop_duration
                    ) ||
                    !compareTimeOffset(
                        oldItem.schedule?.arrival_offset,
                        newItem.schedule?.arrival_offset
                    )
            );

            if (requestItems.length) {
                const token = await getAccessTokenSilently();
                const authorization = `Bearer ${token}`;
                const response = await ProductsService.updateProduct1(
                    productId,
                    authorization,
                    {
                        round_trip: items[0]?.repeatLocation,
                        items: requestItems,
                    }
                );
                return getProductDtoToProduct(response as GetProductResDto);
            }
            return null;
        },
        ...options,
        onSuccess: (...args) => {
            if (args[0]) update(args[1].productId, args[0]);
            options?.onSuccess?.(...args);
        },
    });
};

export const useUpdateProductCapacities = (
    oldItems: ProductCapacityItem[],
    options: CustomMutationOptions<
        {
            productId: string;
            items: ProductCapacityItem[];
        },
        Product | null
    > = {}
) => {
    const { getAccessTokenSilently } = useAuth0();
    const { update } = useProductCache();

    return useMutation({
        mutationFn: async ({ productId, items }) => {
            const requestItems = convertItemsToActionItemsDto(
                oldItems.map(productCapacityToCapacityDto),
                items.map(productCapacityToCapacityDto),
                'reference.id'
            );

            if (requestItems.length) {
                const token = await getAccessTokenSilently();
                const authorization = `Bearer ${token}`;
                const response = await ProductsService.updateCapacityProducts(
                    productId,
                    authorization,
                    {
                        items: requestItems,
                    }
                );
                return getProductDtoToProduct(response as GetProductResDto);
            }
            return null;
        },
        ...options,
        onSuccess: (...args) => {
            if (args[0]) update(args[1].productId, args[0]);
            options?.onSuccess?.(...args);
        },
    });
};

export const useUpdateProductSchedules = (
    oldSchedules: {
        items: ProductScheduleItem[];
        duration?: DurationValue;
        swing?: string;
    },
    options: CustomMutationOptions<
        {
            items: (ProductScheduleItem | ScheduleItem)[];
            duration?: DurationValue;
            swing?: string;
            productId: string;
        },
        Product | null
    > = {}
) => {
    const { getAccessTokenSilently } = useAuth0();
    const { update } = useProductCache();
    const {
        items: oldItems,
        duration: oldDurationValue,
        swing: oldSwing,
    } = oldSchedules;

    return useMutation({
        mutationFn: async ({ productId, items, duration, swing }) => {
            const oldDuration = durationToDurationDto(
                oldDurationValue,
                oldSwing
            );
            const newDuration = durationToDurationDto(duration, swing);
            const requestItems = convertItemsToActionItemsDto(
                oldItems.map(s => productScheduleToScheduleDto(s, oldDuration)),
                items.map(s => productScheduleToScheduleDto(s, newDuration)),
                'reference.id',
                (oldItem, newItem) =>
                    !compareTimeOffset(
                        oldItem.duration?.estimated,
                        newItem.duration?.estimated
                    ) ||
                    !compareTimeOffset(
                        oldItem.duration?.deviation,
                        newItem.duration?.deviation
                    )
            );

            if (requestItems.length) {
                const token = await getAccessTokenSilently();
                const authorization = `Bearer ${token}`;
                const response = await ProductsService.updateCapacityProducts1(
                    productId,
                    authorization,
                    {
                        items: requestItems,
                    }
                );
                return getProductDtoToProduct(response as GetProductResDto);
            }
            return null;
        },
        ...options,
        onSuccess: (...args) => {
            if (args[0]) update(args[1].productId, args[0]);
            options?.onSuccess?.(...args);
        },
    });
};

export const useUpdateProductPricing = (
    oldItems?: ProductFinancial,
    options: CustomMutationOptions<
        {
            productId: string;
            data: ProductFinancial;
        },
        Product | null
    > = {}
) => {
    const { getAccessTokenSilently } = useAuth0();
    const { update } = useProductCache();

    return useMutation({
        mutationFn: async ({ productId, data: { items, ...financial } }) => {
            const requestItems = convertItemsToActionItemsDto(
                oldItems?.items.map(pricingToPricingDto) || [],
                items.map(pricingToPricingDto),
                'id',
                (oldItem, newItem) =>
                    JSON.stringify(oldItem) !== JSON.stringify(newItem)
            );

            if (requestItems.length) {
                const token = await getAccessTokenSilently();
                const authorization = `Bearer ${token}`;
                const response = await ProductsService.updateProduct11(
                    productId,
                    authorization,
                    {
                        payment_requirements: {
                            methods: financial.paymentMethods,
                            prepayment: {
                                required: !!financial.prepayment,
                                type: financial.prepaymentType,
                                amount: financial.prepaymentAmount,
                            },
                        },
                        items: requestItems,
                    }
                );
                return getProductDtoToProduct(response as GetProductResDto);
            }
            return null;
        },
        ...options,
        onSuccess: (...args) => {
            if (args[0]) update(args[1].productId, args[0]);
            options?.onSuccess?.(...args);
        },
    });
};

export const useUpdateProductOptions = (
    oldItems: Option[],
    options: CustomMutationOptions<
        {
            productId: string;
            items: Option[];
        },
        Product | null
    > = {}
) => {
    const { getAccessTokenSilently } = useAuth0();
    const { update } = useProductCache();

    return useMutation({
        mutationFn: async ({ productId, items }) => {
            const requestItems = convertItemsToActionItemsDto(
                oldItems.map(convertOptionToOptionDto),
                items.map(convertOptionToOptionDto),
                'id',
                (oldItem, newItem) =>
                    JSON.stringify(oldItem) !== JSON.stringify(newItem)
            );

            if (requestItems.length) {
                const token = await getAccessTokenSilently();
                const authorization = `Bearer ${token}`;
                const response = await ProductsService.updateProduct111(
                    productId,
                    authorization,
                    {
                        items: requestItems,
                    }
                );
                return getProductDtoToProduct(response as GetProductResDto);
            }
            return null;
        },
        ...options,
        onSuccess: (...args) => {
            if (args[0]) update(args[1].productId, args[0]);
            options?.onSuccess?.(...args);
        },
    });
};

export const useShareProduct = (
    options?: CustomMutationOptions<
        {
            productId: string;
        },
        Product
    >
) => {
    const { getAccessTokenSilently } = useAuth0();
    const { update } = useProductCache();

    return useMutation({
        mutationFn: async ({ productId }) => {
            const token = await getAccessTokenSilently();
            const authorization = `Bearer ${token}`;
            const response = await ProductsService.updateProduct11111(
                productId,
                authorization
            );
            return getProductDtoToProduct(response);
        },
        ...options,
        onSuccess: (...args) => {
            update(args[1].productId, args[0]);
            options?.onSuccess?.(...args);
        },
    });
};

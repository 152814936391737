import { useAuth0 } from '@auth0/auth0-react';
import {
    useQuery,
    useInfiniteQuery,
    useMutation,
    UseQueryOptions,
    UseInfiniteQueryOptions,
    UseMutationOptions,
} from '@tanstack/react-query';
import {
    UploadAvatarUsersReqDto,
    UploadAvatarUsersReq1Dto,
    UpdateUserReqDto,
    UpdateUserReqAccessGroupsItemDto,
    UpdateTransactionsBookingsReqDto,
    UpdateStatusBookingsReqDto,
    UpdateStaffEventsReqDto,
    UpdateStaffEventsReq1Dto,
    UpdateProductReqDto,
    UpdateProductReq1Dto,
    UpdateProductOptionsBookingsReqDto,
    UpdateProduct1ReqDto,
    UpdateProduct1Req1Dto,
    UpdateProduct11ReqDto,
    UpdateProduct111ReqDto,
    UpdateProduct1111ReqDto,
    UpdateMembersTeamsReqDto,
    UpdateCustomersBookingsReqDto,
    UpdateCustomersBookingsReq1Dto,
    UpdateCapacityProductsReqDto,
    UpdateCapacityProducts1ReqDto,
    UpdateBookingSourceBookingsReqDto,
    UpdateBookingSourceBookingsReq1Dto,
    UpdateBookingNotesBookingsReqDto,
    UpdateAssetsEventsReqDto,
    UpdateAssetsEventsReq1Dto,
    RegisterUsersReqDto,
    ProductType,
    PeriodData,
    OrderStatus,
    GetTeams1ReqDto,
    EventStatus,
    DiscountData,
    CurrencySetting,
    CreateTeamReqDto,
    CreateProductReqDto,
    CreateProductReq1Dto,
    CreateOrderReqDto,
    CreateManySchedulesReqDto,
    CreateManyCapacitiesReqDto,
    CreateEventReqDto,
    CreateEventReq1Dto,
    CreateCustomerReqDto,
    CreateBookingReqProductDto,
    CreateBookingReqDto,
    CreateBookingReq1Dto,
    CreateAssetReqDto,
    ComputeAvailabilityReqDto,
    CancelOrdersReqDto,
    CancelEventsReqDto,
    BookingStatus,
    UsersService,
    TeamsService,
    SchedulesService,
    ProjectService,
    ProductsService,
    OrdersService,
    EventsService,
    CustomersService,
    CapacitiesService,
    BookingsService,
    AssetsService,
} from '../requests';

export const useRegisterUsers = (
    options?: Omit<
        UseMutationOptions<
            Awaited<ReturnType<typeof UsersService.registerUsers>>,
            unknown,
            {
                requestBody: RegisterUsersReqDto;
            },
            unknown
        >,
        'mutationFn'
    >
) => {
    const { getAccessTokenSilently } = useAuth0();
    return useMutation(async ({ requestBody }) => {
        const token = await getAccessTokenSilently();
        const authorization = `Bearer ${token}`;
        return UsersService.registerUsers(authorization, requestBody);
    }, options);
};
export const useGetUserKey = 'UsersServiceGetUser';
export const useGetUser = <TQueryKey extends Array<unknown> = unknown[]>(
    queryKey?: TQueryKey,
    options?: Omit<
        UseQueryOptions<
            Awaited<ReturnType<typeof UsersService.getUser>>,
            unknown,
            Awaited<ReturnType<typeof UsersService.getUser>>,
            unknown[]
        >,
        'queryKey' | 'queryFn' | 'initialData'
    >
) => {
    const { getAccessTokenSilently } = useAuth0();
    return useQuery(
        [useGetUserKey, ...(queryKey ?? [])],
        async () => {
            const token = await getAccessTokenSilently();
            const authorization = `Bearer ${token}`;
            return UsersService.getUser(authorization);
        },
        options
    );
};
export const useUpdateUser = (
    options?: Omit<
        UseMutationOptions<
            Awaited<ReturnType<typeof UsersService.updateUser>>,
            unknown,
            {
                requestBody: UpdateUserReqDto;
            },
            unknown
        >,
        'mutationFn'
    >
) => {
    const { getAccessTokenSilently } = useAuth0();
    return useMutation(async ({ requestBody }) => {
        const token = await getAccessTokenSilently();
        const authorization = `Bearer ${token}`;
        return UsersService.updateUser(authorization, requestBody);
    }, options);
};
export const useUploadAvatarUsers = (
    options?: Omit<
        UseMutationOptions<
            Awaited<ReturnType<typeof UsersService.uploadAvatarUsers>>,
            unknown,
            {
                requestBody: UploadAvatarUsersReqDto | UploadAvatarUsersReq1Dto;
            },
            unknown
        >,
        'mutationFn'
    >
) => {
    const { getAccessTokenSilently } = useAuth0();
    return useMutation(async ({ requestBody }) => {
        const token = await getAccessTokenSilently();
        const authorization = `Bearer ${token}`;
        return UsersService.uploadAvatarUsers(authorization, requestBody);
    }, options);
};
export const useUpdateCurrencyUsers = (
    options?: Omit<
        UseMutationOptions<
            Awaited<ReturnType<typeof UsersService.updateCurrencyUsers>>,
            unknown,
            {
                requestBody: CurrencySetting;
            },
            unknown
        >,
        'mutationFn'
    >
) => {
    const { getAccessTokenSilently } = useAuth0();
    return useMutation(async ({ requestBody }) => {
        const token = await getAccessTokenSilently();
        const authorization = `Bearer ${token}`;
        return UsersService.updateCurrencyUsers(authorization, requestBody);
    }, options);
};
export const useAcceptInvitationUsers = (
    options?: Omit<
        UseMutationOptions<
            Awaited<ReturnType<typeof UsersService.acceptInvitationUsers>>,
            unknown,
            {
                key: string;
            },
            unknown
        >,
        'mutationFn'
    >
) => {
    const { getAccessTokenSilently } = useAuth0();
    return useMutation(async ({ key }) => {
        const token = await getAccessTokenSilently();
        const authorization = `Bearer ${token}`;
        return UsersService.acceptInvitationUsers(key, authorization);
    }, options);
};
export const useGetTeamsKey = 'TeamsServiceGetTeams';
export const useGetTeams = <TQueryKey extends Array<unknown> = unknown[]>(
    queryKey?: TQueryKey,
    options?: Omit<
        UseQueryOptions<
            Awaited<ReturnType<typeof TeamsService.getTeams>>,
            unknown,
            Awaited<ReturnType<typeof TeamsService.getTeams>>,
            unknown[]
        >,
        'queryKey' | 'queryFn' | 'initialData'
    >
) => {
    const { getAccessTokenSilently } = useAuth0();
    return useQuery(
        [useGetTeamsKey, ...(queryKey ?? [])],
        async () => {
            const token = await getAccessTokenSilently();
            const authorization = `Bearer ${token}`;
            return TeamsService.getTeams(authorization);
        },
        options
    );
};
export const useCreateTeam = (
    options?: Omit<
        UseMutationOptions<
            Awaited<ReturnType<typeof TeamsService.createTeam>>,
            unknown,
            {
                requestBody: CreateTeamReqDto;
            },
            unknown
        >,
        'mutationFn'
    >
) => {
    const { getAccessTokenSilently } = useAuth0();
    return useMutation(async ({ requestBody }) => {
        const token = await getAccessTokenSilently();
        const authorization = `Bearer ${token}`;
        return TeamsService.createTeam(authorization, requestBody);
    }, options);
};
export const useGetTeamKey = 'TeamsServiceGetTeam';
export const useGetTeam = <TQueryKey extends Array<unknown> = unknown[]>(
    {
        teamId,
    }: {
        teamId: string;
    },
    queryKey?: TQueryKey,
    options?: Omit<
        UseQueryOptions<
            Awaited<ReturnType<typeof TeamsService.getTeam>>,
            unknown,
            Awaited<ReturnType<typeof TeamsService.getTeam>>,
            unknown[]
        >,
        'queryKey' | 'queryFn' | 'initialData'
    >
) => {
    const { getAccessTokenSilently } = useAuth0();
    return useQuery(
        [useGetTeamKey, ...(queryKey ?? [{ teamId }])],
        async () => {
            const token = await getAccessTokenSilently();
            const authorization = `Bearer ${token}`;
            return TeamsService.getTeam(teamId, authorization);
        },
        options
    );
};
export const useUpdateMembersTeams = (
    options?: Omit<
        UseMutationOptions<
            Awaited<ReturnType<typeof TeamsService.updateMembersTeams>>,
            unknown,
            {
                teamId: string;
                requestBody: UpdateMembersTeamsReqDto;
            },
            unknown
        >,
        'mutationFn'
    >
) => {
    const { getAccessTokenSilently } = useAuth0();
    return useMutation(async ({ teamId, requestBody }) => {
        const token = await getAccessTokenSilently();
        const authorization = `Bearer ${token}`;
        return TeamsService.updateMembersTeams(
            teamId,
            authorization,
            requestBody
        );
    }, options);
};
export const useGetTeams1 = (
    options?: Omit<
        UseMutationOptions<
            Awaited<ReturnType<typeof TeamsService.getTeams1>>,
            unknown,
            {
                requestBody: GetTeams1ReqDto;
            },
            unknown
        >,
        'mutationFn'
    >
) => {
    const { getAccessTokenSilently } = useAuth0();
    return useMutation(async ({ requestBody }) => {
        const token = await getAccessTokenSilently();
        const authorization = `Bearer ${token}`;
        return TeamsService.getTeams1(authorization, requestBody);
    }, options);
};
export const useGetSchedulesKey = 'SchedulesServiceGetSchedules';
export const useGetSchedules = <TQueryKey extends Array<unknown> = unknown[]>(
    {
        ids,
        pageNumber,
        pageSize,
    }: {
        ids?: Array<string>;
        pageNumber?: number;
        pageSize?: number;
    },
    queryKey?: TQueryKey,
    options?: Omit<
        UseQueryOptions<
            Awaited<ReturnType<typeof SchedulesService.getSchedules>>,
            unknown,
            Awaited<ReturnType<typeof SchedulesService.getSchedules>>,
            unknown[]
        >,
        'queryKey' | 'queryFn' | 'initialData'
    >
) => {
    const { getAccessTokenSilently } = useAuth0();
    return useQuery(
        [useGetSchedulesKey, ...(queryKey ?? [{ ids, pageNumber, pageSize }])],
        async () => {
            const token = await getAccessTokenSilently();
            const authorization = `Bearer ${token}`;
            return SchedulesService.getSchedules(
                authorization,
                ids,
                pageNumber,
                pageSize
            );
        },
        options
    );
};
export const useGetSchedulesLazyLazyKey = 'SchedulesServiceGetSchedules';
export const useGetSchedulesLazy = <TQueryKey extends Array<any> = any[]>(
    {
        ids,
        pageNumber,
        pageSize = 20,
    }: {
        ids?: Array<string>;
        pageNumber?: number;
        pageSize?: number;
    },
    queryKey?: TQueryKey,
    options?: Omit<
        UseInfiniteQueryOptions<
            Awaited<ReturnType<typeof SchedulesService.getSchedules>>,
            unknown,
            Awaited<ReturnType<typeof SchedulesService.getSchedules>>,
            Awaited<ReturnType<typeof SchedulesService.getSchedules>>,
            any[]
        >,
        'queryKey' | 'queryFn' | 'initialData'
    >
) => {
    const { getAccessTokenSilently } = useAuth0();
    return useInfiniteQuery(
        [
            useGetSchedulesLazyLazyKey,
            ...(queryKey ?? [{ ids, pageNumber, pageSize }]),
        ],
        {
            queryFn: async ({ pageParam = 0 }) => {
                const token = await getAccessTokenSilently();
                const authorization = `Bearer ${token}`;
                return SchedulesService.getSchedules(
                    authorization,
                    ids,
                    pageParam,
                    pageSize
                );
            },
            getNextPageParam: (lastPage, allPages) => {
                if (!lastPage.items || lastPage.items.length < pageSize)
                    return undefined;
                return allPages.length;
            },
            ...options,
        }
    );
};
export const useCreateManySchedules = (
    options?: Omit<
        UseMutationOptions<
            Awaited<ReturnType<typeof SchedulesService.createManySchedules>>,
            unknown,
            {
                requestBody: CreateManySchedulesReqDto;
            },
            unknown
        >,
        'mutationFn'
    >
) => {
    const { getAccessTokenSilently } = useAuth0();
    return useMutation(async ({ requestBody }) => {
        const token = await getAccessTokenSilently();
        const authorization = `Bearer ${token}`;
        return SchedulesService.createManySchedules(authorization, requestBody);
    }, options);
};
export const useGetProjectKey = 'ProjectServiceGetProject';
export const useGetProject = <TQueryKey extends Array<unknown> = unknown[]>(
    {
        subscriptionKey,
    }: {
        subscriptionKey: string;
    },
    queryKey?: TQueryKey,
    options?: Omit<
        UseQueryOptions<
            Awaited<ReturnType<typeof ProjectService.getProject>>,
            unknown,
            Awaited<ReturnType<typeof ProjectService.getProject>>,
            unknown[]
        >,
        'queryKey' | 'queryFn' | 'initialData'
    >
) => {
    const { getAccessTokenSilently } = useAuth0();
    return useQuery(
        [useGetProjectKey, ...(queryKey ?? [{ subscriptionKey }])],
        async () => {
            const token = await getAccessTokenSilently();
            const authorization = `Bearer ${token}`;
            return ProjectService.getProject(subscriptionKey);
        },
        options
    );
};
export const useComputeAvailability = (
    options?: Omit<
        UseMutationOptions<
            Awaited<ReturnType<typeof ProductsService.computeAvailability>>,
            unknown,
            {
                requestBody: ComputeAvailabilityReqDto;
            },
            unknown
        >,
        'mutationFn'
    >
) => {
    const { getAccessTokenSilently } = useAuth0();
    return useMutation(async ({ requestBody }) => {
        const token = await getAccessTokenSilently();
        const authorization = `Bearer ${token}`;
        return ProductsService.computeAvailability(authorization, requestBody);
    }, options);
};
export const useGetProductsKey = 'ProductsServiceGetProducts';
export const useGetProducts = <TQueryKey extends Array<unknown> = unknown[]>(
    {
        action,
        atStart,
        atEnd,
        byId,
        byName,
        types,
        textSearch,
        pageNumber,
        pageSize,
    }: {
        action?: 'created' | 'last_updated' | 'deleted';
        atStart?: number;
        atEnd?: number;
        byId?: string;
        byName?: string;
        types?: Array<ProductType>;
        textSearch?: string;
        pageNumber?: number;
        pageSize?: number;
    },
    queryKey?: TQueryKey,
    options?: Omit<
        UseQueryOptions<
            Awaited<ReturnType<typeof ProductsService.getProducts>>,
            unknown,
            Awaited<ReturnType<typeof ProductsService.getProducts>>,
            unknown[]
        >,
        'queryKey' | 'queryFn' | 'initialData'
    >
) => {
    const { getAccessTokenSilently } = useAuth0();
    return useQuery(
        [
            useGetProductsKey,
            ...(queryKey ?? [
                {
                    action,
                    atStart,
                    atEnd,
                    byId,
                    byName,
                    types,
                    textSearch,
                    pageNumber,
                    pageSize,
                },
            ]),
        ],
        async () => {
            const token = await getAccessTokenSilently();
            const authorization = `Bearer ${token}`;
            return ProductsService.getProducts(
                authorization,
                action,
                atStart,
                atEnd,
                byId,
                byName,
                types,
                textSearch,
                pageNumber,
                pageSize
            );
        },
        options
    );
};
export const useGetProductsLazyLazyKey = 'ProductsServiceGetProducts';
export const useGetProductsLazy = <TQueryKey extends Array<any> = any[]>(
    {
        action,
        atStart,
        atEnd,
        byId,
        byName,
        types,
        textSearch,
        pageNumber,
        pageSize = 20,
    }: {
        action?: 'created' | 'last_updated' | 'deleted';
        atStart?: number;
        atEnd?: number;
        byId?: string;
        byName?: string;
        types?: Array<ProductType>;
        textSearch?: string;
        pageNumber?: number;
        pageSize?: number;
    },
    queryKey?: TQueryKey,
    options?: Omit<
        UseInfiniteQueryOptions<
            Awaited<ReturnType<typeof ProductsService.getProducts>>,
            unknown,
            Awaited<ReturnType<typeof ProductsService.getProducts>>,
            Awaited<ReturnType<typeof ProductsService.getProducts>>,
            any[]
        >,
        'queryKey' | 'queryFn' | 'initialData'
    >
) => {
    const { getAccessTokenSilently } = useAuth0();
    return useInfiniteQuery(
        [
            useGetProductsLazyLazyKey,
            ...(queryKey ?? [
                {
                    action,
                    atStart,
                    atEnd,
                    byId,
                    byName,
                    types,
                    textSearch,
                    pageNumber,
                    pageSize,
                },
            ]),
        ],
        {
            queryFn: async ({ pageParam = 0 }) => {
                const token = await getAccessTokenSilently();
                const authorization = `Bearer ${token}`;
                return ProductsService.getProducts(
                    authorization,
                    action,
                    atStart,
                    atEnd,
                    byId,
                    byName,
                    types,
                    textSearch,
                    pageParam,
                    pageSize
                );
            },
            getNextPageParam: (lastPage, allPages) => {
                if (!lastPage.items || lastPage.items.length < pageSize)
                    return undefined;
                return allPages.length;
            },
            ...options,
        }
    );
};
export const useCreateProduct = (
    options?: Omit<
        UseMutationOptions<
            Awaited<ReturnType<typeof ProductsService.createProduct>>,
            unknown,
            {
                requestBody: CreateProductReqDto | CreateProductReq1Dto;
            },
            unknown
        >,
        'mutationFn'
    >
) => {
    const { getAccessTokenSilently } = useAuth0();
    return useMutation(async ({ requestBody }) => {
        const token = await getAccessTokenSilently();
        const authorization = `Bearer ${token}`;
        return ProductsService.createProduct(authorization, requestBody);
    }, options);
};
export const useGetProducts1Key = 'ProductsServiceGetProducts1';
export const useGetProducts1 = <TQueryKey extends Array<unknown> = unknown[]>(
    {
        pageNumber,
        pageSize,
    }: {
        pageNumber?: number;
        pageSize?: number;
    },
    queryKey?: TQueryKey,
    options?: Omit<
        UseQueryOptions<
            Awaited<ReturnType<typeof ProductsService.getProducts1>>,
            unknown,
            Awaited<ReturnType<typeof ProductsService.getProducts1>>,
            unknown[]
        >,
        'queryKey' | 'queryFn' | 'initialData'
    >
) => {
    const { getAccessTokenSilently } = useAuth0();
    return useQuery(
        [useGetProducts1Key, ...(queryKey ?? [{ pageNumber, pageSize }])],
        async () => {
            const token = await getAccessTokenSilently();
            const authorization = `Bearer ${token}`;
            return ProductsService.getProducts1(
                authorization,
                pageNumber,
                pageSize
            );
        },
        options
    );
};
export const useGetProducts1LazyLazyKey = 'ProductsServiceGetProducts1';
export const useGetProducts1Lazy = <TQueryKey extends Array<any> = any[]>(
    {
        pageNumber,
        pageSize = 20,
    }: {
        pageNumber?: number;
        pageSize?: number;
    },
    queryKey?: TQueryKey,
    options?: Omit<
        UseInfiniteQueryOptions<
            Awaited<ReturnType<typeof ProductsService.getProducts1>>,
            unknown,
            Awaited<ReturnType<typeof ProductsService.getProducts1>>,
            Awaited<ReturnType<typeof ProductsService.getProducts1>>,
            any[]
        >,
        'queryKey' | 'queryFn' | 'initialData'
    >
) => {
    const { getAccessTokenSilently } = useAuth0();
    return useInfiniteQuery(
        [
            useGetProducts1LazyLazyKey,
            ...(queryKey ?? [{ pageNumber, pageSize }]),
        ],
        {
            queryFn: async ({ pageParam = 0 }) => {
                const token = await getAccessTokenSilently();
                const authorization = `Bearer ${token}`;
                return ProductsService.getProducts1(
                    authorization,
                    pageParam,
                    pageSize
                );
            },
            getNextPageParam: (lastPage, allPages) => {
                if (!lastPage.items || lastPage.items.length < pageSize)
                    return undefined;
                return allPages.length;
            },
            ...options,
        }
    );
};
export const useUpdateProduct = (
    options?: Omit<
        UseMutationOptions<
            Awaited<ReturnType<typeof ProductsService.updateProduct>>,
            unknown,
            {
                productId: string;
                requestBody: UpdateProductReqDto | UpdateProductReq1Dto;
            },
            unknown
        >,
        'mutationFn'
    >
) => {
    const { getAccessTokenSilently } = useAuth0();
    return useMutation(async ({ productId, requestBody }) => {
        const token = await getAccessTokenSilently();
        const authorization = `Bearer ${token}`;
        return ProductsService.updateProduct(
            productId,
            authorization,
            requestBody
        );
    }, options);
};
export const useUpdateProduct1 = (
    options?: Omit<
        UseMutationOptions<
            Awaited<ReturnType<typeof ProductsService.updateProduct1>>,
            unknown,
            {
                productId: string;
                requestBody: UpdateProduct1ReqDto | UpdateProduct1Req1Dto;
            },
            unknown
        >,
        'mutationFn'
    >
) => {
    const { getAccessTokenSilently } = useAuth0();
    return useMutation(async ({ productId, requestBody }) => {
        const token = await getAccessTokenSilently();
        const authorization = `Bearer ${token}`;
        return ProductsService.updateProduct1(
            productId,
            authorization,
            requestBody
        );
    }, options);
};
export const useUpdateCapacityProducts = (
    options?: Omit<
        UseMutationOptions<
            Awaited<ReturnType<typeof ProductsService.updateCapacityProducts>>,
            unknown,
            {
                productId: string;
                requestBody: UpdateCapacityProductsReqDto;
            },
            unknown
        >,
        'mutationFn'
    >
) => {
    const { getAccessTokenSilently } = useAuth0();
    return useMutation(async ({ productId, requestBody }) => {
        const token = await getAccessTokenSilently();
        const authorization = `Bearer ${token}`;
        return ProductsService.updateCapacityProducts(
            productId,
            authorization,
            requestBody
        );
    }, options);
};
export const useUpdateCapacityProducts1 = (
    options?: Omit<
        UseMutationOptions<
            Awaited<ReturnType<typeof ProductsService.updateCapacityProducts1>>,
            unknown,
            {
                productId: string;
                requestBody: UpdateCapacityProducts1ReqDto;
            },
            unknown
        >,
        'mutationFn'
    >
) => {
    const { getAccessTokenSilently } = useAuth0();
    return useMutation(async ({ productId, requestBody }) => {
        const token = await getAccessTokenSilently();
        const authorization = `Bearer ${token}`;
        return ProductsService.updateCapacityProducts1(
            productId,
            authorization,
            requestBody
        );
    }, options);
};
export const useUpdateProduct11 = (
    options?: Omit<
        UseMutationOptions<
            Awaited<ReturnType<typeof ProductsService.updateProduct11>>,
            unknown,
            {
                productId: string;
                requestBody: UpdateProduct11ReqDto;
            },
            unknown
        >,
        'mutationFn'
    >
) => {
    const { getAccessTokenSilently } = useAuth0();
    return useMutation(async ({ productId, requestBody }) => {
        const token = await getAccessTokenSilently();
        const authorization = `Bearer ${token}`;
        return ProductsService.updateProduct11(
            productId,
            authorization,
            requestBody
        );
    }, options);
};
export const useUpdateProduct111 = (
    options?: Omit<
        UseMutationOptions<
            Awaited<ReturnType<typeof ProductsService.updateProduct111>>,
            unknown,
            {
                productId: string;
                requestBody: UpdateProduct111ReqDto;
            },
            unknown
        >,
        'mutationFn'
    >
) => {
    const { getAccessTokenSilently } = useAuth0();
    return useMutation(async ({ productId, requestBody }) => {
        const token = await getAccessTokenSilently();
        const authorization = `Bearer ${token}`;
        return ProductsService.updateProduct111(
            productId,
            authorization,
            requestBody
        );
    }, options);
};
export const useUpdateProduct1111 = (
    options?: Omit<
        UseMutationOptions<
            Awaited<ReturnType<typeof ProductsService.updateProduct1111>>,
            unknown,
            {
                productId: string;
                requestBody: UpdateProduct1111ReqDto;
            },
            unknown
        >,
        'mutationFn'
    >
) => {
    const { getAccessTokenSilently } = useAuth0();
    return useMutation(async ({ productId, requestBody }) => {
        const token = await getAccessTokenSilently();
        const authorization = `Bearer ${token}`;
        return ProductsService.updateProduct1111(
            productId,
            authorization,
            requestBody
        );
    }, options);
};
export const useActivateProducts = (
    options?: Omit<
        UseMutationOptions<
            Awaited<ReturnType<typeof ProductsService.activateProducts>>,
            unknown,
            {
                productId: string;
            },
            unknown
        >,
        'mutationFn'
    >
) => {
    const { getAccessTokenSilently } = useAuth0();
    return useMutation(async ({ productId }) => {
        const token = await getAccessTokenSilently();
        const authorization = `Bearer ${token}`;
        return ProductsService.activateProducts(productId, authorization);
    }, options);
};
export const useDeactivateProducts = (
    options?: Omit<
        UseMutationOptions<
            Awaited<ReturnType<typeof ProductsService.deactivateProducts>>,
            unknown,
            {
                productId: string;
            },
            unknown
        >,
        'mutationFn'
    >
) => {
    const { getAccessTokenSilently } = useAuth0();
    return useMutation(async ({ productId }) => {
        const token = await getAccessTokenSilently();
        const authorization = `Bearer ${token}`;
        return ProductsService.deactivateProducts(productId, authorization);
    }, options);
};
export const useGetProductKey = 'ProductsServiceGetProduct';
export const useGetProduct = <TQueryKey extends Array<unknown> = unknown[]>(
    {
        productId,
    }: {
        productId: string;
    },
    queryKey?: TQueryKey,
    options?: Omit<
        UseQueryOptions<
            Awaited<ReturnType<typeof ProductsService.getProduct>>,
            unknown,
            Awaited<ReturnType<typeof ProductsService.getProduct>>,
            unknown[]
        >,
        'queryKey' | 'queryFn' | 'initialData'
    >
) => {
    const { getAccessTokenSilently } = useAuth0();
    return useQuery(
        [useGetProductKey, ...(queryKey ?? [{ productId }])],
        async () => {
            const token = await getAccessTokenSilently();
            const authorization = `Bearer ${token}`;
            return ProductsService.getProduct(productId, authorization);
        },
        options
    );
};
export const useDeleteProduct = (
    options?: Omit<
        UseMutationOptions<
            Awaited<ReturnType<typeof ProductsService.deleteProduct>>,
            unknown,
            {
                productId: string;
            },
            unknown
        >,
        'mutationFn'
    >
) => {
    const { getAccessTokenSilently } = useAuth0();
    return useMutation(async ({ productId }) => {
        const token = await getAccessTokenSilently();
        const authorization = `Bearer ${token}`;
        return ProductsService.deleteProduct(productId, authorization);
    }, options);
};
export const useGenerateNameProductsKey = 'ProductsServiceGenerateNameProducts';
export const useGenerateNameProducts = <
    TQueryKey extends Array<unknown> = unknown[]
>(
    queryKey?: TQueryKey,
    options?: Omit<
        UseQueryOptions<
            Awaited<ReturnType<typeof ProductsService.generateNameProducts>>,
            unknown,
            Awaited<ReturnType<typeof ProductsService.generateNameProducts>>,
            unknown[]
        >,
        'queryKey' | 'queryFn' | 'initialData'
    >
) => {
    const { getAccessTokenSilently } = useAuth0();
    return useQuery(
        [useGenerateNameProductsKey, ...(queryKey ?? [])],
        async () => {
            const token = await getAccessTokenSilently();
            const authorization = `Bearer ${token}`;
            return ProductsService.generateNameProducts(authorization);
        },
        options
    );
};
export const useUpdateProduct11111 = (
    options?: Omit<
        UseMutationOptions<
            Awaited<ReturnType<typeof ProductsService.updateProduct11111>>,
            unknown,
            {
                productId: string;
                requestBody: UpdateUserReqAccessGroupsItemDto;
            },
            unknown
        >,
        'mutationFn'
    >
) => {
    const { getAccessTokenSilently } = useAuth0();
    return useMutation(async ({ productId, requestBody }) => {
        const token = await getAccessTokenSilently();
        const authorization = `Bearer ${token}`;
        return ProductsService.updateProduct11111(
            productId,
            authorization,
            requestBody
        );
    }, options);
};
export const useGetOrdersKey = 'OrdersServiceGetOrders';
export const useGetOrders = <TQueryKey extends Array<unknown> = unknown[]>(
    {
        action,
        atStart,
        atEnd,
        byId,
        byName,
        statuses,
        textSearch,
        pageNumber,
        pageSize,
    }: {
        action?: 'created' | 'last_updated' | 'deleted';
        atStart?: number;
        atEnd?: number;
        byId?: string;
        byName?: string;
        statuses?: Array<OrderStatus>;
        textSearch?: string;
        pageNumber?: number;
        pageSize?: number;
    },
    queryKey?: TQueryKey,
    options?: Omit<
        UseQueryOptions<
            Awaited<ReturnType<typeof OrdersService.getOrders>>,
            unknown,
            Awaited<ReturnType<typeof OrdersService.getOrders>>,
            unknown[]
        >,
        'queryKey' | 'queryFn' | 'initialData'
    >
) => {
    const { getAccessTokenSilently } = useAuth0();
    return useQuery(
        [
            useGetOrdersKey,
            ...(queryKey ?? [
                {
                    action,
                    atStart,
                    atEnd,
                    byId,
                    byName,
                    statuses,
                    textSearch,
                    pageNumber,
                    pageSize,
                },
            ]),
        ],
        async () => {
            const token = await getAccessTokenSilently();
            const authorization = `Bearer ${token}`;
            return OrdersService.getOrders(
                authorization,
                action,
                atStart,
                atEnd,
                byId,
                byName,
                statuses,
                textSearch,
                pageNumber,
                pageSize
            );
        },
        options
    );
};
export const useGetOrdersLazyLazyKey = 'OrdersServiceGetOrders';
export const useGetOrdersLazy = <TQueryKey extends Array<any> = any[]>(
    {
        action,
        atStart,
        atEnd,
        byId,
        byName,
        statuses,
        textSearch,
        pageNumber,
        pageSize = 20,
    }: {
        action?: 'created' | 'last_updated' | 'deleted';
        atStart?: number;
        atEnd?: number;
        byId?: string;
        byName?: string;
        statuses?: Array<OrderStatus>;
        textSearch?: string;
        pageNumber?: number;
        pageSize?: number;
    },
    queryKey?: TQueryKey,
    options?: Omit<
        UseInfiniteQueryOptions<
            Awaited<ReturnType<typeof OrdersService.getOrders>>,
            unknown,
            Awaited<ReturnType<typeof OrdersService.getOrders>>,
            Awaited<ReturnType<typeof OrdersService.getOrders>>,
            any[]
        >,
        'queryKey' | 'queryFn' | 'initialData'
    >
) => {
    const { getAccessTokenSilently } = useAuth0();
    return useInfiniteQuery(
        [
            useGetOrdersLazyLazyKey,
            ...(queryKey ?? [
                {
                    action,
                    atStart,
                    atEnd,
                    byId,
                    byName,
                    statuses,
                    textSearch,
                    pageNumber,
                    pageSize,
                },
            ]),
        ],
        {
            queryFn: async ({ pageParam = 0 }) => {
                const token = await getAccessTokenSilently();
                const authorization = `Bearer ${token}`;
                return OrdersService.getOrders(
                    authorization,
                    action,
                    atStart,
                    atEnd,
                    byId,
                    byName,
                    statuses,
                    textSearch,
                    pageParam,
                    pageSize
                );
            },
            getNextPageParam: (lastPage, allPages) => {
                if (!lastPage.items || lastPage.items.length < pageSize)
                    return undefined;
                return allPages.length;
            },
            ...options,
        }
    );
};
export const useCreateOrder = (
    options?: Omit<
        UseMutationOptions<
            Awaited<ReturnType<typeof OrdersService.createOrder>>,
            unknown,
            {
                requestBody: CreateOrderReqDto;
            },
            unknown
        >,
        'mutationFn'
    >
) => {
    const { getAccessTokenSilently } = useAuth0();
    return useMutation(async ({ requestBody }) => {
        const token = await getAccessTokenSilently();
        const authorization = `Bearer ${token}`;
        return OrdersService.createOrder(authorization, requestBody);
    }, options);
};
export const useGetOrderKey = 'OrdersServiceGetOrder';
export const useGetOrder = <TQueryKey extends Array<unknown> = unknown[]>(
    {
        orderId,
    }: {
        orderId: string;
    },
    queryKey?: TQueryKey,
    options?: Omit<
        UseQueryOptions<
            Awaited<ReturnType<typeof OrdersService.getOrder>>,
            unknown,
            Awaited<ReturnType<typeof OrdersService.getOrder>>,
            unknown[]
        >,
        'queryKey' | 'queryFn' | 'initialData'
    >
) => {
    const { getAccessTokenSilently } = useAuth0();
    return useQuery(
        [useGetOrderKey, ...(queryKey ?? [{ orderId }])],
        async () => {
            const token = await getAccessTokenSilently();
            const authorization = `Bearer ${token}`;
            return OrdersService.getOrder(orderId, authorization);
        },
        options
    );
};
export const useCancelOrders = (
    options?: Omit<
        UseMutationOptions<
            Awaited<ReturnType<typeof OrdersService.cancelOrders>>,
            unknown,
            {
                orderId: string;
                requestBody: CancelOrdersReqDto;
            },
            unknown
        >,
        'mutationFn'
    >
) => {
    const { getAccessTokenSilently } = useAuth0();
    return useMutation(async ({ orderId, requestBody }) => {
        const token = await getAccessTokenSilently();
        const authorization = `Bearer ${token}`;
        return OrdersService.cancelOrders(orderId, authorization, requestBody);
    }, options);
};
export const useExportDetailsOrdersKey = 'OrdersServiceExportDetailsOrders';
export const useExportDetailsOrders = <
    TQueryKey extends Array<unknown> = unknown[]
>(
    {
        orderId,
    }: {
        orderId: string;
    },
    queryKey?: TQueryKey,
    options?: Omit<
        UseQueryOptions<
            Awaited<ReturnType<typeof OrdersService.exportDetailsOrders>>,
            unknown,
            Awaited<ReturnType<typeof OrdersService.exportDetailsOrders>>,
            unknown[]
        >,
        'queryKey' | 'queryFn' | 'initialData'
    >
) => {
    const { getAccessTokenSilently } = useAuth0();
    return useQuery(
        [useExportDetailsOrdersKey, ...(queryKey ?? [{ orderId }])],
        async () => {
            const token = await getAccessTokenSilently();
            const authorization = `Bearer ${token}`;
            return OrdersService.exportDetailsOrders(orderId, authorization);
        },
        options
    );
};
export const useGetEventsKey = 'EventsServiceGetEvents';
export const useGetEvents = <TQueryKey extends Array<unknown> = unknown[]>(
    {
        action,
        atStart,
        atEnd,
        byId,
        byName,
        types,
        statuses,
        startTs,
        endTs,
        textSearch,
        pageNumber,
        pageSize,
        bookings,
    }: {
        action?: 'created' | 'last_updated' | 'deleted';
        atStart?: number;
        atEnd?: number;
        byId?: string;
        byName?: string;
        types?: Array<string>;
        statuses?: Array<EventStatus>;
        startTs?: number;
        endTs?: number;
        textSearch?: string;
        pageNumber?: number;
        pageSize?: number;
        bookings?: boolean;
    },
    queryKey?: TQueryKey,
    options?: Omit<
        UseQueryOptions<
            Awaited<ReturnType<typeof EventsService.getEvents>>,
            unknown,
            Awaited<ReturnType<typeof EventsService.getEvents>>,
            unknown[]
        >,
        'queryKey' | 'queryFn' | 'initialData'
    >
) => {
    const { getAccessTokenSilently } = useAuth0();
    return useQuery(
        [
            useGetEventsKey,
            ...(queryKey ?? [
                {
                    action,
                    atStart,
                    atEnd,
                    byId,
                    byName,
                    types,
                    statuses,
                    startTs,
                    endTs,
                    textSearch,
                    pageNumber,
                    pageSize,
                    bookings,
                },
            ]),
        ],
        async () => {
            const token = await getAccessTokenSilently();
            const authorization = `Bearer ${token}`;
            return EventsService.getEvents(
                authorization,
                action,
                atStart,
                atEnd,
                byId,
                byName,
                types,
                statuses,
                startTs,
                endTs,
                textSearch,
                pageNumber,
                pageSize,
                bookings
            );
        },
        options
    );
};
export const useGetEventsLazyLazyKey = 'EventsServiceGetEvents';
export const useGetEventsLazy = <TQueryKey extends Array<any> = any[]>(
    {
        action,
        atStart,
        atEnd,
        byId,
        byName,
        types,
        statuses,
        startTs,
        endTs,
        textSearch,
        pageNumber,
        pageSize = 20,
        bookings,
    }: {
        action?: 'created' | 'last_updated' | 'deleted';
        atStart?: number;
        atEnd?: number;
        byId?: string;
        byName?: string;
        types?: Array<string>;
        statuses?: Array<EventStatus>;
        startTs?: number;
        endTs?: number;
        textSearch?: string;
        pageNumber?: number;
        pageSize?: number;
        bookings?: boolean;
    },
    queryKey?: TQueryKey,
    options?: Omit<
        UseInfiniteQueryOptions<
            Awaited<ReturnType<typeof EventsService.getEvents>>,
            unknown,
            Awaited<ReturnType<typeof EventsService.getEvents>>,
            Awaited<ReturnType<typeof EventsService.getEvents>>,
            any[]
        >,
        'queryKey' | 'queryFn' | 'initialData'
    >
) => {
    const { getAccessTokenSilently } = useAuth0();
    return useInfiniteQuery(
        [
            useGetEventsLazyLazyKey,
            ...(queryKey ?? [
                {
                    action,
                    atStart,
                    atEnd,
                    byId,
                    byName,
                    types,
                    statuses,
                    startTs,
                    endTs,
                    textSearch,
                    pageNumber,
                    pageSize,
                    bookings,
                },
            ]),
        ],
        {
            queryFn: async ({ pageParam = 0 }) => {
                const token = await getAccessTokenSilently();
                const authorization = `Bearer ${token}`;
                return EventsService.getEvents(
                    authorization,
                    action,
                    atStart,
                    atEnd,
                    byId,
                    byName,
                    types,
                    statuses,
                    startTs,
                    endTs,
                    textSearch,
                    pageParam,
                    pageSize,
                    bookings
                );
            },
            getNextPageParam: (lastPage, allPages) => {
                if (!lastPage.items || lastPage.items.length < pageSize)
                    return undefined;
                return allPages.length;
            },
            ...options,
        }
    );
};
export const useCreateEvent = (
    options?: Omit<
        UseMutationOptions<
            Awaited<ReturnType<typeof EventsService.createEvent>>,
            unknown,
            {
                requestBody: CreateEventReqDto | CreateEventReq1Dto;
            },
            unknown
        >,
        'mutationFn'
    >
) => {
    const { getAccessTokenSilently } = useAuth0();
    return useMutation(async ({ requestBody }) => {
        const token = await getAccessTokenSilently();
        const authorization = `Bearer ${token}`;
        return EventsService.createEvent(authorization, requestBody);
    }, options);
};
export const useGetEventKey = 'EventsServiceGetEvent';
export const useGetEvent = <TQueryKey extends Array<unknown> = unknown[]>(
    {
        eventId,
    }: {
        eventId: string;
    },
    queryKey?: TQueryKey,
    options?: Omit<
        UseQueryOptions<
            Awaited<ReturnType<typeof EventsService.getEvent>>,
            unknown,
            Awaited<ReturnType<typeof EventsService.getEvent>>,
            unknown[]
        >,
        'queryKey' | 'queryFn' | 'initialData'
    >
) => {
    const { getAccessTokenSilently } = useAuth0();
    return useQuery(
        [useGetEventKey, ...(queryKey ?? [{ eventId }])],
        async () => {
            const token = await getAccessTokenSilently();
            const authorization = `Bearer ${token}`;
            return EventsService.getEvent(eventId, authorization);
        },
        options
    );
};
export const useCancelEvents = (
    options?: Omit<
        UseMutationOptions<
            Awaited<ReturnType<typeof EventsService.cancelEvents>>,
            unknown,
            {
                eventId: string;
                requestBody: CancelEventsReqDto;
            },
            unknown
        >,
        'mutationFn'
    >
) => {
    const { getAccessTokenSilently } = useAuth0();
    return useMutation(async ({ eventId, requestBody }) => {
        const token = await getAccessTokenSilently();
        const authorization = `Bearer ${token}`;
        return EventsService.cancelEvents(eventId, authorization, requestBody);
    }, options);
};
export const useUpdateDateEvents = (
    options?: Omit<
        UseMutationOptions<
            Awaited<ReturnType<typeof EventsService.updateDateEvents>>,
            unknown,
            {
                eventId: string;
                requestBody: PeriodData;
            },
            unknown
        >,
        'mutationFn'
    >
) => {
    const { getAccessTokenSilently } = useAuth0();
    return useMutation(async ({ eventId, requestBody }) => {
        const token = await getAccessTokenSilently();
        const authorization = `Bearer ${token}`;
        return EventsService.updateDateEvents(
            eventId,
            authorization,
            requestBody
        );
    }, options);
};
export const useUpdateStaffEvents = (
    options?: Omit<
        UseMutationOptions<
            Awaited<ReturnType<typeof EventsService.updateStaffEvents>>,
            unknown,
            {
                eventId: string;
                requestBody: UpdateStaffEventsReqDto | UpdateStaffEventsReq1Dto;
            },
            unknown
        >,
        'mutationFn'
    >
) => {
    const { getAccessTokenSilently } = useAuth0();
    return useMutation(async ({ eventId, requestBody }) => {
        const token = await getAccessTokenSilently();
        const authorization = `Bearer ${token}`;
        return EventsService.updateStaffEvents(
            eventId,
            authorization,
            requestBody
        );
    }, options);
};
export const useUpdateAssetsEvents = (
    options?: Omit<
        UseMutationOptions<
            Awaited<ReturnType<typeof EventsService.updateAssetsEvents>>,
            unknown,
            {
                eventId: string;
                requestBody:
                    | UpdateAssetsEventsReqDto
                    | UpdateAssetsEventsReq1Dto;
            },
            unknown
        >,
        'mutationFn'
    >
) => {
    const { getAccessTokenSilently } = useAuth0();
    return useMutation(async ({ eventId, requestBody }) => {
        const token = await getAccessTokenSilently();
        const authorization = `Bearer ${token}`;
        return EventsService.updateAssetsEvents(
            eventId,
            authorization,
            requestBody
        );
    }, options);
};
export const useGetCustomerKey = 'CustomersServiceGetCustomer';
export const useGetCustomer = <TQueryKey extends Array<unknown> = unknown[]>(
    {
        name,
        email,
        phoneNumber,
        pageNumber,
        pageSize,
    }: {
        name?: string;
        email?: string;
        phoneNumber?: string;
        pageNumber?: number;
        pageSize?: number;
    },
    queryKey?: TQueryKey,
    options?: Omit<
        UseQueryOptions<
            Awaited<ReturnType<typeof CustomersService.getCustomer>>,
            unknown,
            Awaited<ReturnType<typeof CustomersService.getCustomer>>,
            unknown[]
        >,
        'queryKey' | 'queryFn' | 'initialData'
    >
) => {
    const { getAccessTokenSilently } = useAuth0();
    return useQuery(
        [
            useGetCustomerKey,
            ...(queryKey ?? [
                { name, email, phoneNumber, pageNumber, pageSize },
            ]),
        ],
        async () => {
            const token = await getAccessTokenSilently();
            const authorization = `Bearer ${token}`;
            return CustomersService.getCustomer(
                authorization,
                name,
                email,
                phoneNumber,
                pageNumber,
                pageSize
            );
        },
        options
    );
};
export const useGetCustomerLazyLazyKey = 'CustomersServiceGetCustomer';
export const useGetCustomerLazy = <TQueryKey extends Array<any> = any[]>(
    {
        name,
        email,
        phoneNumber,
        pageNumber,
        pageSize = 20,
    }: {
        name?: string;
        email?: string;
        phoneNumber?: string;
        pageNumber?: number;
        pageSize?: number;
    },
    queryKey?: TQueryKey,
    options?: Omit<
        UseInfiniteQueryOptions<
            Awaited<ReturnType<typeof CustomersService.getCustomer>>,
            unknown,
            Awaited<ReturnType<typeof CustomersService.getCustomer>>,
            Awaited<ReturnType<typeof CustomersService.getCustomer>>,
            any[]
        >,
        'queryKey' | 'queryFn' | 'initialData'
    >
) => {
    const { getAccessTokenSilently } = useAuth0();
    return useInfiniteQuery(
        [
            useGetCustomerLazyLazyKey,
            ...(queryKey ?? [
                { name, email, phoneNumber, pageNumber, pageSize },
            ]),
        ],
        {
            queryFn: async ({ pageParam = 0 }) => {
                const token = await getAccessTokenSilently();
                const authorization = `Bearer ${token}`;
                return CustomersService.getCustomer(
                    authorization,
                    name,
                    email,
                    phoneNumber,
                    pageParam,
                    pageSize
                );
            },
            getNextPageParam: (lastPage, allPages) => {
                if (!lastPage.items || lastPage.items.length < pageSize)
                    return undefined;
                return allPages.length;
            },
            ...options,
        }
    );
};
export const useCreateCustomer = (
    options?: Omit<
        UseMutationOptions<
            Awaited<ReturnType<typeof CustomersService.createCustomer>>,
            unknown,
            {
                requestBody: CreateCustomerReqDto;
            },
            unknown
        >,
        'mutationFn'
    >
) => {
    const { getAccessTokenSilently } = useAuth0();
    return useMutation(async ({ requestBody }) => {
        const token = await getAccessTokenSilently();
        const authorization = `Bearer ${token}`;
        return CustomersService.createCustomer(authorization, requestBody);
    }, options);
};
export const useGetCustomer1Key = 'CustomersServiceGetCustomer1';
export const useGetCustomer1 = <TQueryKey extends Array<unknown> = unknown[]>(
    {
        customerId,
    }: {
        customerId: string;
    },
    queryKey?: TQueryKey,
    options?: Omit<
        UseQueryOptions<
            Awaited<ReturnType<typeof CustomersService.getCustomer1>>,
            unknown,
            Awaited<ReturnType<typeof CustomersService.getCustomer1>>,
            unknown[]
        >,
        'queryKey' | 'queryFn' | 'initialData'
    >
) => {
    const { getAccessTokenSilently } = useAuth0();
    return useQuery(
        [useGetCustomer1Key, ...(queryKey ?? [{ customerId }])],
        async () => {
            const token = await getAccessTokenSilently();
            const authorization = `Bearer ${token}`;
            return CustomersService.getCustomer1(customerId, authorization);
        },
        options
    );
};
export const useUploadAttachmentCustomers = (
    options?: Omit<
        UseMutationOptions<
            Awaited<
                ReturnType<typeof CustomersService.uploadAttachmentCustomers>
            >,
            unknown,
            {
                customerId: string;
                requestBody: UploadAvatarUsersReqDto | UploadAvatarUsersReq1Dto;
            },
            unknown
        >,
        'mutationFn'
    >
) => {
    const { getAccessTokenSilently } = useAuth0();
    return useMutation(async ({ customerId, requestBody }) => {
        const token = await getAccessTokenSilently();
        const authorization = `Bearer ${token}`;
        return CustomersService.uploadAttachmentCustomers(
            customerId,
            authorization,
            requestBody
        );
    }, options);
};
export const useRemoveAttachmentCustomers = (
    options?: Omit<
        UseMutationOptions<
            Awaited<
                ReturnType<typeof CustomersService.removeAttachmentCustomers>
            >,
            unknown,
            {
                customerId: string;
                attachmentId: string;
            },
            unknown
        >,
        'mutationFn'
    >
) => {
    const { getAccessTokenSilently } = useAuth0();
    return useMutation(async ({ customerId, attachmentId }) => {
        const token = await getAccessTokenSilently();
        const authorization = `Bearer ${token}`;
        return CustomersService.removeAttachmentCustomers(
            customerId,
            attachmentId,
            authorization
        );
    }, options);
};
export const useGetCapacitiesKey = 'CapacitiesServiceGetCapacities';
export const useGetCapacities = <TQueryKey extends Array<unknown> = unknown[]>(
    {
        ids,
        pageNumber,
        pageSize,
    }: {
        ids?: Array<string>;
        pageNumber?: number;
        pageSize?: number;
    },
    queryKey?: TQueryKey,
    options?: Omit<
        UseQueryOptions<
            Awaited<ReturnType<typeof CapacitiesService.getCapacities>>,
            unknown,
            Awaited<ReturnType<typeof CapacitiesService.getCapacities>>,
            unknown[]
        >,
        'queryKey' | 'queryFn' | 'initialData'
    >
) => {
    const { getAccessTokenSilently } = useAuth0();
    return useQuery(
        [useGetCapacitiesKey, ...(queryKey ?? [{ ids, pageNumber, pageSize }])],
        async () => {
            const token = await getAccessTokenSilently();
            const authorization = `Bearer ${token}`;
            return CapacitiesService.getCapacities(
                authorization,
                ids,
                pageNumber,
                pageSize
            );
        },
        options
    );
};
export const useGetCapacitiesLazyLazyKey = 'CapacitiesServiceGetCapacities';
export const useGetCapacitiesLazy = <TQueryKey extends Array<any> = any[]>(
    {
        ids,
        pageNumber,
        pageSize = 20,
    }: {
        ids?: Array<string>;
        pageNumber?: number;
        pageSize?: number;
    },
    queryKey?: TQueryKey,
    options?: Omit<
        UseInfiniteQueryOptions<
            Awaited<ReturnType<typeof CapacitiesService.getCapacities>>,
            unknown,
            Awaited<ReturnType<typeof CapacitiesService.getCapacities>>,
            Awaited<ReturnType<typeof CapacitiesService.getCapacities>>,
            any[]
        >,
        'queryKey' | 'queryFn' | 'initialData'
    >
) => {
    const { getAccessTokenSilently } = useAuth0();
    return useInfiniteQuery(
        [
            useGetCapacitiesLazyLazyKey,
            ...(queryKey ?? [{ ids, pageNumber, pageSize }]),
        ],
        {
            queryFn: async ({ pageParam = 0 }) => {
                const token = await getAccessTokenSilently();
                const authorization = `Bearer ${token}`;
                return CapacitiesService.getCapacities(
                    authorization,
                    ids,
                    pageParam,
                    pageSize
                );
            },
            getNextPageParam: (lastPage, allPages) => {
                if (!lastPage.items || lastPage.items.length < pageSize)
                    return undefined;
                return allPages.length;
            },
            ...options,
        }
    );
};
export const useCreateManyCapacities = (
    options?: Omit<
        UseMutationOptions<
            Awaited<ReturnType<typeof CapacitiesService.createManyCapacities>>,
            unknown,
            {
                requestBody: CreateManyCapacitiesReqDto;
            },
            unknown
        >,
        'mutationFn'
    >
) => {
    const { getAccessTokenSilently } = useAuth0();
    return useMutation(async ({ requestBody }) => {
        const token = await getAccessTokenSilently();
        const authorization = `Bearer ${token}`;
        return CapacitiesService.createManyCapacities(
            authorization,
            requestBody
        );
    }, options);
};
export const useGetCapacityKey = 'CapacitiesServiceGetCapacity';
export const useGetCapacity = <TQueryKey extends Array<unknown> = unknown[]>(
    {
        capacityId,
    }: {
        capacityId: string;
    },
    queryKey?: TQueryKey,
    options?: Omit<
        UseQueryOptions<
            Awaited<ReturnType<typeof CapacitiesService.getCapacity>>,
            unknown,
            Awaited<ReturnType<typeof CapacitiesService.getCapacity>>,
            unknown[]
        >,
        'queryKey' | 'queryFn' | 'initialData'
    >
) => {
    const { getAccessTokenSilently } = useAuth0();
    return useQuery(
        [useGetCapacityKey, ...(queryKey ?? [{ capacityId }])],
        async () => {
            const token = await getAccessTokenSilently();
            const authorization = `Bearer ${token}`;
            return CapacitiesService.getCapacity(capacityId, authorization);
        },
        options
    );
};
export const useGetBookingsKey = 'BookingsServiceGetBookings';
export const useGetBookings = <TQueryKey extends Array<unknown> = unknown[]>(
    {
        action,
        atStart,
        atEnd,
        byId,
        byName,
        productTypes,
        statuses,
        orderNumber,
        eventId,
        startTs,
        endTs,
        textSearch,
        pageNumber,
        pageSize,
    }: {
        action?: 'created' | 'last_updated' | 'deleted';
        atStart?: number;
        atEnd?: number;
        byId?: string;
        byName?: string;
        productTypes?: Array<string>;
        statuses?: Array<BookingStatus>;
        orderNumber?: string;
        eventId?: string;
        startTs?: number;
        endTs?: number;
        textSearch?: string;
        pageNumber?: number;
        pageSize?: number;
    },
    queryKey?: TQueryKey,
    options?: Omit<
        UseQueryOptions<
            Awaited<ReturnType<typeof BookingsService.getBookings>>,
            unknown,
            Awaited<ReturnType<typeof BookingsService.getBookings>>,
            unknown[]
        >,
        'queryKey' | 'queryFn' | 'initialData'
    >
) => {
    const { getAccessTokenSilently } = useAuth0();
    return useQuery(
        [
            useGetBookingsKey,
            ...(queryKey ?? [
                {
                    action,
                    atStart,
                    atEnd,
                    byId,
                    byName,
                    productTypes,
                    statuses,
                    orderNumber,
                    eventId,
                    startTs,
                    endTs,
                    textSearch,
                    pageNumber,
                    pageSize,
                },
            ]),
        ],
        async () => {
            const token = await getAccessTokenSilently();
            const authorization = `Bearer ${token}`;
            return BookingsService.getBookings(
                authorization,
                action,
                atStart,
                atEnd,
                byId,
                byName,
                productTypes,
                statuses,
                orderNumber,
                eventId,
                startTs,
                endTs,
                textSearch,
                pageNumber,
                pageSize
            );
        },
        options
    );
};
export const useGetBookingsLazyLazyKey = 'BookingsServiceGetBookings';
export const useGetBookingsLazy = <TQueryKey extends Array<any> = any[]>(
    {
        action,
        atStart,
        atEnd,
        byId,
        byName,
        productTypes,
        statuses,
        orderNumber,
        eventId,
        startTs,
        endTs,
        textSearch,
        pageNumber,
        pageSize = 20,
    }: {
        action?: 'created' | 'last_updated' | 'deleted';
        atStart?: number;
        atEnd?: number;
        byId?: string;
        byName?: string;
        productTypes?: Array<string>;
        statuses?: Array<BookingStatus>;
        orderNumber?: string;
        eventId?: string;
        startTs?: number;
        endTs?: number;
        textSearch?: string;
        pageNumber?: number;
        pageSize?: number;
    },
    queryKey?: TQueryKey,
    options?: Omit<
        UseInfiniteQueryOptions<
            Awaited<ReturnType<typeof BookingsService.getBookings>>,
            unknown,
            Awaited<ReturnType<typeof BookingsService.getBookings>>,
            Awaited<ReturnType<typeof BookingsService.getBookings>>,
            any[]
        >,
        'queryKey' | 'queryFn' | 'initialData'
    >
) => {
    const { getAccessTokenSilently } = useAuth0();
    return useInfiniteQuery(
        [
            useGetBookingsLazyLazyKey,
            ...(queryKey ?? [
                {
                    action,
                    atStart,
                    atEnd,
                    byId,
                    byName,
                    productTypes,
                    statuses,
                    orderNumber,
                    eventId,
                    startTs,
                    endTs,
                    textSearch,
                    pageNumber,
                    pageSize,
                },
            ]),
        ],
        {
            queryFn: async ({ pageParam = 0 }) => {
                const token = await getAccessTokenSilently();
                const authorization = `Bearer ${token}`;
                return BookingsService.getBookings(
                    authorization,
                    action,
                    atStart,
                    atEnd,
                    byId,
                    byName,
                    productTypes,
                    statuses,
                    orderNumber,
                    eventId,
                    startTs,
                    endTs,
                    textSearch,
                    pageParam,
                    pageSize
                );
            },
            getNextPageParam: (lastPage, allPages) => {
                if (!lastPage.items || lastPage.items.length < pageSize)
                    return undefined;
                return allPages.length;
            },
            ...options,
        }
    );
};
export const useCreateBooking = (
    options?: Omit<
        UseMutationOptions<
            Awaited<ReturnType<typeof BookingsService.createBooking>>,
            unknown,
            {
                requestBody: CreateBookingReqDto | CreateBookingReq1Dto;
            },
            unknown
        >,
        'mutationFn'
    >
) => {
    const { getAccessTokenSilently } = useAuth0();
    return useMutation(async ({ requestBody }) => {
        const token = await getAccessTokenSilently();
        const authorization = `Bearer ${token}`;
        return BookingsService.createBooking(authorization, requestBody);
    }, options);
};
export const useGetBookingKey = 'BookingsServiceGetBooking';
export const useGetBooking = <TQueryKey extends Array<unknown> = unknown[]>(
    {
        bookingId,
    }: {
        bookingId: string;
    },
    queryKey?: TQueryKey,
    options?: Omit<
        UseQueryOptions<
            Awaited<ReturnType<typeof BookingsService.getBooking>>,
            unknown,
            Awaited<ReturnType<typeof BookingsService.getBooking>>,
            unknown[]
        >,
        'queryKey' | 'queryFn' | 'initialData'
    >
) => {
    const { getAccessTokenSilently } = useAuth0();
    return useQuery(
        [useGetBookingKey, ...(queryKey ?? [{ bookingId }])],
        async () => {
            const token = await getAccessTokenSilently();
            const authorization = `Bearer ${token}`;
            return BookingsService.getBooking(bookingId, authorization);
        },
        options
    );
};
export const useUpdateEventBookings = (
    options?: Omit<
        UseMutationOptions<
            Awaited<ReturnType<typeof BookingsService.updateEventBookings>>,
            unknown,
            {
                bookingId: string;
                eventId: string;
            },
            unknown
        >,
        'mutationFn'
    >
) => {
    const { getAccessTokenSilently } = useAuth0();
    return useMutation(async ({ bookingId, eventId }) => {
        const token = await getAccessTokenSilently();
        const authorization = `Bearer ${token}`;
        return BookingsService.updateEventBookings(
            bookingId,
            eventId,
            authorization
        );
    }, options);
};
export const useUpdateCustomersBookings = (
    options?: Omit<
        UseMutationOptions<
            Awaited<ReturnType<typeof BookingsService.updateCustomersBookings>>,
            unknown,
            {
                bookingId: string;
                requestBody:
                    | UpdateCustomersBookingsReqDto
                    | UpdateCustomersBookingsReq1Dto;
            },
            unknown
        >,
        'mutationFn'
    >
) => {
    const { getAccessTokenSilently } = useAuth0();
    return useMutation(async ({ bookingId, requestBody }) => {
        const token = await getAccessTokenSilently();
        const authorization = `Bearer ${token}`;
        return BookingsService.updateCustomersBookings(
            bookingId,
            authorization,
            requestBody
        );
    }, options);
};
export const useUpdateProductOptionsBookings = (
    options?: Omit<
        UseMutationOptions<
            Awaited<
                ReturnType<typeof BookingsService.updateProductOptionsBookings>
            >,
            unknown,
            {
                bookingId: string;
                requestBody: UpdateProductOptionsBookingsReqDto;
            },
            unknown
        >,
        'mutationFn'
    >
) => {
    const { getAccessTokenSilently } = useAuth0();
    return useMutation(async ({ bookingId, requestBody }) => {
        const token = await getAccessTokenSilently();
        const authorization = `Bearer ${token}`;
        return BookingsService.updateProductOptionsBookings(
            bookingId,
            authorization,
            requestBody
        );
    }, options);
};
export const useUpdateStatusBookings = (
    options?: Omit<
        UseMutationOptions<
            Awaited<ReturnType<typeof BookingsService.updateStatusBookings>>,
            unknown,
            {
                bookingId: string;
                requestBody: UpdateStatusBookingsReqDto;
            },
            unknown
        >,
        'mutationFn'
    >
) => {
    const { getAccessTokenSilently } = useAuth0();
    return useMutation(async ({ bookingId, requestBody }) => {
        const token = await getAccessTokenSilently();
        const authorization = `Bearer ${token}`;
        return BookingsService.updateStatusBookings(
            bookingId,
            authorization,
            requestBody
        );
    }, options);
};
export const useUpdateBookingSourceBookings = (
    options?: Omit<
        UseMutationOptions<
            Awaited<
                ReturnType<typeof BookingsService.updateBookingSourceBookings>
            >,
            unknown,
            {
                bookingId: string;
                requestBody:
                    | UpdateBookingSourceBookingsReqDto
                    | UpdateBookingSourceBookingsReq1Dto;
            },
            unknown
        >,
        'mutationFn'
    >
) => {
    const { getAccessTokenSilently } = useAuth0();
    return useMutation(async ({ bookingId, requestBody }) => {
        const token = await getAccessTokenSilently();
        const authorization = `Bearer ${token}`;
        return BookingsService.updateBookingSourceBookings(
            bookingId,
            authorization,
            requestBody
        );
    }, options);
};
export const useUpdateTransactionsBookings = (
    options?: Omit<
        UseMutationOptions<
            Awaited<
                ReturnType<typeof BookingsService.updateTransactionsBookings>
            >,
            unknown,
            {
                bookingId: string;
                requestBody: UpdateTransactionsBookingsReqDto;
            },
            unknown
        >,
        'mutationFn'
    >
) => {
    const { getAccessTokenSilently } = useAuth0();
    return useMutation(async ({ bookingId, requestBody }) => {
        const token = await getAccessTokenSilently();
        const authorization = `Bearer ${token}`;
        return BookingsService.updateTransactionsBookings(
            bookingId,
            authorization,
            requestBody
        );
    }, options);
};
export const useUpdateBookingNotesBookings = (
    options?: Omit<
        UseMutationOptions<
            Awaited<
                ReturnType<typeof BookingsService.updateBookingNotesBookings>
            >,
            unknown,
            {
                bookingId: string;
                requestBody: UpdateBookingNotesBookingsReqDto;
            },
            unknown
        >,
        'mutationFn'
    >
) => {
    const { getAccessTokenSilently } = useAuth0();
    return useMutation(async ({ bookingId, requestBody }) => {
        const token = await getAccessTokenSilently();
        const authorization = `Bearer ${token}`;
        return BookingsService.updateBookingNotesBookings(
            bookingId,
            authorization,
            requestBody
        );
    }, options);
};
export const useUpdateDiscountBookings = (
    options?: Omit<
        UseMutationOptions<
            Awaited<ReturnType<typeof BookingsService.updateDiscountBookings>>,
            unknown,
            {
                bookingId: string;
                requestBody: DiscountData;
            },
            unknown
        >,
        'mutationFn'
    >
) => {
    const { getAccessTokenSilently } = useAuth0();
    return useMutation(async ({ bookingId, requestBody }) => {
        const token = await getAccessTokenSilently();
        const authorization = `Bearer ${token}`;
        return BookingsService.updateDiscountBookings(
            bookingId,
            authorization,
            requestBody
        );
    }, options);
};
export const useComputePriceBookings = (
    options?: Omit<
        UseMutationOptions<
            Awaited<ReturnType<typeof BookingsService.computePriceBookings>>,
            unknown,
            {
                requestBody: CreateBookingReqProductDto;
            },
            unknown
        >,
        'mutationFn'
    >
) => {
    const { getAccessTokenSilently } = useAuth0();
    return useMutation(async ({ requestBody }) => {
        const token = await getAccessTokenSilently();
        const authorization = `Bearer ${token}`;
        return BookingsService.computePriceBookings(authorization, requestBody);
    }, options);
};
export const useGetAssetsKey = 'AssetsServiceGetAssets';
export const useGetAssets = <TQueryKey extends Array<unknown> = unknown[]>(
    {
        textSearch,
    }: {
        textSearch?: string;
    },
    queryKey?: TQueryKey,
    options?: Omit<
        UseQueryOptions<
            Awaited<ReturnType<typeof AssetsService.getAssets>>,
            unknown,
            Awaited<ReturnType<typeof AssetsService.getAssets>>,
            unknown[]
        >,
        'queryKey' | 'queryFn' | 'initialData'
    >
) => {
    const { getAccessTokenSilently } = useAuth0();
    return useQuery(
        [useGetAssetsKey, ...(queryKey ?? [{ textSearch }])],
        async () => {
            const token = await getAccessTokenSilently();
            const authorization = `Bearer ${token}`;
            return AssetsService.getAssets(authorization, textSearch);
        },
        options
    );
};
export const useCreateAsset = (
    options?: Omit<
        UseMutationOptions<
            Awaited<ReturnType<typeof AssetsService.createAsset>>,
            unknown,
            {
                requestBody: CreateAssetReqDto;
            },
            unknown
        >,
        'mutationFn'
    >
) => {
    const { getAccessTokenSilently } = useAuth0();
    return useMutation(async ({ requestBody }) => {
        const token = await getAccessTokenSilently();
        const authorization = `Bearer ${token}`;
        return AssetsService.createAsset(authorization, requestBody);
    }, options);
};
export const useGetAssetKey = 'AssetsServiceGetAsset';
export const useGetAsset = <TQueryKey extends Array<unknown> = unknown[]>(
    {
        assetId,
    }: {
        assetId: string;
    },
    queryKey?: TQueryKey,
    options?: Omit<
        UseQueryOptions<
            Awaited<ReturnType<typeof AssetsService.getAsset>>,
            unknown,
            Awaited<ReturnType<typeof AssetsService.getAsset>>,
            unknown[]
        >,
        'queryKey' | 'queryFn' | 'initialData'
    >
) => {
    const { getAccessTokenSilently } = useAuth0();
    return useQuery(
        [useGetAssetKey, ...(queryKey ?? [{ assetId }])],
        async () => {
            const token = await getAccessTokenSilently();
            const authorization = `Bearer ${token}`;
            return AssetsService.getAsset(assetId, authorization);
        },
        options
    );
};

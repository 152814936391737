import React, { useCallback, useRef } from 'react';
import DialogTitle from '@mui/material/DialogTitle';
import { Stack, Typography } from '@mui/material';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import { Button, LoadingOverlay } from '@travelity/ui';
import Dialog from '@mui/material/Dialog';
import { useForm } from '@travelity/form';
import { Asset } from '@travelity/api';
import SelectAsset from '../select-asset/select-asset';
import schema from './asset-modal.schema';

export interface AssetModalProps {
    open: boolean;
    isLoading?: boolean;
    setOpen: (v: boolean) => void;
    onConfirm: (v: Asset) => void;
    onClose: () => void;
    usedAssetIds?: string[];
    position?: 'center' | 'end';
}

export const AssetModal: React.FC<AssetModalProps> = props => {
    const {
        open,
        isLoading,
        setOpen,
        onConfirm,
        onClose,
        usedAssetIds,
        position = 'end',
    } = props;

    const { Form, getValues, errorFields } = useForm({
        schema,
        validateInitially: true,
        mode: 'onChange',
        defaultValues: {} as { asset?: Asset },
    });

    const handleCancel = useCallback(() => {
        setOpen(false);
        onClose();
    }, [setOpen, onClose]);

    const onSubmit = useCallback(() => {
        const values = getValues();

        onConfirm(values.asset as Asset);
    }, [getValues]);

    return (
        <Form>
            <Dialog
                open={open}
                onClose={handleCancel}
                sx={{
                    '& .MuiDialog-container': { justifyContent: position },
                }}
                PaperProps={{
                    sx: { py: 1, bgcolor: '#fff', maxWidth: '432px' },
                }}
            >
                <DialogTitle
                    sx={{
                        fontSize: '16px',
                        lineHeight: '16px',
                        fontWeight: 600,
                        color: '#2B395B',
                        pr: 6,
                    }}
                    id="alert-dialog-title"
                >
                    <Typography sx={{ fontWeight: 600 }}>
                        Add an Asset
                    </Typography>
                </DialogTitle>
                <DialogContent
                    sx={{
                        overflow: 'hidden',
                        pr: 1,
                    }}
                >
                    <Stack
                        sx={{
                            pr: 2,
                            py: 1,
                            height: 'calc(100% - 76px)',
                        }}
                        gap={2}
                    >
                        <SelectAsset
                            name="asset"
                            label="Vehicle model, make or license plate"
                            placeholder="Select Model, Make or License Plate"
                            usedAssetIds={usedAssetIds}
                        />
                    </Stack>
                    {isLoading && (
                        <LoadingOverlay
                            title="Please wait..."
                            subTitle="It’ll just take a moment."
                        />
                    )}
                </DialogContent>
                <DialogActions sx={{ px: 3, py: 2 }}>
                    <Button
                        color="neutral"
                        variant="contained"
                        onClick={handleCancel}
                        disabled={isLoading}
                        sx={{
                            px: '26px',
                            py: '10px',
                        }}
                    >
                        Discard
                    </Button>

                    <Button
                        color="secondary"
                        variant="contained"
                        disabled={!!errorFields.length || isLoading}
                        onClick={onSubmit}
                        sx={{
                            py: '10px',
                            px: 4,
                        }}
                    >
                        Add
                    </Button>
                </DialogActions>
            </Dialog>
        </Form>
    );
};

export const useAssetModal = () => {
    const [assetModalOpen, setAssetModalOpen] = React.useState(false);

    const ref = useRef({
        open: assetModalOpen,
        setOpen: setAssetModalOpen,
    });
    ref.current = {
        open: assetModalOpen,
        setOpen: setAssetModalOpen,
    };

    const addAsset = useCallback(() => {
        setAssetModalOpen(true);
    }, []);

    const closeModal = useCallback(() => {
        setAssetModalOpen(false);
    }, []);

    const AssetModalWrapper = useCallback<
        React.FC<Omit<AssetModalProps, 'open' | 'setOpen'>>
    >(props => {
        return ref.current.open ? (
            <AssetModal {...props} {...ref.current} />
        ) : null;
    }, []);

    return {
        AssetModal: AssetModalWrapper,
        assetModalOpen,
        addAsset,
        closeModal,
    };
};

import { Controller, useFormContext } from 'react-hook-form';
import React from 'react';
import {
    TextField as MuiTextField,
    TextFieldProps as MuiTextFieldProps,
    Box,
    InputAdornment,
} from '@mui/material';
import _ from 'lodash';
import { useIsFormNested } from '../../nested-form-provider';

export type CurrencyFieldProps = Partial<MuiTextFieldProps> & {
    name: string;
    currency: string;
    label?: string;
    required?: boolean;
    fullWidth?: boolean;
    showErrors?: boolean;
};

const CurrencyField: React.FC<CurrencyFieldProps> = ({
    name,
    currency,
    required,
    label,
    fullWidth,
    showErrors,
    ...rest
}) => {
    const {
        control,
        formState: { errors },
    } = useFormContext();

    const isNested = useIsFormNested();
    const onKeyDown = (e: React.KeyboardEvent) => {
        if (isNested && e.key === 'Enter') {
            e.preventDefault();
        }
    };

    const error =
        showErrors && (_.get(errors, name)?.message as string | undefined);

    return (
        <Controller
            render={({ field }: { field: Record<string, any> }) => (
                <MuiTextField
                    onKeyDown={onKeyDown}
                    fullWidth={fullWidth}
                    margin="dense"
                    InputLabelProps={{
                        required: !!required,
                        shrink: !!rest.placeholder,
                    }}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <Box
                                    sx={{
                                        fontSize: '12px',
                                        lineHeight: '11px',

                                        textAlign: 'right',
                                        color: '#949BAC',
                                    }}
                                >
                                    {currency}
                                </Box>
                            </InputAdornment>
                        ),
                        ...(rest.InputProps || {}),
                    }}
                    label={label}
                    {...field}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        field.onChange(
                            e.target.value.indexOf('.') === -1
                                ? parseInt(e.target.value) || ''
                                : e.target.value.indexOf('.') <
                                  e.target.value.length - 1
                                ? parseFloat(e.target.value) || ''
                                : e.target.value
                        );
                    }}
                    value={field.value || ''}
                    type="text"
                    error={!!error}
                    helperText={error}
                    {...rest}
                    sx={{
                        m: 0,
                        width: fullWidth ? '100%' : '388px',
                        ...(rest.sx || {}),
                    }}
                />
            )}
            name={name}
            control={control}
        />
    );
};

export default CurrencyField;

import { GetBookingsResItem0ProductCustomersItemDto } from '../../requests';
import { Traveler } from './traveler.types';
import { convertNameDtoToFullName } from '../user/user.converters';

export const travelerDtoToTraveler = (
    traveler: GetBookingsResItem0ProductCustomersItemDto
): Traveler => ({
    customerId: traveler.id,
    travelerId: traveler.id,
    fullName: convertNameDtoToFullName(traveler.reference.name),
    attendance: traveler.attendance?.type,
    pickUp: traveler.pickup
        ? {
              id: traveler.pickup.id,
              type: traveler.pickup.type,
              name: traveler.pickup.location.name,
              time: traveler.pickup.schedule.date?.start
                  ? new Date(traveler.pickup.schedule.date.start * 1000)
                  : new Date(),
          }
        : undefined,
    guestCount: traveler.plus_x,
});

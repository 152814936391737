import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Divider, Stack } from '@mui/material';
import { EventProduct } from '@travelity/api';
import { GetProductsResItem0RouteDto } from '@travelity/api/src/requests';
import { SingleLineKeyValue } from '../booking-participants/components/single-line-key-value';
import { Route } from '../route';

export interface BookingProductFormProps {
    product: EventProduct;
    route?: GetProductsResItem0RouteDto;
    preview?: boolean;
}

const BookingProductForm: React.FC<BookingProductFormProps> = ({
    preview,
    product,
    route,
}) => {
    const { t } = useTranslation('product');
    const { name, type } = product;

    const routes = useMemo(
        () => route?.items?.map(stop => stop.location.name) || [],
        [route]
    );

    // @ts-ignore
    return preview ? (
        <Stack spacing={2}>
            <SingleLineKeyValue dark label="Type" value={t(type)} />
            <Divider />
            <SingleLineKeyValue dark label="Name" value={name} />
            <Divider>Route</Divider>
            <Route
                color="grey"
                stops={routes}
                timelineProps={{
                    sx: { pl: '2px' },
                }}
            />
        </Stack>
    ) : (
        <Stack spacing={3} />
    );
};

export default React.memo(BookingProductForm);

import React from 'react';
import { Box, Skeleton, Stack, Theme } from '@mui/material';
import { AgebandName, PricingType, PaxData } from '@travelity/api';
import { capitalizeFirstLetter } from '@travelity/api/generator/common';
import { SystemStyleObject } from '@mui/system';
import type {
    AgebandPricingData,
    FlatPricingData,
} from '@travelity/api/src/requests';

export interface PriceItemProps {
    currency: string;
    price: FlatPricingData | AgebandPricingData;
    isLoading: boolean;
    priceLabelSx?: SystemStyleObject<Theme>;
    pax: PaxData;
}

function numberWithSpaces(x: number) {
    const parts = x.toString().split('.');
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
    return parts.join('.');
}

export const PriceItem: React.FC<PriceItemProps> = props => {
    const { price, priceLabelSx = {}, currency, isLoading, pax } = props;

    const prices: { count: number; price: number; label: string }[] = [];
    if (price.type === PricingType.PER_PERSON) {
        prices.push({
            count: Object.values(pax).reduce(
                (sum, ageBand) => sum + (ageBand || 0),
                0
            ),
            price: price.unit_amount,
            label: '',
        });
    } else if (price.type === PricingType.PER_PRODUCT) {
        prices.push({
            count: 1,
            price: price.unit_amount,
            label: '',
        });
    } else {
        Object.values(AgebandName).forEach((k: AgebandName) => {
            const p = price[k];
            if (p && pax[k]) {
                prices.push({
                    count: pax[k],
                    price: p,
                    label: capitalizeFirstLetter(k),
                });
            }
        });
    }

    const totalPrice = prices.reduce((s, p) => s + p.count * p.price, 0);
    const totalPriceFormatted = price
        ? `${numberWithSpaces(totalPrice)} ${currency}`
        : '';

    const priceType =
        price.type === PricingType.PER_AGEBAND
            ? 'Per Agebands'
            : price.type === PricingType.PER_PERSON
            ? 'Per Person'
            : '';

    return (
        <>
            {!!price.base_amount && (
                <Stack
                    direction="row"
                    justifyContent="space-between"
                    sx={{ mb: 1 }}
                >
                    <Box
                        sx={{
                            color: '#6B748C',
                            fontSize: '12px',
                            fontWeight: '400',
                            '&': priceLabelSx,
                        }}
                    >
                        Base Price
                    </Box>
                    <Box
                        sx={{
                            color: '#2B395B',
                            textAlign: 'right',
                            fontSize: '14px',
                            fontWeight: '400',
                        }}
                    >
                        {isLoading ? (
                            <Skeleton width={60} />
                        ) : (
                            `${numberWithSpaces(price.base_amount)} ${currency}`
                        )}
                    </Box>
                </Stack>
            )}
            <Stack
                direction="row"
                justifyContent="space-between"
                sx={{ mb: 1 }}
            >
                <Box
                    sx={{
                        color: '#6B748C',
                        fontSize: '12px',
                        fontWeight: '400',
                        '&': priceLabelSx,
                    }}
                >
                    Total Price {priceType}
                </Box>
                <Box
                    sx={{
                        color: '#2B395B',
                        textAlign: 'right',
                        fontSize: '14px',
                        fontWeight: '400',
                    }}
                >
                    {isLoading ? <Skeleton width={60} /> : totalPriceFormatted}
                </Box>
            </Stack>
            {price.type !== PricingType.PER_PRODUCT &&
                prices.map(p => (
                    <Stack
                        direction="row"
                        justifyContent="space-between"
                        sx={{ mb: 1 }}
                    >
                        <Box
                            sx={{
                                color: '#A3ABBA',
                                fontSize: '12px',
                                fontWeight: '400',
                            }}
                        >
                            {p.label}
                        </Box>
                        <Box
                            sx={{
                                color: '#A3ABBA',
                                textAlign: 'right',
                                fontSize: '12px',
                                fontWeight: '400',
                            }}
                        >
                            {isLoading ? (
                                <Skeleton width={60} />
                            ) : (
                                `${p.count} x ${numberWithSpaces(
                                    p.price
                                )} ${currency}`
                            )}
                        </Box>
                    </Stack>
                ))}
        </>
    );
};

import { addDays, addMinutes, startOfDay } from 'date-fns';
import {
    type ComputeAvailabilityReqProductsItemDto,
    ComputeAvailabilityResItemDto,
    ComputeAvailabilityResItemEventsNewItemDto,
    TimezoneName,
} from '../../requests';

import {
    AvailabilityFilterValues,
    AvailableEvent,
    AvailableProduct,
} from './availability.types';
import { convertDurationDtoToDuration } from '../common.converters';
import { Period } from '../common.types';

export const availabilityEventDtoToAvailableEvent = (
    event: ComputeAvailabilityResItemEventsNewItemDto
): Omit<AvailableEvent, 'capacity' | 'schedule' | 'time'> => ({
    id: event.id,
    duration: event.date.duration
        ? convertDurationDtoToDuration(event.date.duration)
        : undefined,
    // price: event.
});

export const getIntervalTimes = (date: Period) => {
    const times = [];
    let { start } = date;
    const end = date.end || date.start;
    while (start <= end) {
        times.push(start);
        start = addMinutes(start, 15);
    }

    return times;
};

export const availabilityProductDtoToAvailabilityProduct = ({
    product,
    financials,
}: Omit<ComputeAvailabilityResItemDto, 'events'>): AvailableProduct => ({
    id: product.id,
    type: product.type,
    name: product.name,
    autoscaled: product.configuration.autoscaled,
    shared: product.configuration.shared,

    capacity: {
        id: product.capacity.id,
        name: product.capacity.reference.name,
        type: product.capacity.reference.type,
        default: product.capacity.optimal,
    },
    schedule: {
        id: product.schedule.id,
        name: product.schedule.reference.name,
        type: product.schedule.reference.type,
    },
    options: product.options?.items.map(o => ({
        id: o.id,
        name: o.name,
        type: o.type,
        pax: {},
        hasPrice: !!o.pricing,
        pricing: o?.pricing?.items?.length
            ? {
                  currency: o?.pricing?.items[0].currency.abbr,
                  price: o.pricing?.items[0].price.original,
              }
            : undefined,
    })),
    financials: {
        subtotal: financials.subtotal.original || financials.total.original,
        total: financials.total.original,
    },
    pricing: product.pricing
        ? {
              currency: product.pricing.currency.abbr,
              price: product.pricing.price.original,
          }
        : undefined,
    vacantSeats: product.capacity.vacant,
    duration: product.schedule?.duration?.estimated
        ? convertDurationDtoToDuration(product.schedule.duration.estimated)
        : undefined,
});

export const filterToRequestBodyConverter = (
    filters: AvailabilityFilterValues
) => {
    const products: ComputeAvailabilityReqProductsItemDto[] = [];

    if (filters.products) {
        filters.products.forEach(id => {
            products.push({ id });
        });
    }

    if (filters.productTypes) {
        filters.productTypes.forEach(type => {
            products.push({ type });
        });
    }
    return {
        date:
            filters.dates && filters.dates.startDate && filters.dates.endDate
                ? {
                      // timezone: {
                      //     name: Intl.DateTimeFormat().resolvedOptions()
                      //         .timeZone as TimezoneName,
                      // },
                      timezone: {
                          name: Intl.DateTimeFormat().resolvedOptions()
                              .timeZone as TimezoneName,
                      },
                      start: Math.round(
                          filters.dates.startDate.getTime() / 1000
                      ),
                      end:
                          Math.round(filters.dates.endDate.getTime() / 1000) +
                          24 * 60 * 60 -
                          1,
                  }
                : {
                      // timezone: {
                      //     name: Intl.DateTimeFormat().resolvedOptions()
                      //         .timeZone as TimezoneName,
                      // },
                      timezone: {
                          name: Intl.DateTimeFormat().resolvedOptions()
                              .timeZone as TimezoneName,
                      },
                      start: Math.round(
                          startOfDay(new Date()).getTime() / 1000
                      ),
                      end:
                          Math.round(
                              addDays(startOfDay(new Date()), 7).getTime() /
                                  1000
                          ) +
                          24 * 60 * 60 -
                          1,
                  },
        pax: filters.pax,
        products: products.length > 0 ? products : undefined,
        pricing: filters.pricing
            ? {
                  currency: {
                      abbr: filters.pricing.currency || 'USD',
                      name: filters.pricing.currency || 'USD',
                  },
                  min_price: filters.pricing.minPrice,
                  max_price: filters.pricing.maxPrice,
              }
            : undefined,
    };
};

/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { BookingStatus } from './BookingStatus';
import type { GetBookingResEventDto } from './GetBookingResEventDto';
import type { GetBookingResProductDto } from './GetBookingResProductDto';
import type { GetEventResBookingsItemAccessDto } from './GetEventResBookingsItemAccessDto';
import type { GetEventResBookingsItemFinancialsDto } from './GetEventResBookingsItemFinancialsDto';
import type { GetEventResBookingsItemNotesDto } from './GetEventResBookingsItemNotesDto';
import type { GetEventResBookingsItemOrderDto } from './GetEventResBookingsItemOrderDto';
import type { GetEventResBookingsItemSource0Dto } from './GetEventResBookingsItemSource0Dto';
import type { GetEventResBookingsItemSource1Dto } from './GetEventResBookingsItemSource1Dto';
import type { GetEventResBookingsItemTransactionsDto } from './GetEventResBookingsItemTransactionsDto';
import type { GetUserResCreatedDto } from './GetUserResCreatedDto';

export type GetOrderResBookingsItem1Dto = {
    /**
     * Type of the resource
     */
    resource_type: GetOrderResBookingsItem1Dto.resource_type;
    /**
     * Source of the booking
     */
    resource_id?: string;
    /**
     * Identifier of the document in the database.
     */
    id: string;
    /**
     * Details about who has created the database record and when.
     */
    created: GetUserResCreatedDto;
    /**
     * Details about who and when made the most recent update to the record.
     */
    last_updated?: GetUserResCreatedDto;
    /**
     * Details about who and when deleted/archived the record.
     */
    deleted?: GetUserResCreatedDto;
    /**
     * Contains info about the cancellation of the resource
     */
    cancelled?: GetUserResCreatedDto;
    /**
     * Details about all activities happening to the record
     */
    lifecycle: Array<GetUserResCreatedDto>;
    /**
     * Source of the booking
     */
    source: (GetEventResBookingsItemSource0Dto | GetEventResBookingsItemSource1Dto);
    /**
     * Order reference
     */
    product: GetBookingResProductDto;
    /**
     * Booked event
     */
    event?: GetBookingResEventDto;
    /**
     * Order reference
     */
    order: GetEventResBookingsItemOrderDto;
    /**
     * List of transactions
     */
    transactions?: GetEventResBookingsItemTransactionsDto;
    /**
     * List of transactions
     */
    financials: GetEventResBookingsItemFinancialsDto;
    /**
     * List of notes of some importance to the booking
     */
    notes?: GetEventResBookingsItemNotesDto;
    /**
     * Automatically computed status of the booking.
     */
    status: BookingStatus;
    /**
     * Access to the shared product
     */
    access: GetEventResBookingsItemAccessDto;
};

export namespace GetOrderResBookingsItem1Dto {

    /**
     * Type of the resource
     */
    export enum resource_type {
        BOOKING = 'booking',
    }


}

import React, {
    forwardRef,
    memo,
    useCallback,
    useEffect,
    useRef,
    useState,
} from 'react';
import { sortedUniqBy } from 'lodash';
// import { useTranslation } from 'react-i18next';
import { Box, Chip, Divider, IconButton, Skeleton, Stack } from '@mui/material';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { format } from 'date-fns';
import { enUS } from 'date-fns/locale';
import { AvailabilityProduct } from '@travelity/api';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import { ProductTypeIcon } from '../product-type-icon';
import DateChange from './components/date-change';
import { SwipeTimes } from './components/swipeable-times';

export interface AvailableItemProps {
    eventsByProduct?: AvailabilityProduct[];
    expanded?: boolean;
    day: number;
    date: Date;
    onEventClick?: (
        product: AvailabilityProduct,
        day: number,
        time: number
    ) => void;
    expand?: (v?: { day: number; date: Date }) => void;
    active?: boolean;
    isToday?: boolean;
    width?: number;
    isLoading?: boolean;
    maxHeight?: number;
    fullScreen?: boolean;
    availableDates?: number[];
    setFullScreen: (v?: number) => void;
    selectedTime?: number;
    selectedProduct?: string;
}

const DayAvailability = forwardRef<HTMLDivElement, AvailableItemProps>(
    (props, ref) => {
        const {
            eventsByProduct,
            active,
            isToday,
            isLoading,
            expanded,
            day,
            date,
            onEventClick,
            expand,
            width,
            maxHeight,
            fullScreen,
            availableDates,
            setFullScreen,
            selectedTime,
            selectedProduct,
        } = props;

        const scrollbarRef = useRef<any>();
        useEffect(() => {
            const timeout = setTimeout(() => {
                if (scrollbarRef.current && expanded) {
                    scrollbarRef.current.updateScroll();
                }
            }, 100);
            return () => clearTimeout(timeout);
        }, [expanded, date]);
        // const weekDay = date ? format(date, 'EEE', { locale: enUS }) : '';

        const showExpand = !!eventsByProduct?.length;

        const onEventClickCallback = useCallback(
            (
                availabilityProduct: AvailabilityProduct,
                d: number,
                time: number
            ) => {
                onEventClick?.(availabilityProduct, d, time);
                if (fullScreen) setFullScreen();
            },
            [fullScreen]
        );

        const [lastContent, setLastContent] = useState<{
            eventsByProduct?: AvailabilityProduct[];
            selectedTime?: number;
            selectedProduct?: string;
        }>();
        useEffect(() => {
            const timeout = setTimeout(() => {
                setLastContent({
                    eventsByProduct,
                    selectedProduct,
                    selectedTime,
                });
            }, 2);
            return () => clearTimeout(timeout);
        }, [eventsByProduct, selectedProduct, selectedTime]);
        const renderContent =
            showExpand ||
            (lastContent?.eventsByProduct === eventsByProduct &&
                lastContent?.selectedTime === selectedTime &&
                lastContent?.selectedProduct === selectedProduct);

        const content = eventsByProduct
            ?.slice(0, 3)
            .map(({ name, type, id, events, shared }, i) => (
                <Box
                    key={id}
                    sx={{
                        px: '6px',
                        position: 'relative',
                        overflow: 'hidden',
                    }}
                >
                    <Box
                        sx={{
                            ...(i !== 0
                                ? {
                                      mt: 1,
                                      pt: 1,
                                      borderTop: '1px solid #DFE1ED',
                                  }
                                : {}),
                            color: '#67728B',
                            fontSize: '12px',
                            display: 'flex',
                            alignItems: 'center',
                            flexWrap: 'nowrap',
                        }}
                    >
                        <ProductTypeIcon type={type} shared={shared} />
                        <Box
                            sx={{
                                ml: '6px',
                                flexGrow: 2,
                                color: '#67728B',
                                whiteSpace: 'nowrap',
                                pr: 1,
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                            }}
                        >
                            {name}
                        </Box>
                    </Box>
                    <Stack direction="row" sx={{ ml: '17px' }}>
                        {sortedUniqBy(events, 'time')
                            .slice(0, 4)
                            .map(({ time }) => (
                                <Chip
                                    key={time}
                                    size="small"
                                    clickable
                                    onClick={() =>
                                        onEventClick?.(
                                            eventsByProduct[i],
                                            day,
                                            time
                                        )
                                    }
                                    sx={{
                                        m: '1px 2px 2px 1px',
                                        height: 'auto',
                                        fontWeight: 500,
                                        '& > .MuiChip-label': {
                                            px: '6px',
                                        },
                                        ...(selectedTime === time &&
                                        selectedProduct === id
                                            ? {
                                                  bgcolor: '#55B5CF',
                                                  color: '#FFF',
                                                  '&:active': {
                                                      bgcolor: '#55B5CF',
                                                  },
                                                  '&:hover': {
                                                      bgcolor: '#55B5CF',
                                                  },
                                              }
                                            : {
                                                  bgcolor: '#DDF0F5',
                                                  color: '#3B4D7D',
                                                  '&:active': {
                                                      bgcolor: '#DDF0F5',
                                                  },
                                                  '&:hover': {
                                                      bgcolor: '#AAD9E7',
                                                  },
                                              }),
                                    }}
                                    variant="filled"
                                    label={format(time, 'HH:mm')}
                                />
                            ))}
                    </Stack>
                    <Box
                        sx={{
                            position: 'absolute',
                            right: 0,
                            bottom: 0,
                            background:
                                'linear-gradient(270deg, #FFFFFF 19.66%, rgba(255, 255, 255, 0) 100%)',
                            height: '40px',
                            width: '33%',
                            borderRadius: '0 0 8px 0',
                        }}
                    />
                </Box>
            ));

        const expandedContent = eventsByProduct?.map(
            ({ name, type, id, events, shared }, i) => (
                <Box
                    key={id}
                    sx={{
                        px: '6px',
                        position: 'relative',
                        overflow: 'hidden',
                    }}
                >
                    <Box
                        sx={{
                            ...(i !== 0
                                ? {
                                      mt: 1,
                                      pt: 1,
                                      borderTop: '1px solid #DFE1ED',
                                  }
                                : {}),
                            color: '#67728B',
                            fontSize: '12px',
                            display: 'flex',
                            alignItems: 'center',
                            flexWrap: 'nowrap',
                        }}
                    >
                        <ProductTypeIcon type={type} shared={shared} />
                        <Box
                            sx={{
                                ml: '6px',
                                flexGrow: 2,
                                color: '#67728B',
                                whiteSpace: 'nowrap',
                                pr: 1,
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                            }}
                        >
                            {name}
                        </Box>
                    </Box>
                    <SwipeTimes
                        events={events}
                        initialWidth={width ? width + 64 - 12 : undefined}
                        day={day}
                        availabilityProduct={eventsByProduct[i]}
                        onEventClick={onEventClickCallback}
                        selectedTime={
                            selectedProduct === id ? selectedTime : undefined
                        }
                    />
                </Box>
            )
        );

        return (
            <>
                <Stack
                    sx={{
                        '&:hover .fullscreen-button': {
                            display: 'block',
                        },
                        maxHeight: maxHeight ? maxHeight - 16 : undefined,
                        position: 'relative',
                        overflow: 'hidden',
                        background: '#FFFFFF',
                        borderRadius: '8px',
                        ...(expanded
                            ? {
                                  boxShadow:
                                      '0px 0px 20px rgba(178, 185, 205, 0.7)',
                                  // left:
                                  //     weekDay !== 'Sun' && weekDay !== 'Mon'
                                  //         ? -32
                                  //         : 0,
                                  width: width ? `${width + 64}px` : 'auto',
                                  height: 'auto',
                              }
                            : {
                                  height: '100%',
                                  boxShadow:
                                      '0px 0px 4px rgba(178, 185, 205, 0.5)',
                                  border: eventsByProduct?.length
                                      ? '1px solid #55B5CF'
                                      : undefined,
                              }),
                    }}
                >
                    <div ref={ref} data-day={active ? day : undefined} />
                    {isLoading ? (
                        <Stack>
                            <Stack direction="row" justifyContent="center">
                                <Skeleton
                                    width={24}
                                    sx={{ fontSize: '24px' }}
                                />
                            </Stack>
                            {(day + 1) % 5 === 0 && (
                                <>
                                    <Stack
                                        direction="row"
                                        sx={{ px: 0.75 }}
                                        gap={1}
                                    >
                                        <Skeleton
                                            variant="circular"
                                            width={20}
                                            height={20}
                                        />
                                        <Skeleton
                                            width={110}
                                            sx={{ fontSize: '16px' }}
                                        />
                                    </Stack>
                                    <Stack
                                        direction="row"
                                        sx={{
                                            pl: 4,
                                        }}
                                        gap={1}
                                    >
                                        <Skeleton
                                            width={40}
                                            sx={{ fontSize: '20px' }}
                                        />
                                        <Skeleton
                                            width={40}
                                            sx={{ fontSize: '20px' }}
                                        />
                                        <Skeleton
                                            width={40}
                                            sx={{ fontSize: '20px' }}
                                        />
                                    </Stack>
                                    <Divider sx={{ m: 0.75 }} />
                                    <Stack
                                        direction="row"
                                        sx={{ px: 0.75 }}
                                        gap={1}
                                    >
                                        <Skeleton
                                            variant="circular"
                                            width={20}
                                            height={20}
                                        />
                                        <Skeleton
                                            width={80}
                                            sx={{ fontSize: '16px' }}
                                        />
                                    </Stack>
                                    <Stack
                                        direction="row"
                                        sx={{
                                            pl: 4,
                                        }}
                                        gap={1}
                                    >
                                        <Skeleton
                                            width={40}
                                            sx={{ fontSize: '20px' }}
                                        />
                                        <Skeleton
                                            width={40}
                                            sx={{ fontSize: '20px' }}
                                        />
                                        <Skeleton
                                            width={40}
                                            sx={{ fontSize: '20px' }}
                                        />
                                    </Stack>
                                </>
                            )}
                        </Stack>
                    ) : (
                        <>
                            <Box sx={{ textAlign: 'center', mt: '6px' }}>
                                <Box
                                    component="span"
                                    sx={{
                                        color: '#2B395B',
                                        height: '24px',
                                        width: '24px',
                                        display: 'inline-block',
                                        fontWeight: 700,
                                        lineHeight: '24px',
                                        ...(isToday
                                            ? {
                                                  color: '#36869C',
                                                  background: '#EAEBED',
                                                  borderRadius: '32px',
                                              }
                                            : {}),
                                        ...(eventsByProduct?.length === 0
                                            ? {
                                                  color: '#B8BDCA',
                                              }
                                            : {}),
                                        ...(expanded
                                            ? {
                                                  width: 'auto',
                                                  fontWeight: 400,
                                                  letterSpacing: '0.4px',
                                                  mb: 2,
                                              }
                                            : {}),
                                    }}
                                >
                                    {!expanded || !date
                                        ? day
                                        : format(date, 'MMM d, EEE', {
                                              locale: enUS,
                                          }).toUpperCase()}
                                </Box>
                            </Box>
                            {showExpand && (
                                <Box
                                    className="fullscreen-button"
                                    sx={{
                                        position: 'absolute',
                                        top: expanded ? 4 : 0,
                                        right: expanded ? 4 : 0,
                                        display: expanded ? 'block' : 'none',
                                    }}
                                >
                                    <IconButton
                                        onClick={() => {
                                            if (expanded) expand?.();
                                            setFullScreen(day);
                                        }}
                                        size="small"
                                        sx={{
                                            color: '#B8BDCA',
                                            '&:hover': {
                                                color: '#2B395B',
                                            },
                                        }}
                                    >
                                        <OpenInNewIcon fontSize="inherit" />
                                    </IconButton>
                                </Box>
                            )}
                            <Box
                                sx={{ pb: 1, flexGrow: 1 }}
                                ref={scrollbarRef}
                                options={{ suppressScrollX: true }}
                                component={expanded ? PerfectScrollbar : 'div'}
                            >
                                {renderContent &&
                                    (expanded ? expandedContent : content)}

                                {!expanded && !!eventsByProduct?.length && (
                                    <Box
                                        sx={{
                                            position: 'absolute',
                                            bottom: 0,
                                            width: 1,
                                            background: '#F4F6FA',
                                            borderRadius: '0px 0px 6px 6px',
                                            textAlign: 'center',
                                            fontSize: '12px',
                                            '& > a': {
                                                color: '#2B395B',
                                            },
                                        }}
                                    >
                                        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                                        <a
                                            onClick={e => {
                                                e.preventDefault();
                                                expand?.({ day, date });
                                            }}
                                            href="#"
                                        >
                                            See All Products
                                        </a>
                                    </Box>
                                )}
                            </Box>
                        </>
                    )}
                </Stack>
                {active === false && !isLoading && (
                    <Box
                        sx={{
                            position: 'absolute',
                            top: 0,
                            left: 0,
                            background: '#FFFFFF',
                            opacity: 0.7,
                            width: '100%',
                            height: '100%',
                            borderRadius: '8px',
                        }}
                    />
                )}
                {fullScreen && (
                    <Dialog
                        open={fullScreen}
                        onClose={() => setFullScreen()}
                        PaperProps={{
                            sx: {
                                py: 1,
                                bgcolor: '#fff',
                                maxWidth: 616,
                            },
                        }}
                    >
                        <DialogTitle
                            sx={{
                                fontSize: '16px',
                                lineHeight: '16px',
                                textAlign: 'center',
                                fontWeight: 600,
                                color: '#2B395B',
                            }}
                            id="alert-dialog-title"
                        >
                            <DateChange
                                selectedDate={date}
                                handleDateChange={d =>
                                    setFullScreen(parseInt(format(d, 'd'), 10))
                                }
                                availableDates={availableDates}
                            />
                        </DialogTitle>
                        <DialogContent
                            sx={{
                                overflow: 'hidden',
                                p: 1,
                                '& > div': {
                                    pr: 0.5,
                                    maxHeight: 'calc(100vh - 230px)',
                                },
                            }}
                        >
                            <PerfectScrollbar>
                                {eventsByProduct?.map(
                                    ({ name, type, id, events, shared }, i) => (
                                        <Box
                                            key={id}
                                            sx={{
                                                px: '6px',
                                                position: 'relative',
                                                overflow: 'hidden',
                                            }}
                                        >
                                            <Box
                                                sx={{
                                                    ...(i !== 0
                                                        ? {
                                                              mt: 1,
                                                              pt: 1,
                                                              borderTop:
                                                                  '1px solid #DFE1ED',
                                                          }
                                                        : {}),
                                                    color: '#67728B',
                                                    fontSize: '12px',
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    flexWrap: 'nowrap',
                                                }}
                                            >
                                                <ProductTypeIcon
                                                    type={type}
                                                    shared={shared}
                                                />
                                                <Box
                                                    sx={{
                                                        ml: '6px',
                                                        flexGrow: 2,
                                                        color: '#67728B',
                                                        whiteSpace: 'nowrap',
                                                        pr: 1,
                                                        overflow: 'hidden',
                                                        textOverflow:
                                                            'ellipsis',
                                                    }}
                                                >
                                                    {name}
                                                </Box>
                                            </Box>
                                            <SwipeTimes
                                                initialWidth={584}
                                                events={events}
                                                day={day}
                                                availabilityProduct={
                                                    eventsByProduct?.[i]
                                                }
                                                onEventClick={
                                                    onEventClickCallback
                                                }
                                                selectedTime={
                                                    selectedProduct === id
                                                        ? selectedTime
                                                        : undefined
                                                }
                                            />
                                        </Box>
                                    )
                                )}
                            </PerfectScrollbar>
                        </DialogContent>
                    </Dialog>
                )}
            </>
        );
    }
);

export default memo(DayAvailability);

import React, { useCallback, useEffect, useRef, useState } from 'react';
import {
    ContentType,
    User,
    userDetailsToUserDetailsDto,
    useUpdateUser,
    useUploadAvatarUser,
    useUser,
} from '@travelity/api';
import { Box, Divider, Stack, Typography } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import Check from '@mui/icons-material/Check';
import {
    CreatableTags,
    TagDropdown,
    TextField,
    useForm,
} from '@travelity/form';
import { emailRefEx } from '@travelity/utils';
import {
    Card,
    IconButton,
    IconButtonGroup,
    LoadingOverlay,
    TagListItem,
} from '@travelity/ui';
import { useTranslation } from 'react-i18next';
import CloseIcon from '@mui/icons-material/Close';
import { PageTitle } from '../../../components/page-title';
import { occupations } from '../../register-steps/components/occupation-select';
import { SingleLineTextField } from '../../../components/booking-participants/components/single-line-text-field';
import {
    birthPasswordSchema,
    nameContactsSchema,
} from './personal-details.schema';
import { CropperModal } from './cropper-modal';

function PersonalDetails() {
    const { data, isLoading } = useUser();
    const { t } = useTranslation('common');

    const { mutate: update, isLoading: isSaving } = useUpdateUser({});

    // Card 1

    // Avatar upload
    const [file, setFile] = useState<File>();
    const [croppedImage, setCroppedImage] = useState<
        | {
              content: string;
              name: string;
          }
        | undefined
    >();
    const { mutate: upload, isLoading: isUploading } = useUploadAvatarUser();
    const handleImgChange: React.ChangeEventHandler<HTMLInputElement> = e => {
        if (e.target.files) setFile(e.target.files[0]);
    };
    const inputRef = useRef<HTMLInputElement>(null);
    const closeModal = () => {
        if (inputRef.current) {
            // @ts-ignore
            inputRef.current.value = null;
        }
        setFile(undefined);
    };

    const [form1Editing, setForm1Editing] = React.useState(false);
    const {
        Form: Form1,
        submit: submit1,
        reset: reset1,
        formState: {
            isSubmitting: isSubmitting1,
            isSubmitted: isSubmitted1,
            isDirty: isDirty1,
        },
    } = useForm({
        schema: nameContactsSchema,
        onSubmit: useCallback(
            (user: Partial<User>) => {
                update(userDetailsToUserDetailsDto(user), {
                    onSuccess: () => setForm1Editing(false),
                    onError: () => setForm1Editing(false),
                });
                if (croppedImage) {
                    const contentStart = croppedImage.content.indexOf(',');
                    upload({
                        name: croppedImage?.name || 'avatar.png',
                        content_type: ContentType.IMAGE_PNG,
                        // @ts-ignore
                        width: 242,
                        // @ts-ignore
                        height: 242,
                        content: croppedImage.content.substring(
                            contentStart + 1
                        ),
                    });
                }
            },
            [file, croppedImage]
        ),
    });
    useEffect(() => {
        if (form1Editing && data) {
            reset1({
                fullName: data.fullName,
                occupation: data.occupation,
                emails: data.emails,
                numbers: data.numbers,
            });
        }
    }, [form1Editing, reset1, data]);

    // Card 2
    const [form2Editing, setForm2Editing] = React.useState(false);
    const {
        Form: Form2,
        submit: submit2,
        reset: reset2,
        formState: {
            isSubmitting: isSubmitting2,
            isSubmitted: isSubmitted2,
            isDirty: isDirty2,
        },
    } = useForm({
        schema: birthPasswordSchema,
        onSubmit: useCallback((user: Partial<User>) => {
            update(userDetailsToUserDetailsDto(user), {
                onSuccess: () => setForm2Editing(false),
            });
        }, []),
    });
    useEffect(() => {
        if (data) {
            reset2({
                birthDate: data.birthDate,
                birthPlace: data.birthPlace,
                nationality: data.nationality,
                passportN: data.passportN,
                issuedBy: data.issuedBy,
                issuedAt: data.issuedAt,
                expiresAt: data.expiresAt,
                citizenship: data.citizenship,
            });
        }
    }, [form2Editing, reset2, data]);

    return isLoading ? null : (
        <>
            <PageTitle sx={{ pl: 1 }}>Profile Settings</PageTitle>
            <Stack sx={{ p: 1 }} direction="row" gap={5}>
                <Stack sx={{ width: 424 }}>
                    <Form1>
                        <Card
                            sx={{ p: 3 }}
                            buttons={
                                <IconButtonGroup>
                                    {!form1Editing ? (
                                        <IconButton
                                            onClick={() =>
                                                setForm1Editing(true)
                                            }
                                            tooltip="Edit"
                                            icon={<EditIcon fontSize="small" />}
                                        />
                                    ) : (
                                        <>
                                            <IconButton
                                                color="success.main"
                                                hoverColor="success.dark"
                                                onClick={() => submit1()}
                                                disabled={
                                                    !isDirty1 ||
                                                    isSaving ||
                                                    isUploading
                                                }
                                                tooltip="Save"
                                                icon={
                                                    <Check fontSize="small" />
                                                }
                                            />
                                            <Divider sx={{ mx: 0.75 }} />
                                            <IconButton
                                                color="error.main"
                                                hoverColor="error.dark"
                                                onClick={() =>
                                                    setForm1Editing(false)
                                                }
                                                disabled={
                                                    isSaving || isUploading
                                                }
                                                tooltip="Cancel"
                                                icon={
                                                    <CloseIcon fontSize="small" />
                                                }
                                            />
                                        </>
                                    )}
                                </IconButtonGroup>
                            }
                        >
                            {(isSaving || isUploading) &&
                                (isSubmitting1 || isSubmitted1) && (
                                    <LoadingOverlay
                                        title="Please wait..."
                                        subTitle="It’ll just take a moment."
                                    />
                                )}
                            {form1Editing ? (
                                <>
                                    <Stack alignItems="center" gap={1}>
                                        <Stack alignItems="center">
                                            <Box
                                                sx={{
                                                    width: 242,
                                                    height: 242,
                                                    position: 'relative',
                                                }}
                                            >
                                                <Box
                                                    sx={{
                                                        width: 1,
                                                        height: 1,
                                                        borderRadius: '50%',
                                                        background: `url(${
                                                            croppedImage?.content ||
                                                            data?.avatar ||
                                                            '/profile-pic.svg'
                                                        })`,
                                                        backgroundSize:
                                                            'contain',
                                                        position: 'relative',
                                                        overflow: 'hidden',
                                                    }}
                                                >
                                                    <Box
                                                        onClick={() =>
                                                            inputRef.current?.click()
                                                        }
                                                        sx={{
                                                            bgcolor:
                                                                'rgba(43, 57, 91, 0.5)',
                                                            color: '#FFF',
                                                            cursor: 'pointer',
                                                            fontSize: '13px',
                                                            textAlign: 'center',
                                                            lineHeight: '20px',
                                                            fontWeight: 500,
                                                            bottom: 0,
                                                            position:
                                                                'absolute',
                                                            p: 1.5,
                                                            py: 2,
                                                            left: 0,
                                                            right: 0,
                                                        }}
                                                    >
                                                        Change Image
                                                    </Box>
                                                    <Box
                                                        component="input"
                                                        type="file"
                                                        accept="image/*"
                                                        onChange={
                                                            handleImgChange
                                                        }
                                                        ref={inputRef}
                                                        sx={{
                                                            display: 'none',
                                                        }}
                                                    />
                                                </Box>
                                                {/* <IconButton */}
                                                {/*    withShadow */}
                                                {/*    sx={{ */}
                                                {/*        position: 'absolute', */}
                                                {/*        bottom: '16px', */}
                                                {/*        right: '16px', */}
                                                {/*    }} */}
                                                {/*    icon={ */}
                                                {/*        <CloseIcon fontSize="small" /> */}
                                                {/*    } */}
                                                {/*    color="error.main" */}
                                                {/*    hoverColor="error.dark" */}
                                                {/*    onClick={() => {}} */}
                                                {/* /> */}
                                            </Box>
                                        </Stack>
                                        <TextField
                                            sx={{
                                                mx: '10px',
                                                width: '200px',
                                            }}
                                            autoFocus
                                            InputProps={{
                                                inputProps: {
                                                    sx: {
                                                        textAlign: 'center',
                                                        fontWeight: '700',
                                                    },
                                                },
                                                sx: {
                                                    width: '200px',
                                                    fontSize: '14px',
                                                    pl: 1.5,
                                                },
                                            }}
                                            placeholder="Type Full Name"
                                            name="fullName"
                                            variant="standard"
                                            showErrors
                                            helperText=""
                                        />
                                        <TagDropdown
                                            label=""
                                            name="occupation"
                                            options={occupations}
                                            sx={{
                                                background: '#FFF',
                                                boxShadow:
                                                    '0px 0px 12px 0px rgba(178, 185, 205, 0.60)',
                                                padding: '2px 0',
                                            }}
                                            optionsSx={{
                                                background: 'transparent',
                                            }}
                                        />
                                    </Stack>
                                    <Divider sx={{ my: 3 }} />
                                    <Stack gap={2}>
                                        <CreatableTags
                                            name="emails"
                                            leaveLast
                                            regEx={emailRefEx}
                                            inputProps={{
                                                sx: {
                                                    '&& .MuiInputBase-root': {
                                                        paddingRight: '10px',
                                                    },
                                                },
                                                label: 'Email',
                                                placeholder:
                                                    'Type email and press enter',
                                            }}
                                        />
                                        <CreatableTags
                                            name="numbers"
                                            regEx={/^\+?\d+ \d+$/}
                                            inputProps={{
                                                sx: {
                                                    '&& .MuiInputBase-root': {
                                                        paddingRight: '10px',
                                                    },
                                                },
                                                label: 'Phone Numbers',
                                                placeholder:
                                                    'Type number and press enter',
                                            }}
                                        />
                                    </Stack>
                                </>
                            ) : (
                                <>
                                    <Stack alignItems="center" gap={1}>
                                        <Stack alignItems="center">
                                            <Box
                                                sx={{
                                                    width: 242,
                                                    height: 242,
                                                    position: 'relative',
                                                }}
                                            >
                                                <Box
                                                    sx={{
                                                        width: 1,
                                                        height: 1,
                                                        borderRadius: '50%',
                                                        background: `url(${
                                                            data?.avatar ||
                                                            '/profile-pic.svg'
                                                        })`,
                                                        backgroundSize:
                                                            'contain',
                                                        position: 'relative',
                                                        overflow: 'hidden',
                                                    }}
                                                />
                                            </Box>
                                        </Stack>
                                        <Typography
                                            sx={{
                                                py: 0.5,
                                                color: '#2B395B',
                                                fontSize: '14px',
                                                fontWeight: '700',
                                                lineHeight: '100%',
                                                textAlign: 'center',
                                            }}
                                        >
                                            {data?.fullName}
                                        </Typography>
                                        {data?.occupation && (
                                            <Typography
                                                sx={{
                                                    py: 0.5,
                                                    color: '#6B758D',
                                                    fontSize: '14px',
                                                    fontWeight: '400',
                                                    lineHeight: '100%',
                                                    textAlign: 'center',
                                                }}
                                            >
                                                {t(data.occupation)}
                                            </Typography>
                                        )}
                                    </Stack>
                                    <Divider sx={{ my: 3 }} />
                                    <Stack gap={2}>
                                        <Typography
                                            sx={{
                                                color: '#9198AA',
                                                fontSize: '14px',
                                                lineHeight: '11px',
                                            }}
                                        >
                                            Emails
                                        </Typography>
                                        <Stack
                                            direction="row"
                                            flexWrap="wrap"
                                            sx={{ py: '3px', ml: '-2px' }}
                                        >
                                            {data?.emails?.map(field => (
                                                <TagListItem
                                                    key={field.value}
                                                    label={field.value}
                                                />
                                            ))}
                                        </Stack>
                                        {!!data?.numbers?.length && (
                                            <>
                                                <Typography
                                                    sx={{
                                                        color: '#9198AA',
                                                        fontSize: '14px',
                                                        lineHeight: '11px',
                                                    }}
                                                >
                                                    Phone Numbers
                                                </Typography>
                                                <Stack
                                                    direction="row"
                                                    flexWrap="wrap"
                                                    sx={{
                                                        py: '3px',
                                                        ml: '-2px',
                                                    }}
                                                >
                                                    {data.numbers.map(field => (
                                                        <TagListItem
                                                            key={field.value}
                                                            label={field.value}
                                                        />
                                                    ))}
                                                </Stack>
                                            </>
                                        )}
                                    </Stack>
                                </>
                            )}
                        </Card>
                    </Form1>
                </Stack>
                <Stack sx={{ flexGrow: 2, maxWidth: '450px' }} gap={2}>
                    <Form2>
                        <Card
                            sx={{ p: 3 }}
                            buttons={
                                <IconButtonGroup>
                                    {!form2Editing ? (
                                        <IconButton
                                            onClick={() =>
                                                setForm2Editing(true)
                                            }
                                            tooltip="Edit"
                                            icon={<EditIcon fontSize="small" />}
                                        />
                                    ) : (
                                        <>
                                            <IconButton
                                                color="success.main"
                                                hoverColor="success.dark"
                                                onClick={() => {
                                                    submit2();
                                                }}
                                                disabled={
                                                    !isDirty2 ||
                                                    isSaving ||
                                                    isUploading
                                                }
                                                tooltip="Save"
                                                icon={
                                                    <Check fontSize="small" />
                                                }
                                            />
                                            <Divider sx={{ mx: 0.75 }} />
                                            <IconButton
                                                color="error.main"
                                                hoverColor="error.dark"
                                                onClick={() =>
                                                    setForm2Editing(false)
                                                }
                                                tooltip="Cancel"
                                                disabled={
                                                    isSaving || isUploading
                                                }
                                                icon={
                                                    <CloseIcon fontSize="small" />
                                                }
                                            />
                                        </>
                                    )}
                                </IconButtonGroup>
                            }
                        >
                            <Stack gap={0.5}>
                                <Divider>Birth Details</Divider>
                                <SingleLineTextField
                                    label="Date of Birth"
                                    name="birthDate"
                                    width="138px"
                                    readOnly={!form2Editing}
                                />
                                <SingleLineTextField
                                    label="Place of Birth"
                                    name="birthPlace"
                                    width="138px"
                                    readOnly={!form2Editing}
                                />
                                <SingleLineTextField
                                    label="Nationality"
                                    name="nationality"
                                    width="138px"
                                    readOnly={!form2Editing}
                                />
                                <Divider sx={{ mt: 1.5 }}>
                                    Identification Document
                                </Divider>
                                <Stack direction="row" gap={1}>
                                    <Box sx={{ width: 0.5 }}>
                                        <SingleLineTextField
                                            label="Passport №"
                                            name="passportN"
                                            readOnly={!form2Editing}
                                        />
                                        <SingleLineTextField
                                            label="Issued at"
                                            name="issuedAt"
                                            readOnly={!form2Editing}
                                        />
                                        <SingleLineTextField
                                            label="Citizenship"
                                            name="citizenship"
                                            readOnly={!form2Editing}
                                        />
                                    </Box>
                                    <Box sx={{ width: 0.5 }}>
                                        <SingleLineTextField
                                            label="Issued by"
                                            name="issuedBy"
                                            readOnly={!form2Editing}
                                        />
                                        <SingleLineTextField
                                            label="Expires at"
                                            name="expiresAt"
                                            readOnly={!form2Editing}
                                        />
                                    </Box>
                                </Stack>
                            </Stack>
                            {(isSaving || isUploading) &&
                                (isSubmitting2 || isSubmitted2) && (
                                    <LoadingOverlay
                                        title="Please wait..."
                                        subTitle="It’ll just take a moment."
                                    />
                                )}
                        </Card>
                    </Form2>
                    <Card
                        sx={{ p: 3 }}
                        buttons={
                            <IconButtonGroup>
                                <IconButton
                                    onClick={() => {}}
                                    disabled
                                    tooltip="Edit"
                                    icon={<EditIcon fontSize="small" />}
                                />
                            </IconButtonGroup>
                        }
                    >
                        <Stack gap={0.5}>
                            <Divider>Attachments</Divider>
                            No attachments
                        </Stack>
                        {/* {(isSaving || isUploading) && */}
                        {/*    (isSubmitting2 || isSubmitted2) && ( */}
                        {/*        <LoadingOverlay */}
                        {/*            title="Please wait..." */}
                        {/*            subTitle="It’ll just take a moment." */}
                        {/*        /> */}
                        {/*    )} */}
                    </Card>
                </Stack>
            </Stack>

            <CropperModal
                modalOpen={!!file}
                src={file ? URL.createObjectURL(file) : undefined}
                setPreview={v =>
                    setCroppedImage({ content: v, name: file?.name || '' })
                }
                closeModal={closeModal}
            />
        </>
    );
}

export default PersonalDetails;

import React, { useCallback, useRef } from 'react';
import DialogTitle from '@mui/material/DialogTitle';
import { Stack, Typography } from '@mui/material';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import { Button, LoadingOverlay } from '@travelity/ui';
import Dialog from '@mui/material/Dialog';
import {
    TourEventStaffMemberPosition,
    Staff,
    StaffPosition,
} from '@travelity/api';
import { Select, useForm } from '@travelity/form';

import SelectStaff from '../select-staff/select-staff';
import schema from './staff-modal.schema';

export interface StaffModalProps {
    open: boolean;
    isLoading?: boolean;
    productId: string;
    setOpen: (v: boolean) => void;
    onConfirm: (v: StaffModalData) => void;
    onClose: () => void;
    existingEmails: string[];
    defaultValues?: Partial<StaffModalData>;
    availablePositions?: StaffPosition[];
    position?: 'center' | 'end';
}

export interface StaffModalData {
    staff: Staff;
    position: TourEventStaffMemberPosition;
}

export const positionOptions = [
    {
        value: 'guide',
        label: 'Guide',
    },
    {
        value: 'driver',
        label: 'Driver',
    },
    {
        value: 'operator',
        label: 'Operator',
    },
];

export const StaffModal: React.FC<StaffModalProps> = props => {
    const {
        open,
        isLoading,
        setOpen,
        onConfirm,
        onClose,
        productId,
        existingEmails,
        defaultValues = {} as StaffModalData,
        availablePositions = [],
        position = 'end',
    } = props;

    const { Form, getValues, errorFields } = useForm({
        schema,
        validateInitially: true,
        mode: 'onChange',
        defaultValues,
    });

    const handleCancel = useCallback(() => {
        setOpen(false);
        onClose();
    }, [setOpen, onClose]);

    const onSubmit = useCallback(() => {
        const values = getValues();

        onConfirm(values as StaffModalData);
    }, [getValues]);

    const availablePositionOptions = positionOptions.filter(({ value }) =>
        availablePositions.includes(value as StaffPosition)
    );

    return (
        <Form>
            <Dialog
                open={open}
                onClose={handleCancel}
                sx={{
                    '& .MuiDialog-container': { justifyContent: position },
                }}
                PaperProps={{
                    sx: { py: 1, bgcolor: '#fff', maxWidth: '432px' },
                }}
            >
                <DialogTitle
                    sx={{
                        fontSize: '16px',
                        lineHeight: '16px',
                        fontWeight: 600,
                        color: '#2B395B',
                        pr: 6,
                    }}
                    id="alert-dialog-title"
                >
                    <Typography sx={{ fontWeight: 600 }}>
                        Add a Staff
                    </Typography>
                </DialogTitle>
                <DialogContent
                    sx={{
                        overflow: 'hidden',
                        pr: 1,
                    }}
                >
                    <Stack
                        sx={{
                            pr: 2,
                            py: 1,
                            height: 'calc(100% - 76px)',
                            '& > div': {
                                maxHeight: 'calc(100vh - 284px)',
                            },
                        }}
                        gap={2}
                    >
                        <Select
                            name="position"
                            label="Position"
                            placeholder="Select Position"
                            options={availablePositionOptions}
                        />
                        <SelectStaff
                            name="staff"
                            label="Full Name or Email"
                            placeholder="Select Full Name or Email"
                            productId={productId}
                            existingEmails={existingEmails}
                        />
                    </Stack>
                    {isLoading && (
                        <LoadingOverlay
                            title="Please wait..."
                            subTitle="It’ll just take a moment."
                        />
                    )}
                </DialogContent>
                <DialogActions sx={{ px: 3, py: 2 }}>
                    <Button
                        color="neutral"
                        variant="contained"
                        onClick={handleCancel}
                        disabled={isLoading}
                        sx={{
                            px: '26px',
                            py: '10px',
                        }}
                    >
                        Discard
                    </Button>

                    <Button
                        color="secondary"
                        variant="contained"
                        disabled={!!errorFields.length || isLoading}
                        onClick={onSubmit}
                        sx={{
                            py: '10px',
                            px: 4,
                        }}
                    >
                        Add
                    </Button>
                </DialogActions>
            </Dialog>
        </Form>
    );
};

export const useStaffModal = () => {
    const [staffModalOpen, setStaffModalState] = React.useState<
        Partial<StaffModalData> | undefined
    >();

    const setStaffModalOpen = useCallback(
        (v: boolean) => {
            setStaffModalState(v ? {} : undefined);
        },
        [setStaffModalState]
    );
    const ref = useRef({
        open: !!staffModalOpen,
        setOpen: setStaffModalOpen,
        defaultValues: staffModalOpen,
    });
    ref.current = {
        open: !!staffModalOpen,
        setOpen: setStaffModalOpen,
        defaultValues: staffModalOpen,
    };

    const addStaff = useCallback((initial?: Partial<StaffModalData>) => {
        setStaffModalState(initial || {});
    }, []);

    const closeModal = useCallback(() => {
        setStaffModalOpen(false);
    }, []);

    const StaffModalWrapper = useCallback<
        React.FC<Omit<StaffModalProps, 'open' | 'setOpen'>>
    >(props => {
        return ref.current.open ? (
            <StaffModal {...props} {...ref.current} />
        ) : null;
    }, []);

    return {
        StaffModal: StaffModalWrapper,
        staffModalOpen: !!staffModalOpen,
        addStaff,
        closeModal,
    };
};

/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CreateTeamReqDto } from '../models/CreateTeamReqDto';
import type { CreateTeamResDto } from '../models/CreateTeamResDto';
import type { GetTeamResDto } from '../models/GetTeamResDto';
import type { GetTeams1ReqDto } from '../models/GetTeams1ReqDto';
import type { GetTeamsResDto } from '../models/GetTeamsResDto';
import type { GetTeamsResItemMembersDto } from '../models/GetTeamsResItemMembersDto';
import type { UpdateMembersTeamsReqDto } from '../models/UpdateMembersTeamsReqDto';
import type { UpdateMembersTeamsResDto } from '../models/UpdateMembersTeamsResDto';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class TeamsService {

    /**
     * List user's teams
     * @param authorization Bearer token (JWT) used for endpoint caller authentication
     * @returns GetTeamsResDto Successful Response
     * @throws ApiError
     */
    public static getTeams(
authorization: string,
): CancelablePromise<GetTeamsResDto> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/teams',
            headers: {
                'authorization': authorization,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Create Team
     * @param authorization Bearer token (JWT) used for endpoint caller authentication
     * @param requestBody 
     * @returns CreateTeamResDto Successful Response
     * @throws ApiError
     */
    public static createTeam(
authorization: string,
requestBody: CreateTeamReqDto,
): CancelablePromise<CreateTeamResDto> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/teams',
            headers: {
                'authorization': authorization,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Fetch team
     * @param teamId Id of team to fetch
     * @param authorization Bearer token (JWT) used for endpoint caller authentication
     * @returns GetTeamResDto Successful Response
     * @throws ApiError
     */
    public static getTeam(
teamId: string,
authorization: string,
): CancelablePromise<GetTeamResDto> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/teams/{team_id}',
            path: {
                'team_id': teamId,
            },
            headers: {
                'authorization': authorization,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Update members list of the team
     * @param teamId ID of the team being patched
     * @param authorization Bearer token (JWT) used for endpoint caller authentication
     * @param requestBody 
     * @returns UpdateMembersTeamsResDto Successful Response
     * @throws ApiError
     */
    public static updateMembersTeams(
teamId: string,
authorization: string,
requestBody: UpdateMembersTeamsReqDto,
): CancelablePromise<UpdateMembersTeamsResDto> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/api/v1/teams/{team_id}/members',
            path: {
                'team_id': teamId,
            },
            headers: {
                'authorization': authorization,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * List team members
     * @param authorization Bearer token (JWT) used for endpoint caller authentication
     * @param requestBody 
     * @returns GetTeamsResItemMembersDto Successful Response
     * @throws ApiError
     */
    public static getTeams1(
authorization: string,
requestBody: GetTeams1ReqDto,
): CancelablePromise<GetTeamsResItemMembersDto> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/teams/community_members',
            headers: {
                'authorization': authorization,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

}

import React, { memo } from 'react';

import { TextField } from '@mui/material';
import { Control, useController } from 'react-hook-form';
import { FilterOption } from '../../filters.types';

export interface SearchBarProps {
    filter: FilterOption;
    control: Control;
    onEnter: () => void;
}

export const SearchBar: React.FC<SearchBarProps> = memo(
    (props: SearchBarProps) => {
        const { filter, control, onEnter } = props;
        const { name, selectText } = filter;

        const { field } = useController({ name, control, defaultValue: '' });
        // const { filter, value: defaultValue, ...rest } = props;
        // const { options, label, type, name } = filter;
        // const { control } = useFormContext();
        // const {
        //     field: { value, onChange },
        // } = useController({
        //     name,
        //     control,
        //     defaultValue,
        // });
        //
        // const open = Boolean(anchorEl);
        //
        // const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        //     setAnchorEl(anchorEl ? null : event.currentTarget);
        // };
        // const handleClose = (
        //     event: React.MouseEvent<HTMLDivElement> | MouseEvent | TouchEvent
        // ) => {
        //     if ((event.target as HTMLElement)?.localName === 'body') return;
        //     setAnchorEl(null);
        // };
        //
        // const onUpdate = useCallback((val: FilterValue) => {
        //     onChange(val);
        //     setAnchorEl(null);
        // }, []);
        //
        // const onRemove = useCallback(() => {
        //     setAnchorEl(null);
        //     onChange(undefined);
        // }, []);

        return (
            <TextField
                onKeyDown={ev => {
                    if (ev.key === 'Enter') {
                        onEnter();
                    }
                }}
                size="small"
                placeholder={selectText}
                value={field.value}
                onChange={field.onChange}
            />
        );
    }
);

import {
    useInfiniteQuery,
    useMutation,
    useQuery,
    useQueryClient,
} from '@tanstack/react-query';
import { useAuth0 } from '@auth0/auth0-react';
import React, { useCallback, useMemo, useState } from 'react';
import { useIsChanged } from '@travelity/web/src/hooks';
import { formatValue } from '@travelity/form';
import { FilterPaxValue } from '@travelity/web/src/components/filters/filters.types';
import {
    OrdersService,
    DiscountType,
    GetOrderResDto,
    type GetOrderResBookingsItem0Dto,
    type GetOrderResBookingsItem1Dto,
    PaxData,
    CreateBookingReqProductDto,
    OrderStatus,
} from '../../requests';
import { useComputePriceBookings } from '../../queries';
import { getOrderItemDtoToOrder } from './order.converters';
import {
    CustomInfiniteQueryOptions,
    CustomMutationOptions,
    CustomQueryOptions,
} from '../common.types';

export enum RequestTypes {
    EVENT = 'event',
    ORDER = 'order',
}

export const useCancelOrder = (
    options?: CustomMutationOptions<
        { orderId: string; reason?: string },
        ReturnType<typeof OrdersService.cancelOrders>
    >
) => {
    const { getAccessTokenSilently } = useAuth0();

    return useMutation({
        mutationFn: async ({ orderId, reason }) => {
            const token = await getAccessTokenSilently();
            const authorization = `Bearer ${token}`;
            return OrdersService.cancelOrders(orderId, authorization, {
                message: reason || '',
            });
        },
        ...options,
        onSuccess: (...args) => {
            // TODO update order in cache
            options?.onSuccess?.(...args);
        },
    });
};

export const useUpdatePrice = (
    params: {
        id?: string;
        date?: {
            start: number;
        };
        capacity_option_id?: string | undefined;
        options?: {
            items: {
                id: string;
                customers: {
                    pax: PaxData;
                };
            }[];
        }[];
        customers?: {
            items?: never[];
            pax: FilterPaxValue;
        };
        pricing?:
            | {
                  discount: {
                      type: DiscountType;
                      amount: number;
                  };
              }
            | undefined;
    },
    initialPrice?: {
        total: number;
        subtotal: number;
    }
) => {
    const [state, setState] = useState<{
        data: {
            beforeDiscount: number;
            final: number;
        };
        rawData?: {
            total: number;
            subtotal: number;
        };
        isLoading: boolean;
        discount: number;
        discountType: DiscountType;
        options: string[];
    }>({
        data: {
            beforeDiscount: 0,
            final: 0,
        },
        isLoading: true,
        discount: 0,
        discountType: DiscountType.RELATIVE,
        options: [],
    });
    const { mutate: getPrices } = useComputePriceBookings({
        onSuccess: data => {
            setState(prevData => ({
                ...prevData,
                data: {
                    final: data.total.original as number,
                    beforeDiscount:
                        (data.subtotal.original as number) ||
                        (data.total.original as number),
                },
                rawData: {
                    total: data.total.original as number,
                    subtotal:
                        (data.subtotal.original as number) ||
                        (data.total.original as number),
                },
                isLoading: false,
            }));
        },
    });

    const needsUpdate = useMemo(() => {
        const paramsDiscount = params.pricing?.discount.amount || 0;
        const paramsDiscountType =
            params.pricing?.discount?.type || DiscountType.RELATIVE;
        const paramsOptions =
            params?.options?.items.map(
                o =>
                    `${o.id}:${
                        o.customers?.pax ? formatValue(o.customers?.pax) : ''
                    }`
            ) || [];

        return (
            paramsDiscountType !== state.discountType ||
            paramsDiscount !== state.discount ||
            paramsOptions.join(',') !== state.options.join(',')
        );
    }, [state.discount, state.discountType, state.options, params]);

    const initialPriceChanged = useIsChanged(initialPrice);
    const paramsChanged = useIsChanged(params);
    React.useEffect(() => {
        if (initialPriceChanged && initialPrice) {
            setState({
                data: {
                    final: initialPrice.total,
                    beforeDiscount: initialPrice.subtotal,
                },
                rawData: initialPrice,
                isLoading: false,
                discount: 0,
                discountType: DiscountType.RELATIVE,
                options: [],
            });
        }
    }, [params, initialPrice, initialPriceChanged, paramsChanged]);

    const update = useCallback(() => {
        if (params.customers?.pax) {
            setState(prevState => ({
                ...prevState,
                isLoading: true,
                discount: params.pricing?.discount?.amount || 0,
                discountType:
                    params.pricing?.discount?.type || DiscountType.RELATIVE,
                options:
                    params.options?.items.map(
                        o =>
                            `${o.id}:${
                                o.customers?.pax
                                    ? formatValue(o.customers?.pax)
                                    : ''
                            }`
                    ) || [],
            }));
            getPrices({
                requestBody: params as CreateBookingReqProductDto,
            });
        }
    }, [params, getPrices]);

    return {
        data: state.data,
        rawData: state.rawData,
        isLoading: state.isLoading,
        changed: needsUpdate,
        update,
    };
};

export interface UseOrdersData {
    statuses?: OrderStatus[];
    action?: 'created' | 'last_updated' | 'deleted';
    at_start?: number;
    at_end?: number;
    by?: string;
    text_search?: string;
    pageNumber?: number;
    pageSize?: number;
}

const useOrdersKey = 'useOrdersKey';
export const useOrders = (
    params: UseOrdersData = {},
    options: CustomQueryOptions<ReturnType<typeof OrdersService.getOrders>> = {}
) => {
    const newParams = { ...params, pageSize: 1000 };
    const { getAccessTokenSilently } = useAuth0();
    const { data, ...other } = useQuery({
        queryKey: [useOrdersKey, newParams],
        queryFn: async () => {
            const token = await getAccessTokenSilently();
            const authorization = `Bearer ${token}`;
            return OrdersService.getOrders(
                authorization,
                params.action,
                params.at_start,
                params.at_end,
                undefined,
                params.by,
                params.statuses,
                params.text_search,
                newParams.pageNumber,
                newParams.pageSize || 1000
            );
        },
        ...options,
    });

    const parsedData = useMemo(
        () => data?.items?.map(getOrderItemDtoToOrder),
        [data]
    );

    return {
        data: parsedData,
        ...other,
    };
};

const useOrdersLazyKey = 'useOrdersLazyKey';
export const useOrdersLazy = (
    params: UseOrdersData = {},
    options: CustomInfiniteQueryOptions<
        ReturnType<typeof OrdersService.getOrders>
    > = {}
) => {
    const { getAccessTokenSilently } = useAuth0();
    const pageSize = params.pageSize || 20;

    const { data, ...other } = useInfiniteQuery({
        queryKey: [useOrdersLazyKey, params],
        queryFn: async ({ pageParam = 0 }) => {
            const token = await getAccessTokenSilently();
            const authorization = `Bearer ${token}`;
            return OrdersService.getOrders(
                authorization,
                params.action,
                params.at_start,
                params.at_end,
                undefined,
                params.by,
                params.statuses,
                params.text_search,
                pageParam,
                pageSize
            );
        },
        getNextPageParam: (lastPage, allPages) => {
            if (!lastPage.items || lastPage.items.length < pageSize)
                return undefined;
            return allPages.length;
        },
        ...options,
    });

    const parsedData = useMemo(
        () =>
            data?.pages
                ? data.pages
                      .map(page =>
                          (page.items || []).map(getOrderItemDtoToOrder)
                      )
                      .reduce((arr, cur) => [...arr, ...cur], [])
                : undefined,
        [data]
    );

    return {
        data: parsedData,
        ...other,
    };
};

const useOrderKey = 'useOrderKey';
export const useOrder = (
    id?: string,
    options: CustomQueryOptions<ReturnType<typeof OrdersService.getOrder>> = {}
) => {
    const { getAccessTokenSilently } = useAuth0();
    const { data, ...other } = useQuery({
        queryKey: [useOrderKey, id],
        queryFn: async () => {
            const token = await getAccessTokenSilently();
            const authorization = `Bearer ${token}`;
            return OrdersService.getOrder(id as string, authorization);
        },
        enabled: !!id,
        ...options,
    });

    return {
        data: useMemo(
            () => (data ? getOrderItemDtoToOrder(data) : data),
            [data]
        ),
        ...other,
    };
};

export const useUpdateOrderBooking = () => {
    const queryClient = useQueryClient();
    const queriesData = queryClient.getQueriesData({
        queryKey: ['OrdersServiceGetOrder'],
        exact: false,
    });

    const update = (
        bookingId: string,
        callback: (
            b: GetOrderResBookingsItem0Dto | GetOrderResBookingsItem1Dto
        ) => GetOrderResBookingsItem0Dto | GetOrderResBookingsItem1Dto
    ) => {
        queriesData.forEach(([queryKey]) => {
            queryClient.setQueryData<GetOrderResDto>(
                queryKey,
                // @ts-ignore
                order => {
                    if (!order) return undefined;
                    const bookings =
                        order.bookings?.map(b =>
                            b.id === bookingId ? callback(b) : b
                        ) || [];
                    return {
                        ...order,
                        bookings,
                    };
                }
            );
        });
    };

    return update;
};

export const useExport = (
    options?: CustomMutationOptions<
        {
            id: string;
        },
        ReturnType<typeof OrdersService.exportDetailsOrders>
    >
) => {
    const { getAccessTokenSilently } = useAuth0();

    return useMutation(
        async ({ id }) => {
            const token = await getAccessTokenSilently();
            const authorization = `Bearer ${token}`;
            return OrdersService.exportDetailsOrders(id, authorization);
        },
        {
            onSuccess: data => {
                window.open(data.url, '_blank')?.focus();
            },
            ...options,
        }
    );
};

/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CreateAssetReqDto } from '../models/CreateAssetReqDto';
import type { CreateAssetResDto } from '../models/CreateAssetResDto';
import type { GetAssetResDto } from '../models/GetAssetResDto';
import type { GetAssetsResDto } from '../models/GetAssetsResDto';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class AssetsService {

    /**
     * List assets
     * @param authorization Bearer token (JWT) used for endpoint caller authentication
     * @param textSearch Search term
     * @returns GetAssetsResDto Successful Response
     * @throws ApiError
     */
    public static getAssets(
authorization: string,
textSearch?: string,
): CancelablePromise<GetAssetsResDto> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/assets',
            headers: {
                'authorization': authorization,
            },
            query: {
                'text_search': textSearch,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Create Asset
     * @param authorization Bearer token (JWT) used for endpoint caller authentication
     * @param requestBody 
     * @returns CreateAssetResDto Successful Response
     * @throws ApiError
     */
    public static createAsset(
authorization: string,
requestBody: CreateAssetReqDto,
): CancelablePromise<CreateAssetResDto> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/assets',
            headers: {
                'authorization': authorization,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Fetch asset
     * @param assetId Id of the asset being fetched
     * @param authorization Bearer token (JWT) used for endpoint caller authentication
     * @returns GetAssetResDto Successful Response
     * @throws ApiError
     */
    public static getAsset(
assetId: string,
authorization: string,
): CancelablePromise<GetAssetResDto> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/assets/{asset_id}',
            path: {
                'asset_id': assetId,
            },
            headers: {
                'authorization': authorization,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

}

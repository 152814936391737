import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Box,
    Button,
    Tooltip,
} from '@mui/material';
import React, { useCallback, useMemo } from 'react';
import CheckIcon from '@mui/icons-material/Check';
import { IconButton, LoadingOverlay } from '@travelity/ui';
import CloseIcon from '@mui/icons-material/Close';
import EditIcon from '@mui/icons-material/Edit';
import { useSideFormAccordion } from './side-form-accordion.hooks';

export interface SideFormAccordionProps {
    id: string;
    title: string;
    children: React.ReactNode;
    isEditable?: boolean;
    isSaving?: boolean;
    isLoading?: boolean;
    disabled?: boolean;
    notDirty?: boolean;
    errors?: Record<string, any>;
    onCancel?: () => void;
    onSave?: () => void;
    onDone?: () => void;
    handleEditClick?: () => void;
}

export const SideFormAccordion: React.FC<SideFormAccordionProps> = React.memo(
    (props: SideFormAccordionProps) => {
        const {
            id,
            title,
            children,
            notDirty,
            isSaving,
            isLoading,
            isEditable,
            disabled,
            errors = {},
            onCancel,
            onSave,
            onDone,
            handleEditClick,
        } = props;

        const { openTab, isEdit, toggle, edit } = useSideFormAccordion();
        const expanded = openTab === id;
        const editing = expanded && isEdit;

        const toggleExpanded = useCallback(() => {
            toggle(id);
        }, [toggle, id]);

        const onEditClick = () => {
            if (handleEditClick) {
                handleEditClick();
            } else {
                edit();
            }
        };

        const onSaveClick = (e?: React.MouseEvent) => {
            // do request
            e?.stopPropagation();
            onSave?.();
        };

        const onCancelClick = () => {
            onCancel?.();
            // reset
            // change url to view
        };

        const stringErrors = useMemo(() => {
            return Object.values(errors)
                .map(error => error?.message)
                .filter(v => v) as string[];
        }, [errors]);

        return (
            <Accordion
                disableGutters
                expanded={expanded}
                onChange={toggleExpanded}
                disabled={disabled}
            >
                <AccordionSummary sx={{ borderBottom: '1px solid #DFE1ED' }}>
                    {title}
                    {expanded &&
                        isEditable &&
                        (editing ? (
                            <>
                                {stringErrors.length ? (
                                    <Tooltip
                                        componentsProps={{
                                            tooltip: {
                                                sx: {
                                                    borderRadius: '8px',
                                                },
                                            },
                                        }}
                                        title={
                                            <>
                                                {stringErrors.map(error => (
                                                    <li key={error}>{error}</li>
                                                ))}
                                            </>
                                        }
                                    >
                                        <Button
                                            type="submit"
                                            onClick={onSaveClick}
                                            form={id}
                                            sx={{
                                                ml: 2,
                                                padding: '4px',
                                                background: '#FFF',
                                                cursor: 'default',
                                                minWidth: 'auto',
                                                boxShadow:
                                                    '0px 1px 12px 0px rgba(178, 185, 205, 0.60)',
                                                borderRadius: '12px',
                                                '&&&&:hover': {
                                                    boxShadow:
                                                        '0px 1px 12px 0px rgba(178, 185, 205, 0.60)',
                                                },
                                            }}
                                        >
                                            <CheckIcon
                                                sx={{
                                                    fontSize: '20px',
                                                    lineHeight: '20px',
                                                    color: 'grey.400',
                                                }}
                                            />
                                        </Button>
                                    </Tooltip>
                                ) : onDone ? (
                                    <Box
                                        sx={{
                                            ml: 2,
                                            my: 0.25,
                                            textAlign: 'center',
                                            lineHeight: '14px',
                                            color: '#6B748C',
                                            fontSize: '14px',
                                            '&:hover': {
                                                color: 'success.dark',
                                            },
                                            px: 1,
                                            py: 1,
                                            borderRadius: '12px',
                                            background: '#FFF',
                                            boxShadow:
                                                '0px 1px 12px 0px rgba(178, 185, 205, 0.60)',
                                            minWidth: 'auto',
                                            '&&&&:hover': {
                                                boxShadow:
                                                    '0px 1px 12px 0px rgba(178, 185, 205, 0.60)',
                                            },
                                        }}
                                        component="span"
                                        onClick={onDone}
                                    >
                                        Done
                                    </Box>
                                ) : (
                                    <IconButton
                                        withShadow
                                        sx={{
                                            ml: 1,
                                        }}
                                        icon={<CheckIcon fontSize="small" />}
                                        color={
                                            !isSaving && !notDirty
                                                ? 'success.main'
                                                : 'grey.400'
                                        }
                                        hoverColor="success.dark"
                                        disabled={!!isSaving || notDirty}
                                        onClick={onSaveClick}
                                        form={id}
                                        tooltip={!notDirty ? 'Save' : undefined}
                                        tooltipPlacement="bottom"
                                    />
                                )}
                                {!!onCancel && (
                                    <IconButton
                                        withShadow
                                        sx={{ ml: 1 }}
                                        icon={<CloseIcon fontSize="small" />}
                                        color="error.main"
                                        hoverColor="error.dark"
                                        onClick={onCancelClick}
                                        disabled={!!isSaving}
                                        tooltip="Cancel"
                                        tooltipPlacement="bottom"
                                    />
                                )}
                            </>
                        ) : (
                            <IconButton
                                withShadow
                                sx={{ ml: 2 }}
                                icon={<EditIcon fontSize="small" />}
                                onClick={onEditClick}
                                tooltip="Edit"
                            />
                        ))}
                </AccordionSummary>
                <AccordionDetails
                    sx={{
                        py: 3,
                    }}
                >
                    {isLoading ? <LoadingOverlay /> : children}
                    {isSaving && !isLoading && <LoadingOverlay />}
                </AccordionDetails>
            </Accordion>
        );
    }
);

import React, { memo, useCallback, useMemo } from 'react';

import { Box, Chip, ClickAwayListener, Paper } from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { useController, useFormContext } from 'react-hook-form';
import { formatValue as formatPaxValue } from '@travelity/form';
import { format } from 'date-fns';
import { isArray } from 'lodash';
import Transitions from '../../../transitions/transitions';
import { FilterSettings } from '../filter-settings';
import { FilterOption, FilterTypes, FilterValue } from '../../filters.types';
import { Popper } from '../../../popper';

export interface FilterItemProps {
    remove?: FilterValue;
    value: FilterValue;
    filter: FilterOption;
}

export const FilterItem: React.FC<FilterItemProps> = memo(
    (props: FilterItemProps) => {
        const { filter, value: defaultValue, ...rest } = props;
        const { options, label, type, name } = filter;
        const { control } = useFormContext();
        const {
            field: { value, onChange },
        } = useController({
            name,
            control,
            defaultValue,
        });

        const selectedValue = useMemo(() => {
            switch (type) {
                case FilterTypes.PAX:
                    return formatPaxValue(value);
                case FilterTypes.DROPDOWN: {
                    const arrayValue: string[] = !isArray(value)
                        ? [value]
                        : value;
                    if (arrayValue?.length >= 3) {
                        return `${arrayValue.length} selected`;
                    }
                    return options
                        ?.filter(o => arrayValue.includes(o.value))
                        .map(o => o.label)
                        .map(o => (o.length > 20 ? `${o.slice(0, 20)}...` : o))
                        .join(', ');
                }
                case FilterTypes.KEYWORD:
                    return value;
                case FilterTypes.DATES: {
                    if (value.startDate && value.endDate) {
                        return `${format(
                            value.startDate,
                            'd MMM, y'
                        )} - ${format(value.endDate, 'd MMM, y')}`;
                    }
                    if (value.startDate) {
                        return `After ${format(value.startDate, 'd MMM, y')}`;
                    }
                    if (value.endDate) {
                        return `Before ${format(value.endDate, 'd MMM, y')}`;
                    }
                    return '';
                }
                case FilterTypes.DATE: {
                    if (value) {
                        return `${format(value, 'd MMM, y')}`;
                    }
                    return '';
                }
                case FilterTypes.PRICE: {
                    if (value.minPrice && value.maxPrice) {
                        return `${value.minPrice} ${value.currency} - ${value.maxPrice} ${value.currency}`;
                    }
                    if (value.minPrice) {
                        return `More than ${value.minPrice} ${value.currency}`;
                    }
                    if (value.maxPrice) {
                        return `Less than ${value.maxPrice} ${value.currency}`;
                    }
                    return '';
                }
                case FilterTypes.TAGS:
                    return value.join(', ');
                default:
                    return '';
            }
        }, [value, options, type]);

        const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(
            null
        );
        const open = Boolean(anchorEl);

        const handleClick = (event: React.MouseEvent<HTMLElement>) => {
            setAnchorEl(anchorEl ? null : event.currentTarget);
        };
        const handleClose = (
            event: React.MouseEvent<HTMLDivElement> | MouseEvent | TouchEvent
        ) => {
            if ((event.target as HTMLElement)?.localName === 'body') return;
            setAnchorEl(null);
        };

        const onUpdate = useCallback((val: FilterValue) => {
            onChange(val);
            setAnchorEl(null);
        }, []);

        const onRemove = useCallback(() => {
            setAnchorEl(null);
            onChange(undefined);
        }, []);

        return (
            <>
                <Chip
                    {...rest}
                    sx={{
                        // ...(rest.sx || {}),
                        borderRadius: '20px',
                        '& .MuiChip-label': {
                            whiteSpace: 'nowrap',
                            mt: '-4px',
                        },
                    }}
                    variant="outlined"
                    color="primary"
                    size="small"
                    label={
                        <>
                            <Box
                                sx={{
                                    pr: 0.5,
                                    pl: '6px',
                                    fontWeight: 400,
                                    color: '#9198AA',
                                }}
                                component="span"
                            >
                                {label}
                            </Box>
                            <Box
                                component="span"
                                onClick={handleClick}
                                sx={{
                                    background: 'rgba(85, 181, 207, 0.2)',
                                    color: '#2B395B',
                                    lineHeight: '14px',
                                    fontSize: '14px',
                                    fontWeight: 400,
                                    display: 'inline-flex',
                                    textAlign: 'center',
                                    borderRadius: '14px',
                                    minWidth: '25px',
                                    height: '26px',
                                    cursor: 'pointer',
                                    py: 0.75,
                                    px: 1,
                                    ml: 0.5,
                                    mt: 0.5,
                                }}
                            >
                                <span>{selectedValue}</span>
                                <KeyboardArrowDownIcon
                                    sx={{
                                        fontSize: '14px',
                                        ml: '3px',
                                    }}
                                />
                            </Box>
                        </>
                    }
                />

                <Popper
                    placement="bottom-end"
                    role={undefined}
                    transition
                    sx={{ zIndex: 10000 }}
                    anchorEl={anchorEl}
                    open={open}
                    // anchorOrigin={{
                    //     vertical: 'bottom',
                    //     horizontal: 'right',
                    // }}
                    // transformOrigin={{
                    //     vertical: 'top',
                    //     horizontal: 'right',
                    // }}
                    popperOptions={{
                        modifiers: [
                            {
                                name: 'offset',
                                options: {
                                    offset: [0, 8],
                                },
                            },
                        ],
                    }}
                >
                    {({ TransitionProps }) => (
                        <Transitions in={open} {...TransitionProps}>
                            <Paper
                                sx={{
                                    borderRadius: '12px',
                                    boxShadow:
                                        '0px 0px 16px 0px rgba(178, 185, 205, 0.40)',
                                    background: '#FFF',
                                    p: 2,
                                }}
                            >
                                <ClickAwayListener onClickAway={handleClose}>
                                    <div>
                                        <FilterSettings
                                            filter={filter}
                                            value={value}
                                            onRemove={onRemove}
                                            onUpdate={onUpdate}
                                        />
                                    </div>
                                </ClickAwayListener>
                            </Paper>
                        </Transitions>
                    )}
                </Popper>
            </>
        );
    }
);

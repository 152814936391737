import React, { useCallback, useEffect, useState } from 'react';
import { Stack } from '@mui/material';
import {
    Booking,
    BookingStatus,
    DiscountType,
    PaymentStatus,
    ProductOptionType,
    useEvent,
    useProduct,
    useUpdateBookingNotes,
    useUpdateBookingOptions,
    useUpdateBookingParticipants,
} from '@travelity/api';
import { useSnackbar } from 'notistack';
import { useForm } from '@travelity/form';

import participantsSchema from '../booking-participants/booking-participants.schema';
import { BookingParticipantsForm } from '../booking-participants';
import financialInformationSchema from '../booking-financial-information/booking-financial-information.schema';
import { BookingFinancialInformationForm } from '../booking-financial-information';
import productSchema from '../booking-product/booking-product.schema';
import productOptionsSchema from '../booking-product-options/booking-product-options.schema';
import { BookingProductForm } from '../booking-product';
import scheduleSchema from '../booking-schedule/booking-schedule.schema';
import { BookingScheduleForm } from '../booking-schedule';
import notesSchema from '../booking-notes/booking-notes.schema';
import { BookingNotesForm } from '../booking-notes';
import { PaymentStatusTag } from '../payment-status';
import { BookingStatusTag } from '../booking-status-tag';
import { participantsToParticipantsForm } from '../booking-participants/booking-participants.converters';
import { useLayoutContext } from '../../layouts/main-layout/main-layout';
import { ActivityHistory } from '../activity-history';
import { BookingProductOptionsForm } from '../booking-product-options';
import { MoveBookingModal } from '../move-booking-modal';
import { SideFormAccordion } from '../side-form-accordion';
import { useSideFormAccordion } from '../side-form-accordion/side-form-accordion.hooks';

export interface BookingPreviewProps {
    booking: Booking;
    updateBooking?: () => void;
    closeForm?: () => void;

    // In case of booking under order
    siblingBookings: Booking[];
}

export const BookingPreview: React.FC<BookingPreviewProps> = React.memo(
    (props: BookingPreviewProps) => {
        const {
            booking,
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            updateBooking,
            closeForm,
            siblingBookings,
        } = props;
        const { view, openTab, isEdit } = useSideFormAccordion();
        const readonly =
            !booking.permissions.canEdit ||
            booking.status === BookingStatus.CANCELLED;

        const { data: event } = useEvent(booking.eventId);
        const { data: product } = useProduct(booking.product.id);

        // TODO handle siblings
        console.log(siblingBookings);
        const { enqueueSnackbar } = useSnackbar();
        const { mutate: updateParticipants, isLoading: isSavingParticipants } =
            useUpdateBookingParticipants(booking.participants.travelers || [], {
                onSuccess: () => {
                    view();
                },
                onError: (error: any) => {
                    if (error?.status === 409) {
                        enqueueSnackbar('Nothing to save', {
                            variant: 'error',
                        });
                    } else if (error?.body) {
                        enqueueSnackbar(error.body, { variant: 'error' });
                    }
                },
            });

        const bookingId = booking.id;
        const onSubmit = useCallback(
            (data: any, e?: React.BaseSyntheticEvent) => {
                e?.preventDefault();
                const key = e?.target.id;
                if (key === 'participants') {
                    updateBooking?.();
                } else if (key === 'financialInformation') {
                    // update(
                    //     {
                    //         bookingId,
                    //         requestBody: {
                    //             financials: {
                    //                 price: {
                    //                     discount: data,
                    //                 },
                    //             },
                    //         },
                    //     },
                    //     {
                    //         onSuccess: () => {
                    //             updateBooking();
                    //         },
                    //     }
                    // );
                }
            },
            [bookingId]
        );
        const {
            Form: ScheduleForm,
            reset: resetSchedule,
            // formState: { errors: scheduleErrors },
        } = useForm({
            defaultValues: {
                start: booking.date.start,
            },
            onSubmit,
            mode: 'onChange',
            schema: scheduleSchema,
        });
        const {
            Form: ParticipantsForm,
            reset: resetParticipants,
            submit: submitParticipants,
            formState: {
                errors: participantsErrors,
                isDirty: isParticipantsDirty,
            },
        } = useForm({
            defaultValues: participantsToParticipantsForm(booking.participants),
            onSubmit: useCallback(
                (data: any, e?: React.BaseSyntheticEvent) => {
                    e?.preventDefault();
                    updateParticipants({
                        bookingId,
                        pax: data.pax,
                        items: data.travelers,
                    });
                },
                [bookingId]
            ),
            mode: 'onChange',
            schema: participantsSchema,
        });
        const {
            Form: FinancialInformationForm,
            reset: resetFinancialInformation,
            formState: { errors: financialInformationErrors },
        } = useForm({
            defaultValues: booking.product.pricing.discount || {
                type: DiscountType.RELATIVE,
                amount: 0,
            },
            onSubmit,
            mode: 'onChange',
            schema: financialInformationSchema,
        });
        const {
            Form: ProductForm,
            reset: resetProduct,
            // formState: { errors: productErrors },
        } = useForm({
            defaultValues: { values: [] },
            onSubmit,
            mode: 'onChange',
            schema: productSchema,
        });

        // Options
        const { mutate: updateOptions, isLoading: isSavingOptions } =
            useUpdateBookingOptions(booking.product.options || [], {
                onSuccess: () => {
                    view();
                },
                onError: (error: any) => {
                    if (error?.status === 409) {
                        enqueueSnackbar('Nothing to save', {
                            variant: 'error',
                        });
                    } else if (error?.body) {
                        enqueueSnackbar(error.body, { variant: 'error' });
                    }
                },
            });
        const {
            Form: ProductOptionsForm,
            submit: submitProductOptions,
            reset: resetProductOptions,
            formState: { isDirty: isOptionsDirty },
        } = useForm({
            defaultValues: {
                options:
                    booking.product.options?.map(o => ({
                        uuid: o.id,
                        name: o.name as string,
                        type: o.type as ProductOptionType,
                        pax: o?.pax || {},
                        hasPrice: !!o.pricing,
                        pricing: o.pricing,
                    })) || [],
            },
            onSubmit: useCallback(
                (data: any, e?: React.BaseSyntheticEvent) => {
                    e?.preventDefault();
                    updateOptions({
                        bookingId,
                        items: data.options.map(o => ({
                            id: o.id,
                            pax: o.pax,
                            name: o.name,
                            type: o.type,
                        })),
                    });
                },
                [bookingId]
            ),
            mode: 'onChange',
            schema: productOptionsSchema,
        });

        // Notes
        const { mutate: updateNotes, isLoading: isSaving } =
            useUpdateBookingNotes(booking.notes, {
                onSuccess: () => {
                    view();
                },
                onError: (error: any) => {
                    if (error?.status === 409) {
                        enqueueSnackbar('Nothing to save', {
                            variant: 'error',
                        });
                    } else if (error?.body) {
                        enqueueSnackbar(error.body, { variant: 'error' });
                    }
                },
            });
        const {
            Form: NotesForm,
            reset: resetNotes,
            submit: notesSubmit,
            formState: { errors: notesErrors, isDirty: isNotesDirty },
        } = useForm({
            defaultValues: {
                notes:
                    booking.notes?.map(({ id, content }) => ({
                        id,
                        value: content,
                    })) || [],
            },
            onSubmit: useCallback(
                (data: any, e?: React.BaseSyntheticEvent) => {
                    e?.preventDefault();
                    updateNotes({
                        bookingId,
                        notes: data.notes?.map(
                            ({ id, value }: { id: string; value: string }) => ({
                                id,
                                content: value,
                            })
                        ),
                    });
                },
                [bookingId]
            ),
            mode: 'onChange',
            schema: notesSchema,
        });

        const reset = useCallback(() => {
            resetSchedule({
                start: booking.date.start,
            });
            resetParticipants(
                participantsToParticipantsForm(booking.participants)
            );
            resetFinancialInformation(
                booking.product.pricing.discount || {
                    type: DiscountType.RELATIVE,
                    amount: 0,
                }
            );
            resetProduct({ values: [] });
            resetProductOptions({
                options:
                    booking.product.options?.map(o => ({
                        uuid: o.id,
                        name: o.name as string,
                        type: o.type as ProductOptionType,
                        pax: o.pax || {},
                        hasPrice: !!o.pricing,
                        pricing: o.pricing,
                    })) || [],
            });
            resetNotes({
                notes: booking.notes?.map(({ id, content }) => ({
                    id,
                    value: content,
                })),
            });
        }, [booking]);

        const onCancel = useCallback(() => {
            reset();
            view();
        }, [view, reset]);

        useEffect(() => {
            reset();
        }, [booking]);

        const { setMainIsLoading } = useLayoutContext();
        useEffect(() => {
            setMainIsLoading(isSaving);
        }, [isSaving]);

        const [moveBooking, setMoveBooking] = useState(false);

        return (
            <>
                <Stack
                    direction="row"
                    alignItems="center"
                    gap={2}
                    sx={{
                        px: 2,
                        height: '46px',
                        py: '10px',
                    }}
                >
                    <BookingStatusTag status={booking.status} />
                    <PaymentStatusTag
                        status={
                            booking.financials?.debt
                                ? PaymentStatus.NOT_PAID
                                : PaymentStatus.PAID
                        }
                    />
                </Stack>

                <SideFormAccordion
                    id="schedule"
                    title="Date and Time"
                    isEditable={!readonly && false}
                    onDone={() => setMoveBooking(true)}
                    handleEditClick={() => setMoveBooking(true)}
                >
                    <ScheduleForm id="schedule">
                        <BookingScheduleForm
                            date={booking.date}
                            preview={!isEdit || openTab !== 'schedule'}
                        />
                    </ScheduleForm>
                </SideFormAccordion>
                <SideFormAccordion
                    id="participants"
                    title="Participants"
                    isEditable={!readonly}
                    errors={participantsErrors}
                    isLoading={!event}
                    isSaving={isSavingParticipants}
                    notDirty={!isParticipantsDirty}
                    onSave={submitParticipants}
                    onCancel={onCancel}
                >
                    <ParticipantsForm>
                        {event && (
                            <BookingParticipantsForm
                                preview={!isEdit || openTab !== 'participants'}
                                route={event.route}
                            />
                        )}
                    </ParticipantsForm>
                </SideFormAccordion>
                <SideFormAccordion
                    id="financialInformation"
                    title="Pricing and Financials"
                    isEditable={!readonly && false}
                    errors={financialInformationErrors}
                    isSaving={isSaving}
                    onCancel={onCancel}
                >
                    <FinancialInformationForm id="financialInformation">
                        <BookingFinancialInformationForm
                            financials={booking.financials}
                            pricing={booking.product.pricing}
                            transactions={booking.transactions}
                            options={booking.product.options}
                            bookingId={booking.id}
                            pax={booking.participants.pax}
                            preview={
                                !isEdit || openTab !== 'financialInformation'
                            }
                            refetch={updateBooking}
                        />
                    </FinancialInformationForm>
                </SideFormAccordion>
                <SideFormAccordion id="product" title="Product">
                    <ProductForm id="product">
                        <BookingProductForm
                            product={booking.product}
                            route={event?.route}
                            preview={!isEdit || openTab !== 'product'}
                        />
                    </ProductForm>
                </SideFormAccordion>
                <SideFormAccordion
                    id="product-options"
                    title="Product Options"
                    isEditable={!readonly}
                    isSaving={isSavingOptions}
                    onSave={submitProductOptions}
                    notDirty={!isOptionsDirty}
                    onCancel={onCancel}
                    disabled={product && !product.options?.items.length}
                >
                    <ProductOptionsForm id="product-options">
                        {product && (
                            <BookingProductOptionsForm
                                preview={
                                    !isEdit || openTab !== 'product-options'
                                }
                                product={product}
                                bookingPax={booking.participants.pax}
                            />
                        )}
                    </ProductOptionsForm>
                </SideFormAccordion>
                <SideFormAccordion
                    id="notes"
                    title="Notes"
                    isEditable={!readonly}
                    onCancel={onCancel}
                    isSaving={isSaving}
                    notDirty={!isNotesDirty}
                    onSave={notesSubmit}
                    errors={notesErrors}
                >
                    <NotesForm id="notes">
                        <BookingNotesForm
                            preview={!isEdit || openTab !== 'notes'}
                        />
                    </NotesForm>
                </SideFormAccordion>
                <SideFormAccordion id="activity-log" title="Activity Log">
                    <ActivityHistory
                        entity="booking"
                        lifecycle={booking.lifecycle}
                    />
                </SideFormAccordion>

                <MoveBookingModal
                    handleCancel={() => setMoveBooking(false)}
                    handleConfirm={() => {
                        updateBooking?.();
                        setMoveBooking(false);
                        closeForm?.();
                    }}
                    booking={booking}
                    open={moveBooking}
                />
            </>
        );
    }
);

import React, { useCallback, useEffect } from 'react';
import {
    User,
    userDetailsToUserDetailsDto,
    UserSubscription,
    userWorkweekToDetailsDto,
    useUpdateUser,
    useUser,
} from '@travelity/api';
import { Box, Divider, Stack, Typography } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import Close from '@mui/icons-material/Close';
import Check from '@mui/icons-material/Check';
import { useForm, WeekdayPicker, Weekdays } from '@travelity/form';
import {
    Card,
    IconButton,
    IconButtonGroup,
    LoadingOverlay,
} from '@travelity/ui';
import { PageTitle } from '../../../components/page-title';
import WeekdayRow from '../../register-steps/components/weekday-row';
import { workweekSchema } from './settings.schema';
import SelectTimezone from '../../../components/select-timezone/select-timezone';

function Settings() {
    const { data, isLoading } = useUser();

    const { mutate: update, isLoading: isSaving } = useUpdateUser({});

    // Card 0
    const [form0Editing, setForm0Editing] = React.useState(false);
    const {
        Form: Form0,
        submit: submit0,
        reset: reset0,
        formState: { isSubmitting: isSubmitting0, isSubmitted: isSubmitted0 },
    } = useForm({
        onSubmit: useCallback((user: Partial<User>) => {
            update(userDetailsToUserDetailsDto(user), {
                onSuccess: () => setForm0Editing(false),
            });
        }, []),
    });
    useEffect(() => {
        if (data) {
            reset0({
                timeZone: data.timeZone,
            });
        }
    }, [form0Editing, reset0, data]);

    // Card 3
    const [form3Editing, setForm3Editing] = React.useState(false);
    const {
        Form: Form3,
        submit: submit3,
        reset: reset3,
        watch: watch3,
        getValues: getValues3,
        setValue: setValue3,
        formState: { isSubmitting: isSubmitting3, isSubmitted: isSubmitted3 },
    } = useForm({
        schema: workweekSchema,
        onSubmit: useCallback((user: Partial<User>) => {
            update(userWorkweekToDetailsDto(user), {
                onSuccess: () => setForm3Editing(false),
            });
        }, []),
    });

    const disableWeekdays =
        (data?.subscription as UserSubscription)?.user?.calendar &&
        !(data?.subscription as UserSubscription).user?.calendar?.workweek
            ?.customizable;
    useEffect(() => {
        if (data) {
            reset3(
                disableWeekdays
                    ? {
                          weekdays: [
                              Weekdays.MONDAY,
                              Weekdays.TUESDAY,
                              Weekdays.WEDNESDAY,
                              Weekdays.THURSDAY,
                              Weekdays.FRIDAY,
                              Weekdays.SATURDAY,
                              Weekdays.SUNDAY,
                          ],
                      }
                    : {
                          weekdays: data.weekdays,
                          weekdayHours: data.weekdayHours,
                      }
            );
        }
    }, [form3Editing, reset3, data, disableWeekdays]);

    const weekdays = watch3('weekdays');

    const weekdayHours = watch3('weekdayHours');
    useEffect(() => {
        if (weekdays) {
            const values = getValues3();
            const currentWeekdayHours = values.weekdayHours;
            const weekdayHoursNew: Partial<{
                [v in Weekdays]: {
                    start: number;
                    end: number;
                }[];
            }> = {};

            weekdays.forEach(w => {
                weekdayHoursNew[w] = currentWeekdayHours?.[w] || [
                    {
                        start: 0,
                        end: 1439,
                    },
                ];
            });

            setValue3('weekdayHours', weekdayHoursNew);
        }
    }, [weekdays]);

    const multiTimeZone = !!(data?.subscription as UserSubscription)?.user
        ?.calendar?.multi_timezone;
    const customWorkweek = !!(data?.subscription as UserSubscription)?.user
        ?.calendar?.workweek.customizable;

    return isLoading ? null : (
        <>
            <PageTitle sx={{ justifyContent: 'center' }}>
                Vendibility Settings
            </PageTitle>
            <Stack gap={5} alignItems="center" sx={{ mb: 1 }}>
                <Stack sx={{ width: '752px' }} gap={2}>
                    <Typography
                        sx={{
                            color: '#CACEDC',
                            fontSize: '14px',
                            fontWeight: '700',
                        }}
                    >
                        Time zone
                    </Typography>
                    <Form0>
                        <Card
                            sx={{ p: 3 }}
                            buttons={
                                <IconButtonGroup>
                                    {!form0Editing ? (
                                        <IconButton
                                            onClick={() =>
                                                setForm0Editing(true)
                                            }
                                            disabled={!multiTimeZone}
                                            tooltip="Edit"
                                            icon={<EditIcon fontSize="small" />}
                                        />
                                    ) : (
                                        <>
                                            <IconButton
                                                color="success.main"
                                                hoverColor="success.dark"
                                                onClick={() => submit0()}
                                                disabled={isSaving}
                                                tooltip="Save"
                                                icon={
                                                    <Check fontSize="small" />
                                                }
                                            />
                                            <Divider sx={{ mx: 0.75 }} />
                                            <IconButton
                                                color="error.main"
                                                hoverColor="error.dark"
                                                onClick={() =>
                                                    setForm0Editing(false)
                                                }
                                                disabled={isSaving}
                                                tooltip="Cancel"
                                                icon={
                                                    <Close fontSize="small" />
                                                }
                                            />
                                        </>
                                    )}
                                </IconButtonGroup>
                            }
                        >
                            {isSaving && (isSubmitting0 || isSubmitted0) && (
                                <LoadingOverlay
                                    title="Please wait..."
                                    subTitle="It’ll just take a moment."
                                />
                            )}{' '}
                            <Stack direction="row" alignItems="center">
                                <SelectTimezone
                                    name="timeZone"
                                    label=""
                                    disabled={!form0Editing}
                                    placeholder="Select time zone"
                                />
                            </Stack>
                        </Card>
                    </Form0>
                </Stack>
                <Stack sx={{ width: '752px' }} gap={2}>
                    <Typography
                        sx={{
                            color: '#CACEDC',
                            fontSize: '14px',
                            fontWeight: '700',
                        }}
                    >
                        Workweek
                    </Typography>
                    <Form3>
                        <Card
                            sx={{ p: 3 }}
                            buttons={
                                <IconButtonGroup>
                                    {!form3Editing ? (
                                        <IconButton
                                            onClick={() =>
                                                setForm3Editing(true)
                                            }
                                            tooltip="Edit"
                                            disabled={!customWorkweek}
                                            icon={<EditIcon fontSize="small" />}
                                        />
                                    ) : (
                                        <>
                                            <IconButton
                                                color="success.main"
                                                hoverColor="success.dark"
                                                onClick={() => submit3()}
                                                disabled={isSaving}
                                                tooltip="Save"
                                                icon={
                                                    <Check fontSize="small" />
                                                }
                                            />
                                            <Divider sx={{ mx: 0.75 }} />
                                            <IconButton
                                                color="error.main"
                                                hoverColor="error.dark"
                                                onClick={() =>
                                                    setForm3Editing(false)
                                                }
                                                disabled={isSaving}
                                                tooltip="Cancel"
                                                icon={
                                                    <Close fontSize="small" />
                                                }
                                            />
                                        </>
                                    )}
                                </IconButtonGroup>
                            }
                        >
                            <Box
                                sx={{
                                    opacity: form3Editing ? 1 : 0.6,
                                    pointerEvents: form3Editing
                                        ? 'all'
                                        : 'none',
                                }}
                            >
                                <WeekdayPicker
                                    name="weekdays"
                                    chipSx={{
                                        py: '12px',
                                        px: '20px',
                                        borderRadius: '26px',
                                        background: '#FFF',
                                        border: '1px solid #DFE1ED',
                                        '&.MuiChip-colorPrimary': {
                                            border: '1px solid #55B5CF',
                                            background: '#DDF0F5',
                                            color: '#2B395B',
                                        },
                                    }}
                                    sx={{
                                        background: 'rgba(59, 77, 125, 0.04)',
                                        borderRadius: '32px',
                                    }}
                                />
                                <Divider sx={{ mx: 10, my: 3 }} />
                                {weekdayHours &&
                                    Object.keys(weekdayHours).map(w => (
                                        <WeekdayRow
                                            key={w}
                                            name={`weekdayHours.${w}`}
                                            weekday={w as Weekdays}
                                        />
                                    ))}
                            </Box>
                            {isSaving && (isSubmitting3 || isSubmitted3) && (
                                <LoadingOverlay
                                    title="Please wait..."
                                    subTitle="It’ll just take a moment."
                                />
                            )}
                        </Card>
                    </Form3>
                </Stack>
            </Stack>
        </>
    );
}

export default Settings;

/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { GetUserResProfileContactDetailsDto } from './GetUserResProfileContactDetailsDto';
import type { GetUserResProfileOrganizationDto } from './GetUserResProfileOrganizationDto';
import type { Language } from './Language';
import type { PersonNameData } from './PersonNameData';

export type GetEventResBookingsItemSource1ReferenceDto = {
    /**
     * Schedule option which corresponds to the booked date
     */
    type: GetEventResBookingsItemSource1ReferenceDto.type;
    /**
     * Type of the capacity of the product
     */
    id: string;
    /**
     * Profile's (person, company) contact details.
     */
    contact_details?: GetUserResProfileContactDetailsDto;
    /**
     * Person's name, separated into components.
     */
    name: PersonNameData;
    /**
     * List of languages the traveler speaks/understands.
     */
    languages?: Array<Language>;
    /**
     * Person's organization.
     */
    organization?: GetUserResProfileOrganizationDto;
};

export namespace GetEventResBookingsItemSource1ReferenceDto {

    /**
     * Schedule option which corresponds to the booked date
     */
    export enum type {
        PERSON = 'person',
    }


}

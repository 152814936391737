import { ProductType } from '@travelity/api';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import DriveEtaIcon from '@mui/icons-material/DriveEta';
import PersonIcon from '@mui/icons-material/Person';
import React from 'react';

export interface ProductTypeIconProps {
    type: ProductType;
    shared?: boolean;
}

export const ProductTypeIcon: React.FC<ProductTypeIconProps> = props => {
    const { type, shared } = props;

    if (type === ProductType.TOUR && !shared) {
        return <PersonIcon sx={{ fontSize: '12px' }} />;
    }
    if (type === ProductType.TOUR) {
        return <PeopleAltIcon sx={{ fontSize: '12px' }} />;
    }
    return <DriveEtaIcon sx={{ fontSize: '12px' }} />;
};

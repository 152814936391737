import React, { memo, useEffect, useMemo } from 'react';
import { Badge, Button, Stack, Typography } from '@mui/material';
import { useForm } from '@travelity/form';
import { useWatch } from 'react-hook-form';
import { useProducts } from '@travelity/api';
import { FilterItem } from './components/filter-item';
import { FilterOption, FilterTypes, FilterValue } from './filters.types';
import { FilterButton } from './components/filter-button';
import { useSelectOptions } from '../../hooks';
import { SearchBar } from './components/search-bar';

export interface FiltersProps {
    values: Record<string, FilterValue> | {};
    setValues: (v: Record<string, FilterValue>) => void;
    options: FilterOption[];
}

export const Filters: React.FC<FiltersProps> = memo((props: FiltersProps) => {
    const { values: appliedValues, setValues: applyValues, options } = props;
    const { Form, reset, control, getValues } = useForm({
        defaultValues: appliedValues,
        mode: 'onChange',
    });

    // Fill products
    const optionsWithoutSearch = options.filter(
        option => option.type !== FilterTypes.SEARCH
    );
    const hasSearch = options.find(
        option => option.type === FilterTypes.SEARCH
    );
    const { data: products } = useProducts(undefined, {
        enabled: !optionsWithoutSearch.find(o => o.name === 'products')?.options
            ?.length,
    });
    const productFilterOptions = useSelectOptions(products, 'name', 'id', true);
    const filledOptions = useMemo(() => {
        return optionsWithoutSearch.map(filter =>
            filter.name === 'products'
                ? {
                      ...filter,
                      options: productFilterOptions,
                  }
                : filter
        );
    }, [optionsWithoutSearch, productFilterOptions]);

    useWatch({ control });

    const values = getValues();
    const changed = useMemo(() => {
        const appliedString = JSON.stringify(appliedValues);
        const valuesString = JSON.stringify(values);

        return appliedString !== valuesString;
    }, [appliedValues, values]);

    useEffect(() => {
        if (appliedValues) reset(appliedValues);
    }, [appliedValues, reset]);

    const valuesArray = Object.keys(values)
        .map(name => ({
            name,
            value: values[name],
        }))
        .filter(
            ({ value, name }) => value && options.find(o => name === o.name)
        );

    const valuesFilterArray = valuesArray.filter(
        ({ name }) =>
            options.find(o => name === o.name)?.type !== FilterTypes.SEARCH
    );

    useEffect(() => {
        if (changed && !valuesArray.length) applyValues({});
    }, [changed, valuesArray, applyValues]);

    return (
        <Stack sx={{ mb: 0.5 }}>
            <Stack
                direction="row"
                justifyContent={
                    !valuesArray.length && !hasSearch ? 'left' : 'space-between'
                }
                gap={1}
                alignItems="center"
                sx={{
                    py: 1,
                    height: '56px',
                }}
            >
                {hasSearch ? (
                    <Stack
                        sx={{
                            flexGrow: 2,
                            borderRight: '#DFE2EC 1px solid',
                            pr: 1,
                        }}
                    >
                        <SearchBar
                            control={control}
                            filter={hasSearch}
                            onEnter={() => applyValues(values)}
                        />
                    </Stack>
                ) : (
                    <>
                        {!valuesFilterArray.length && (
                            <Typography
                                sx={{
                                    color: '#B2B9CD',
                                    fontSize: '20px',
                                    fontWeight: '700',
                                }}
                            >
                                Configure Filtering
                            </Typography>
                        )}
                        {!!valuesFilterArray.length && (
                            <Stack
                                sx={{
                                    flexGrow: 2,
                                    borderRight: '#DFE2EC 1px solid',
                                }}
                            >
                                <Form>
                                    <Stack direction="row" gap={1}>
                                        {valuesFilterArray.map(filter => (
                                            <FilterItem
                                                key={filter.name}
                                                value={filter.value}
                                                filter={
                                                    filledOptions.find(
                                                        ({ name }) =>
                                                            name === filter.name
                                                    ) as FilterOption
                                                }
                                            />
                                        ))}
                                    </Stack>
                                </Form>
                            </Stack>
                        )}
                    </>
                )}
                {(!!valuesFilterArray.length || hasSearch) &&
                    (changed ? (
                        <Badge
                            color="warning"
                            variant="dot"
                            badgeContent=" "
                            sx={{
                                '.MuiBadge-badge': { top: '4px', right: '4px' },
                            }}
                        >
                            <Button
                                variant="contained"
                                color="secondary"
                                onClick={() => applyValues(values)}
                            >
                                {hasSearch ? 'Search' : 'Filter'}
                            </Button>
                        </Badge>
                    ) : (
                        <Button
                            variant="contained"
                            color="secondary"
                            onClick={() => applyValues(values)}
                        >
                            {hasSearch ? 'Search' : 'Filter'}
                        </Button>
                    ))}

                <FilterButton
                    selectedFilters={values}
                    updateSelectedFilters={reset}
                    options={filledOptions}
                />
            </Stack>
            {hasSearch && !!valuesFilterArray.length && (
                <Stack
                    sx={{
                        py: 1,
                        height: '56px',
                    }}
                >
                    <Form>
                        <Stack direction="row" gap={1}>
                            {valuesFilterArray.map(filter => (
                                <FilterItem
                                    key={filter.name}
                                    value={filter.value}
                                    filter={
                                        filledOptions.find(
                                            ({ name }) => name === filter.name
                                        ) as FilterOption
                                    }
                                />
                            ))}
                        </Stack>
                    </Form>
                </Stack>
            )}
        </Stack>
    );
});

import { OrderStatuses } from '@travelity/api';
import React from 'react';
import { StatusTag, StatusTagStatuses } from '@travelity/ui';
import { useTranslation } from 'react-i18next';

export interface OrderStatusProps {
    status: OrderStatuses;
}

const statusMap: Record<OrderStatuses, StatusTagStatuses> = {
    [OrderStatuses.OPEN]: StatusTagStatuses.ERROR,
    [OrderStatuses.CONFIRMED]: StatusTagStatuses.SUCCESS,
    [OrderStatuses.CANCELLED]: StatusTagStatuses.NEUTRAL,
};

export const OrderStatus: React.FC<OrderStatusProps> = props => {
    const { t } = useTranslation('order');
    const { status } = props;
    return (
        <StatusTag status={statusMap[status]} value={t(status).toUpperCase()} />
    );
};

import React, { useState, useRef } from 'react';
import { Link } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import {
    ClickAwayListener,
    Divider,
    List,
    ListItem,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    Paper,
    Popper,
    Tooltip,
    Typography,
} from '@mui/material';

import LockIcon from '@mui/icons-material/Lock';
import Button from '@mui/material/Button';
import Transitions from '../transitions/transitions';
import { useNavigateAbsolute } from '../../hooks';

export interface MultiButtonItem {
    nativeIcon?: boolean;
    label: string;
    path?: string;
    onClick?: () => void;
    Icon: React.FC;
    disabled?: boolean;
}

export interface MultiButtonProps {
    listItems: MultiButtonItem[];
}

const MultiButton: React.FC<MultiButtonProps> = ({ listItems }) => {
    const theme = useTheme();
    const [open, setOpen] = useState<number>(0);
    useNavigateAbsolute();
    /**
     * anchorRef is used on different components and specifying one type leads to other components throwing an error
     * */
    const anchorRef = useRef<HTMLButtonElement | null>(null);

    const handleClose = (
        event: React.MouseEvent<HTMLDivElement> | MouseEvent | TouchEvent
    ) => {
        if (
            anchorRef.current &&
            anchorRef.current.contains(event.target as Node)
        ) {
            return;
        }
        setOpen(0);
    };

    const handleToggle = (e: React.MouseEvent) => {
        setOpen(prevOpen =>
            prevOpen ? 0 : e.currentTarget?.clientWidth || 198
        );
    };

    return (
        <>
            <Button
                fullWidth
                variant="contained"
                color="secondary"
                sx={{
                    lineHeight: 1,
                    py: '12px',
                    borderRadius: '19px',
                    ...(open && {
                        borderBottomRightRadius: '0',
                        borderBottomLeftRadius: '0',
                    }),
                }}
                ref={anchorRef}
                aria-controls={open ? 'nav-item-grow' : undefined}
                aria-haspopup="true"
                onClick={handleToggle}
            >
                Create
            </Button>

            <Popper
                placement="bottom-end"
                open={!!open}
                anchorEl={anchorRef.current}
                role={undefined}
                transition
                disablePortal
                sx={{ zIndex: 100 }}
                popperOptions={{
                    modifiers: [
                        {
                            name: 'offset',
                            options: {
                                offset: [-1, 0],
                            },
                        },
                    ],
                }}
            >
                {({ TransitionProps }) => (
                    <Transitions in={open} {...TransitionProps}>
                        <Paper
                            sx={{
                                borderRadius: '24px',
                                borderTopLeftRadius: 0,
                                borderTopRightRadius: 0,
                                border: '1px solid #55B5CF',
                                borderTop: 0,
                                background: '#FFF',
                                width: anchorRef.current?.clientWidth,
                            }}
                        >
                            <ClickAwayListener onClickAway={handleClose}>
                                <List
                                    component="nav"
                                    sx={{
                                        width: '100%',
                                        borderRadius: '24px',
                                        [theme.breakpoints.down('md')]: {
                                            minWidth: '100%',
                                        },
                                        py: 0.5,
                                        '& .MuiListItemButton-root': {
                                            mt: 0.5,
                                        },
                                    }}
                                >
                                    {listItems.map(
                                        (
                                            {
                                                label,
                                                Icon,
                                                path,
                                                onClick,
                                                nativeIcon,
                                                disabled,
                                            },
                                            i
                                        ) => (
                                            <React.Fragment key={label}>
                                                {i !== 0 && (
                                                    <Divider
                                                        sx={{ mx: '14px' }}
                                                    />
                                                )}
                                                <ListItem
                                                    sx={{ p: 0 }}
                                                    secondaryAction={
                                                        disabled ? (
                                                            <Tooltip title="Upgrade subscription to use this feature">
                                                                <LockIcon
                                                                    sx={{
                                                                        mt: 0.5,
                                                                        opacity: 0.38,
                                                                    }}
                                                                    fontSize="small"
                                                                />
                                                            </Tooltip>
                                                        ) : undefined
                                                    }
                                                >
                                                    <ListItemButton
                                                        sx={{
                                                            mx: 0.5,
                                                            my: 0.5,
                                                            alignItems:
                                                                'flex-start',
                                                            py: 1,
                                                            pl: 1,
                                                            pr: 0,
                                                            borderRadius: '4px',
                                                            ...(nativeIcon && {
                                                                '& svg': {
                                                                    width: '18px',
                                                                    height: '18px',
                                                                    marginLeft:
                                                                        '3px',
                                                                    marginTop:
                                                                        '3px',
                                                                },
                                                            }),
                                                        }}
                                                        component={
                                                            path
                                                                ? Link
                                                                : undefined
                                                        }
                                                        to={path}
                                                        disabled={disabled}
                                                        onClick={(e: any) => {
                                                            if (!path)
                                                                e.preventDefault();
                                                            setOpen(0);
                                                            if (onClick)
                                                                onClick();
                                                        }}
                                                    >
                                                        <ListItemIcon
                                                            sx={{
                                                                my: 0,
                                                                minWidth:
                                                                    '32px',
                                                                color: '#7682A4',
                                                                opacity: 0.5,
                                                            }}
                                                        >
                                                            <Icon />
                                                        </ListItemIcon>
                                                        <ListItemText
                                                            sx={{
                                                                my: 0,
                                                                '& .MuiTypography-root':
                                                                    {
                                                                        color: '#7682A4',
                                                                        fontSize:
                                                                            '14px',
                                                                        lineHeight:
                                                                            '14px',
                                                                        mt: '5px',
                                                                    },
                                                            }}
                                                            primary={
                                                                <Typography variant="body2">
                                                                    {label}
                                                                </Typography>
                                                            }
                                                        />
                                                    </ListItemButton>
                                                </ListItem>
                                            </React.Fragment>
                                        )
                                    )}
                                </List>
                            </ClickAwayListener>
                        </Paper>
                    </Transitions>
                )}
            </Popper>
        </>
    );
};

export default MultiButton;

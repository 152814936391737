/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { EnterpriseSubscriptionData } from './EnterpriseSubscriptionData';
import type { FreeSubscriptionData } from './FreeSubscriptionData';
import type { ProfessionalSubscriptionData } from './ProfessionalSubscriptionData';
import type { ResourceType } from './ResourceType';
import type { TeamSubscriptionData } from './TeamSubscriptionData';
import type { UpdateUserResAccessDto } from './UpdateUserResAccessDto';
import type { UpdateUserResAccountDto } from './UpdateUserResAccountDto';
import type { UpdateUserResCreatedDto } from './UpdateUserResCreatedDto';
import type { UpdateUserResProfileDto } from './UpdateUserResProfileDto';

export type UpdateUserResDto = {
    /**
     * Type of the resource
     */
    resource_type?: ResourceType;
    /**
     * Identifier of the document in the database.
     */
    id: string;
    /**
     * Details about who has created the database record and when.
     */
    created: UpdateUserResCreatedDto;
    /**
     * Details about who and when made the most recent update to the record.
     */
    last_updated?: UpdateUserResCreatedDto;
    /**
     * Details about who and when deleted/archived the record.
     */
    deleted?: UpdateUserResCreatedDto;
    /**
     * Contains info about the cancellation of the resource
     */
    cancelled?: UpdateUserResCreatedDto;
    /**
     * Details about all activities happening to the record
     */
    lifecycle: Array<UpdateUserResCreatedDto>;
    /**
     * Type of the user
     */
    type?: UpdateUserResDto.type;
    /**
     * Account details of the user
     */
    account?: UpdateUserResAccountDto;
    /**
     * Access to the shared product
     */
    access?: UpdateUserResAccessDto;
    /**
     * Profile details of the user
     */
    profile?: UpdateUserResProfileDto;
    /**
     * Memberships of the user
     */
    subscription?: (FreeSubscriptionData | ProfessionalSubscriptionData | TeamSubscriptionData | EnterpriseSubscriptionData);
};

export namespace UpdateUserResDto {

    /**
     * Type of the user
     */
    export enum type {
        HUMAN = 'human',
    }


}

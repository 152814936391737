import React, { useCallback, useMemo, useState } from 'react';
import { CardList } from '@travelity/form';
import { Box, Chip, Stack } from '@mui/material';
import {
    AgeBands,
    AvailableProductOption,
    PricingType,
    AgebandName,
    PaxData,
} from '@travelity/api';
import { useTranslation } from 'react-i18next';
import { Tag } from '@travelity/ui';
import { FlatPricingData } from '@travelity/api/src/requests';
import { useSelectOptions } from '../../hooks';
import { FilterPaxValue } from '../filters/filters.types';
import { OptionModal } from '../option-modal';

export interface ProductOptionCardsProps {
    productOptions: AvailableProductOption[];
    selectedOptions?: { id?: string; name: string; pax: PaxData }[];
    pax: FilterPaxValue;
}

function numberWithSpaces(x: number) {
    const parts = x.toString().split('.');
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
    return parts.join('.');
}

const ProductOptionCards: React.FC<ProductOptionCardsProps> = props => {
    const { t } = useTranslation('product');
    const {
        productOptions,
        pax,
        selectedOptions: selectedOptionsArray,
    } = props;

    const options = useSelectOptions(productOptions, 'name', 'name', true);

    const selectedOptions =
        selectedOptionsArray && selectedOptionsArray.length
            ? selectedOptionsArray
            : undefined;

    // Handle option selection
    const [optionModal, setOptionModal] = useState<{
        item?: Partial<AvailableProductOption>;
        callback: (item: AvailableProductOption) => void;
    } | null>(null);

    const onOptionAdd = useCallback(
        (callback: (item: AvailableProductOption) => void) => {
            setOptionModal({ item: { pax }, callback });
        },
        [pax]
    );

    const onOptionEdit = useCallback(
        (
            item: Record<string, any>,
            callback: (item: Record<string, any>) => void
        ) => {
            setOptionModal({ item: item as AvailableProductOption, callback });
        },
        []
    );

    const handleOptionSave = useCallback(
        (newItem: AvailableProductOption) => {
            optionModal?.callback(newItem);
            setOptionModal(null);
        },
        [optionModal]
    );

    const availableOptions = useMemo(() => {
        return options.filter(
            option =>
                optionModal?.item?.name === option.name ||
                !selectedOptions?.find(({ name }) => name === option.name)
        );
    }, [options, selectedOptions, optionModal]);

    const getOptionPrice = (n: string) => {
        const option = productOptions?.find(({ name }) => name === n);
        if (option) {
            const selectedPax = selectedOptionsArray?.find(
                o => o.name === n
            )?.pax;
            const p = selectedPax || pax;
            const count =
                Object.values(p)
                    .filter(v => !!v)
                    .reduce((sum, ageBand) => sum + ageBand, 0) || 0;
            const prices: { count: number; price: number; label: string }[] =
                [];
            // @ts-ignore
            if (option.pricing?.price.type === PricingType.PER_PERSON) {
                prices.push({
                    count,
                    price:
                        (option.pricing.price as FlatPricingData).unit_amount ||
                        0,
                    label: '',
                });
                // @ts-ignore
            } else if (option.pricing?.price.type === PricingType.PER_PRODUCT) {
                prices.push({
                    count: 1,
                    price:
                        (option.pricing.price as FlatPricingData).unit_amount ||
                        0,
                    label: '',
                });
            } else {
                Object.values(AgebandName).forEach((k: AgebandName) => {
                    // @ts-ignore
                    const pr = option.pricing?.price[k];
                    if (pr && p?.[k]) {
                        prices.push({
                            count: p[k] as number,
                            price: pr,
                            label: k,
                        });
                    }
                });
            }

            if (option.pricing?.price.base_amount) {
                prices.push({
                    count: 1,
                    price: option.pricing.price.base_amount,
                    label: '',
                });
            }

            const totalPrice = prices.reduce(
                (s, cur) => s + cur.count * cur.price,
                0
            );
            return totalPrice
                ? `${numberWithSpaces(totalPrice)} ${option?.pricing?.currency}`
                : 'Free';
        }
        return 'Free';
    };

    const hasOptionPrice = (n: string) => {
        return options.find(o => o.name === n && o.hasPrice);
    };

    return (
        <>
            <CardList
                renderHeader={({ item }) => (
                    <Box component="span">
                        {item.name} ({item.type})
                    </Box>
                )}
                renderContent={({ item }) => (
                    <Stack direction="row">
                        <Stack
                            direction="row"
                            gap={1}
                            px={1}
                            py={1}
                            flexWrap="wrap"
                            flexGrow={2}
                        >
                            {Object.values(AgeBands).map(
                                name =>
                                    !!item.pax[name] && (
                                        <Tag
                                            label={t(name)}
                                            values={[item.pax[name]]}
                                        />
                                    )
                            )}
                        </Stack>
                        <Stack pr={1}>
                            {!hasOptionPrice(item.name) && (
                                <Chip
                                    size="small"
                                    label="Free"
                                    sx={{
                                        mt: 1,
                                        color: 'success.main',
                                        border: '1px solid',
                                        borderColor: 'success.main',
                                        fontWeight: 400,
                                        fontSize: '12px',
                                        '& > .MuiChip-label': {
                                            px: '6px',
                                            py: '4px',
                                        },
                                        bgcolor: 'rgba(44, 172, 96, 0.20)',
                                    }}
                                />
                            )}
                            {hasOptionPrice(item.name) && (
                                <Chip
                                    size="small"
                                    label={getOptionPrice(item.name)}
                                    sx={{
                                        mt: 1,
                                        color: 'secondary.main',
                                        border: '1px solid',
                                        borderColor: 'secondary.main',
                                        fontWeight: 400,
                                        fontSize: '12px',
                                        '& > .MuiChip-label': {
                                            px: '6px',
                                            py: '4px',
                                        },
                                        bgcolor: 'rgba(85, 181, 207, 0.2)',
                                    }}
                                />
                            )}
                        </Stack>
                    </Stack>
                )}
                disableAdding={options.length <= (selectedOptions?.length || 0)}
                customAdd={onOptionAdd}
                customEdit={onOptionEdit}
                noEmptyState
                name="options"
                maxHeight={178}
                addButtonText="Add Product Option"
            />

            <OptionModal
                handleCancel={() => setOptionModal(null)}
                handleConfirm={handleOptionSave}
                open={!!optionModal}
                options={availableOptions}
                item={optionModal?.item}
                productPax={pax}
            />
        </>
    );
};

export default React.memo(ProductOptionCards);

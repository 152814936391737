import React from 'react';
import { Box, Button, Paper, Stack, Typography } from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import StarIcon from '@mui/icons-material/Star';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';

export interface PriceProps {
    title: string;
    description: string;
    price?: string;
    free?: boolean;
    demoButton?: boolean;
    highlighted?: boolean;
    features: string[];
    everythingIn?: string;
    onClick?: () => void;
}

export const Price: React.FC<PriceProps> = props => {
    const {
        title,
        description,
        features,
        price,
        demoButton,
        free,
        everythingIn,
        highlighted,
        onClick,
    } = props;

    return (
        <Paper
            sx={{
                borderWidth: '2px',
                borderStyle: 'solid',
                bgcolor: '#FFF',
                mt: highlighted ? 0 : '34px',
                boxShadow: highlighted
                    ? 'none'
                    : '0px 1px 12px 0px rgba(178, 185, 205, 0.30)',
                borderColor: highlighted ? 'primary.main' : 'transparent',
            }}
        >
            {highlighted && (
                <Stack
                    sx={{
                        height: '34px',
                        color: '#FFF',
                        bgcolor: 'primary.main',
                        borderTopRightRadius: '10px',
                        borderTopLeftRadius: '10px',
                        fontSize: '14px',
                        lineHeight: '14px',
                        fontWeight: 700,
                    }}
                    direction="row"
                    alignItems="center"
                    justifyContent="center"
                    gap={1}
                >
                    <StarIcon fontSize="small" /> Most Popular
                </Stack>
            )}

            <Stack
                sx={{
                    p: { md: 3, xs: 2 },
                }}
            >
                <Typography
                    variant="h4"
                    sx={{
                        fontSize: '24px',
                        fontWeight: 800,
                        color: '#3B4D7D',
                    }}
                >
                    {title}
                </Typography>
                <Typography
                    variant="h5"
                    sx={{
                        mt: 2,
                        fontSize: '14px',
                        fontWeight: 400,
                        color: '#3B4D7D',
                        opacity: 0.7,
                    }}
                >
                    {description}
                </Typography>
                {price ? (
                    <Stack
                        direction="row"
                        sx={{
                            mt: 4,
                            fontSize: '24px',
                            fontWeight: 800,
                            color: '#3B4D7D',
                        }}
                        alignItems="flex-end"
                        gap={1}
                    >
                        <Box
                            component="span"
                            alignSelf="flex-start"
                            sx={{
                                opacity: 0.5,
                                fontSize: '20px',
                                fontWeight: 400,
                                pb: '20px',
                            }}
                        >
                            $
                        </Box>
                        <Box
                            component="span"
                            sx={{
                                fontSize: '46px',
                                lineHeight: '54px',
                                fontWeight: 700,
                            }}
                        >
                            {price}
                        </Box>
                        <Box
                            component="span"
                            sx={{
                                fontSize: '20px',
                                opacity: 0.5,
                                fontWeight: 400,
                            }}
                        >
                            USD / per month
                        </Box>
                    </Stack>
                ) : (
                    <Stack
                        sx={{
                            mt: 4,
                            fontSize: '18px',
                            fontWeight: 800,
                            color: '#3B4D7D',
                        }}
                    >
                        Get a custom price from our sales representative
                    </Stack>
                )}
                <Typography
                    variant="subtitle1"
                    sx={{
                        color: 'rgba(255, 255, 255, 0.5)',
                        fontWeight: 500,
                        opacity: free ? 1 : 0,
                    }}
                >
                    {free
                        ? 'No credit card required!'
                        : 'Credit card required!'}
                </Typography>
                <Button
                    sx={{ mt: 2, mb: 4 }}
                    variant={highlighted ? 'contained' : 'outlined'}
                    aria-label={demoButton ? 'Contact Sales' : 'Get started'}
                    size="large"
                    color="primary"
                    onClick={onClick}
                    disabled={!onClick}
                >
                    {!demoButton ? `Go ${title}` : 'Contact Sales'}
                </Button>
                <Stack
                    direction="row"
                    gap={1.5}
                    sx={{ fontSize: '14px', fontWeight: 700, color: '#36869C' }}
                >
                    {everythingIn && (
                        <>
                            <KeyboardBackspaceIcon fontSize="small" />
                            All inclusions from {everythingIn} subscription
                        </>
                    )}
                </Stack>
                <Stack gap={2} sx={{ mt: 2 }}>
                    {features.map(feature => (
                        <Stack
                            key={feature}
                            direction="row"
                            gap={1.5}
                            alignItems="start"
                            sx={{
                                fontWeight: 400,
                                fontSize: '14px',
                                color: '#3B4D7D',
                            }}
                        >
                            <CheckIcon
                                sx={{ mt: '3px', color: '#36869C' }}
                                fontSize="small"
                            />
                            {feature}
                        </Stack>
                    ))}
                </Stack>
            </Stack>
        </Paper>
    );
};

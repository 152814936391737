import React, { useCallback, useEffect } from 'react';
import { Chip, Stack } from '@mui/material';
import {
    EventItem,
    useCapacity,
    useUpdateEventOperations,
    EventStatuses,
} from '@travelity/api';
import { useForm } from '@travelity/form';
import _ from 'lodash';
import travelersSchema from '../../../components/booking-travelers/booking-travelers.schema';
import { BookingTravelersForm } from '../../../components/booking-travelers';
import { BookingProductForm } from '../../../components/booking-product';
import { BookingScheduleForm } from '../../../components/booking-schedule';
import { EventStatus } from '../../../components/event-status';
import { EventEnd } from '../../../components/event-end/event-end';
import { ActivityHistory } from '../../../components/activity-history';
import { EventOperations } from '../../../components/event-operations';
import { SideFormAccordion } from '../../../components/side-form-accordion';
import { useSideFormAccordion } from '../../../components/side-form-accordion/side-form-accordion.hooks';
import { SidebarContent } from '../../../components/sidebar-content';

export interface EventPreviewProps {
    event: EventItem;
}

export const eventBookingsToEventTravelers = (event: EventItem) => {
    return (
        event.bookings.map(booking => ({
            bookingId: booking.id,
            travelers: booking.participants.travelers,
            customer: booking.participants.customer,
        })) || []
    );
};

const EventPreview: React.FC<EventPreviewProps> = ({
    event,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    // updateBooking,
}) => {
    const { view, openTab, isEdit } = useSideFormAccordion();
    const readonly =
        event.status === EventStatuses.CANCELLED || !event.permissions.canEdit;

    const {
        Form: TravelersForm,
        reset: resetTravelersForm,
        // formState: { errors: travelersErrors },
    } = useForm({
        defaultValues: {
            travelers: eventBookingsToEventTravelers(event),
        },
        // onSubmit,
        mode: 'onChange',
        schema: travelersSchema,
    });

    const { mutate: updateOperations, isLoading: isOperationsSaving } =
        useUpdateEventOperations(
            event.assets.filter(i => !i.pinned),
            event.staff.filter(i => !i.pinned),
            event.product.type,
            {
                onSuccess: () => {
                    view();
                },
            }
        );
    const {
        Form: OperationsForm,
        reset: resetOperationsForm,
        submit: operationsSubmit,
        // formState: { errors: travelersErrors },
    } = useForm({
        // defaultValues: travelersToTravelersForm(
        //     event.bookingsSummary?.travelers_summary?.travelers || []
        // ),
        defaultValues: {
            capacity: [event.capacity],
            staff: event.staff
                .filter(staff => staff.reference)
                .map(v => ({
                    ...v.reference,
                    uuid: v.id,
                    position: v.position,
                })),
            assets: event.assets
                .filter(asset => asset.reference)
                .map(v => ({ ...v.reference, uuid: v.id })),
        },
        onSubmit: useCallback(
            (data: Record<string, any>) => {
                updateOperations({
                    staff: data.staff.map(
                        ({
                            uuid,
                            position,
                            ...reference
                        }: {
                            uuid?: string;
                        }) => ({
                            id: uuid,
                            position,
                            reference,
                        })
                    ),
                    assets: data.assets.map(
                        ({ uuid, ...reference }: { uuid?: string }) => ({
                            id: uuid,
                            reference,
                        })
                    ),
                    eventId: event.id,
                });
            },
            [event, updateOperations]
        ),
        mode: 'onChange',
        // schema: travelersSchema,
    });

    const onTravelersEditCancel = useCallback(() => {
        resetTravelersForm({
            travelers: eventBookingsToEventTravelers(event),
        });
        view();
    }, [event, view]);

    const onOperationsEditCancel = useCallback(() => {
        resetOperationsForm({
            capacity: [event.capacity],
            staff: event.staff
                .filter(staff => staff.reference)
                .map(v => ({
                    ...v.reference,
                    uuid: v.id,
                    position: v.position,
                })),
            assets: event.assets
                .filter(asset => asset.reference)
                .map(v => ({ ...v.reference, uuid: v.id })),
        });
        view();
    }, [event, view]);

    useEffect(() => {
        resetOperationsForm({
            capacity: [event.capacity],
            staff: event.staff
                .filter(staff => staff.reference)
                .map(v => ({
                    ...v.reference,
                    uuid: v.id,
                    position: v.position,
                })),
            assets: event.assets
                .filter(asset => asset.reference)
                .map(v => ({ ...v.reference, uuid: v.id })),
        });
        resetTravelersForm({
            travelers: eventBookingsToEventTravelers(event),
        });
    }, [event]);

    const { data: capacity } = useCapacity(event.capacity.id);

    return (
        <SidebarContent title="Event Preview">
            <Stack
                direction="row"
                alignItems="center"
                gap={0.75}
                sx={{
                    px: 2,
                    height: '46px',
                    py: '10px',
                    borderRadius: '0px 12px 0px 0px',
                }}
            >
                <EventStatus status={event.status} />
                <EventEnd date={event.date} />
                {event.ephemeral && (
                    <Chip
                        label="Virtual"
                        sx={{
                            borderRadius: '8px',
                            background: '#EC8031',
                            padding: '6px 8px',
                            color: '#FFF',
                            fontSize: '14px',
                        }}
                    />
                )}
            </Stack>
            <SideFormAccordion id="schedule" title="Date and Time">
                <BookingScheduleForm date={event.date} preview />
            </SideFormAccordion>
            <SideFormAccordion id="product" title="Product">
                <BookingProductForm
                    product={event.product}
                    route={event.route}
                    preview
                />
            </SideFormAccordion>
            <SideFormAccordion
                id="travelers"
                isEditable={false}
                title="Travelers"
                onCancel={onTravelersEditCancel}
            >
                <TravelersForm id="travelers">
                    <BookingTravelersForm />
                </TravelersForm>
            </SideFormAccordion>
            <SideFormAccordion
                id="operations"
                title="Operations"
                isEditable={!readonly}
                isSaving={isOperationsSaving}
                onCancel={onOperationsEditCancel}
                onSave={operationsSubmit}
            >
                <OperationsForm id="operations">
                    <EventOperations
                        event={event}
                        capacity={capacity}
                        allowedAssetTypes={
                            event.assets.length ? ['vehicle'] : []
                        }
                        allowedStaffPositions={_.uniq(
                            event.staff.map(staff => staff.position)
                        )}
                        preview={!isEdit || openTab !== 'operations'}
                    />
                </OperationsForm>
            </SideFormAccordion>

            <SideFormAccordion id="activity-log" title="Activity Log">
                <ActivityHistory entity="event" lifecycle={event.lifecycle} />
            </SideFormAccordion>
        </SidebarContent>
    );
};

export default React.memo(EventPreview);

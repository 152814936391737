import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Box, Stack } from '@mui/material';
import { addDays, startOfDay } from 'date-fns';
import {
    useAvailability,
    useProducts,
    AvailabilityFilterValues,
    AvailabilityProduct,
    PaxData,
} from '@travelity/api';
import { useLocation, useNavigate } from 'react-router-dom';
import { isEmpty, pickBy } from 'lodash';
import { Filters } from '../../components/filters';
import {
    FilterOption,
    FilterTypes,
} from '../../components/filters/filters.types';
import { useDynamicState, useSelectOptions } from '../../hooks';
import { SideForm } from '../../components/side-form';
import BookingSideForm from './components/booking-side-form';
import { productTypeOptions } from '../select-type/select-type';
import { AvailabilityCalendar } from './components/availability-calendar';

const availabilityFilters: FilterOption[] = [
    {
        name: 'dates',
        label: 'Dates',
        required: true,
        preventPast: true,
        type: FilterTypes.DATES,
        selectText: 'Please, select the value for date filters',
    },
    {
        name: 'pax',
        label: 'PAX',
        required: true,
        type: FilterTypes.PAX,
        // selectText: 'Please, select the value for product type',
    },
    {
        name: 'productTypes',
        label: 'Product Types',
        type: FilterTypes.DROPDOWN,
        multiple: true,
        selectText: 'Please, select the value for product types',
        options: productTypeOptions,
    },
    {
        name: 'products',
        label: 'Products',
        multiple: true,
        type: FilterTypes.DROPDOWN,
        selectText: 'Please, select products',
        options: [],
    },
];

function Availability() {
    const { data: products } = useProducts();
    const productFilterOptions = useSelectOptions(products, 'name', 'id', true);

    const location = useLocation();
    const [filters, setFilters] = useDynamicState<AvailabilityFilterValues>(
        useMemo(() => {
            if (location.state) {
                const stateFilters = pickBy(
                    location.state as AvailabilityFilterValues,
                    v => v
                );
                if (!isEmpty(stateFilters)) return stateFilters;
            }
            return {
                pax: {
                    adults: 1,
                },
                dates: {
                    startDate: startOfDay(new Date()),
                    endDate: addDays(startOfDay(new Date()), 7),
                },
            };
        }, [location.state])
    );

    const navigate = useNavigate();
    const updateFilters = useCallback(
        (newFilters: AvailabilityFilterValues) => {
            navigate('/availability', {
                replace: true,
                state: newFilters,
            });
        },
        [setFilters]
    );

    const filledFilters = useMemo(() => {
        return availabilityFilters.map(filter =>
            filter.name === 'products'
                ? {
                      ...filter,
                      options: productFilterOptions,
                  }
                : filter
        );
    }, [productFilterOptions]);

    const {
        data: availability,
        isLoading,
        refetch,
    } = useAvailability(
        useMemo(
            () => ({
                requestBody: filters,
            }),
            [filters]
        )
    );

    const [selectedEvent, setSelectedEvent] = useState<
        | {
              product: AvailabilityProduct;
              time: number;
              day: number;
          }
        | undefined
    >();

    const closeEvent = useCallback(() => {
        setSelectedEvent(undefined);
    }, []);

    useEffect(closeEvent, [filters]);

    const onBookingCreate = useCallback(() => {
        closeEvent();
        refetch();
    }, []);

    const openEvent = useCallback(
        (product: AvailabilityProduct, day: number, time: number) => {
            setSelectedEvent({
                product,
                day,
                time,
            });
        },
        [filters]
    );

    const changeTime = useCallback((time: number) => {
        setSelectedEvent(prevState =>
            prevState
                ? {
                      ...prevState,
                      time,
                  }
                : undefined
        );
    }, []);

    return (
        <Stack
            direction="row"
            sx={{ height: 'calc(100vh - 60px)', overflow: 'hidden' }}
        >
            <Box
                sx={{
                    height: '100%',
                    overflow: 'hidden',
                    flexGrow: 2,
                    pr: 2,
                    pl: 4,
                }}
            >
                <Filters
                    values={filters}
                    setValues={updateFilters}
                    options={filledFilters}
                />
                <AvailabilityCalendar
                    events={availability?.events}
                    isLoading={isLoading}
                    openEvent={openEvent}
                    selectedDay={selectedEvent?.day}
                    selectedTime={selectedEvent?.time}
                    selectedProductId={selectedEvent?.product.id}
                />
            </Box>

            <SideForm
                title="Booking Creation"
                isOpen={!!selectedEvent}
                onClose={closeEvent}
            >
                {!!selectedEvent && (
                    <BookingSideForm
                        pax={filters.pax as PaxData}
                        products={availability?.products}
                        availableProduct={selectedEvent?.product}
                        time={selectedEvent?.time}
                        changeTime={changeTime}
                        discard={closeEvent}
                        onSuccess={onBookingCreate}
                    />
                )}
            </SideForm>
        </Stack>
    );
}

export default Availability;

import React, { useEffect, useMemo } from 'react';
import {
    Box,
    Chip,
    ChipProps,
    Divider,
    Menu,
    MenuItem,
    Theme,
    Tooltip,
} from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { useController, useFormContext } from 'react-hook-form';
import { SystemStyleObject } from '@mui/system';
import CloseIcon from '@mui/icons-material/Close';

export interface TagDropdownProps extends Partial<ChipProps> {
    optionsSx?: SystemStyleObject<Theme>;
    label: string;
    name: string;
    valueAsObject?: boolean;
    valueKey?: string;
    options: { value: string | number; label: string; [key: string]: any }[];
    enableRemove?: boolean;
    autoOpenEmpty?: boolean;
}

const TagDropdown: React.FC<TagDropdownProps> = ({
    optionsSx,
    label,
    options,
    name,
    valueAsObject,
    valueKey = 'value',
    enableRemove,
    autoOpenEmpty,
    ...rest
}) => {
    const { control } = useFormContext();
    const {
        field: { value, onChange },
    } = useController({
        name,
        control,
    });

    const selectedValue = useMemo(() => {
        const v = valueAsObject ? value?.[valueKey] : value;
        return options?.find(o => o[valueKey] === v)?.label;
    }, [valueAsObject, value, valueKey, options]);

    const buttonRef = React.useRef<HTMLDivElement>(null);
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    useEffect(() => {
        if (autoOpenEmpty && !open && buttonRef.current && !selectedValue) {
            setAnchorEl(buttonRef.current);
        }
    }, [autoOpenEmpty, selectedValue, open]);

    return (
        <Chip
            {...rest}
            sx={{
                '&': (rest.sx || {}) as SystemStyleObject<Theme>,
                '& > .MuiChip-deleteIconSmall': {
                    ml: 0.5,
                    mr: 0,
                    fontSize: '14px',
                    color: '#9198AA',
                    lineHeight: '14px',
                    '&: hover': {
                        color: '#9198AA',
                    },
                },
                '&&&:hover': {
                    boxShadow: '0px 1px 12px rgba(178, 185, 205, 0.6)',
                },
                ...(label
                    ? {
                          '& .MuiChip-label': {
                              whiteSpace: 'nowrap',
                              mt: '-4px',
                          },
                      }
                    : {
                          color: '#9198AA',
                          background: 'rgba(85, 181, 207, 0.2)',
                          p: '8px 10px',
                          fontSize: '14px',
                          boxShadow: 0,
                          '& .MuiChip-label span': {
                              lineHeight: '12px',
                          },
                      }),
            }}
            {...(enableRemove && {
                deleteIcon: (
                    <Tooltip title="Remove">
                        <CloseIcon />
                    </Tooltip>
                ),
                onDelete: () => onChange(undefined),
            })}
            variant="outlined"
            color="primary"
            size="small"
            label={
                <>
                    <Box sx={{ px: 0.5, fontWeight: 400 }} component="span">
                        {label}
                    </Box>
                    <Box
                        component="span"
                        onClick={handleClick}
                        ref={buttonRef}
                        sx={{
                            '&': optionsSx || {},
                            background: 'rgba(85, 181, 207, 0.2)',
                            color: '#9198AA',
                            lineHeight: '14px',
                            fontSize: '14px',
                            fontWeight: 400,
                            display: 'inline-flex',
                            textAlign: 'center',
                            borderRadius: '12px',
                            minWidth: '25px',
                            height: '22px',
                            cursor: 'pointer',
                            py: 0.5,
                            px: 1,
                            ml: 0.5,
                            mt: 0.5,
                            '& span': {
                                textOverflow: 'ellipsis',
                                overflow: 'hidden',
                            },
                        }}
                    >
                        <span title={selectedValue}>{selectedValue}</span>
                        <KeyboardArrowDownIcon
                            sx={{
                                fontSize: '14px',
                                ml: '3px',
                            }}
                        />
                    </Box>

                    <Menu
                        id="demo-positioned-menu"
                        aria-labelledby="demo-positioned-button"
                        anchorEl={anchorEl}
                        open={open}
                        onClose={handleClose}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'right',
                        }}
                        PaperProps={{ sx: { mt: '6px' } }}
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                        }}
                    >
                        {options.map((o, index) => (
                            <React.Fragment key={o[valueKey]}>
                                {!!index && (
                                    <Divider
                                        sx={{ mx: 2, '&&&': { my: 0.5 } }}
                                    />
                                )}

                                <MenuItem
                                    sx={{
                                        justifyContent: 'start',
                                        color: '#2B395B',
                                        fontSize: '12px',
                                        px: 1,
                                        margin: '0 8px',
                                        borderRadius: '4px',
                                        '&:before': {
                                            borderTop: index
                                                ? '1px solid #DFE1ED'
                                                : 0,
                                        },
                                        '&:hover': {
                                            backgroundColor: '#D7E7EB',
                                        },
                                    }}
                                    onClick={() => {
                                        onChange(
                                            valueAsObject ? o : o[valueKey]
                                        );
                                        handleClose();
                                    }}
                                >
                                    {o.label}
                                </MenuItem>
                            </React.Fragment>
                        ))}
                    </Menu>
                </>
            }
        />
    );
};

export default React.memo(TagDropdown);

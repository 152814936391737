import { useQuery } from '@tanstack/react-query';
import { useAuth0 } from '@auth0/auth0-react';
import hash from 'object-hash';
import {
    availabilityProductDtoToAvailabilityProduct,
    filterToRequestBodyConverter,
} from './availability.converters';

import Worker from './availability.worker';

import {
    Availability,
    AvailabilityFilterValues,
    AvailableProduct,
} from './availability.types';
import { ProductsService } from '../../requests';
import { CustomQueryOptions } from '../common.types';

async function communicateWithWorker(data: any) {
    return new Promise<Availability[] | undefined>(resolve => {
        const worker = new Worker();

        worker.onmessage = function (event: any) {
            resolve(event.data);
            worker.terminate();
        };

        worker.onerror = function (error: any) {
            console.log(error);
            resolve(undefined);
            worker.terminate();
        };

        worker.postMessage(data);
    });
}

export const useAvailability = (
    params: {
        requestBody: AvailabilityFilterValues;
    },
    options?: CustomQueryOptions<{
        products: Record<string, AvailableProduct>;
        events?: Availability[];
    }>
) => {
    const { getAccessTokenSilently } = useAuth0();

    return useQuery({
        queryKey: [params?.requestBody],
        queryFn: async () => {
            const token = await getAccessTokenSilently();
            const authorization = `Bearer ${token}`;

            const products: Record<string, AvailableProduct> = {};

            const data = await ProductsService.computeAvailability(
                authorization,
                filterToRequestBodyConverter(params?.requestBody)
            );

            const allEvents = data.items?.map(({ events, ...item }) => {
                const product =
                    availabilityProductDtoToAvailabilityProduct(item);
                const key = hash(product);
                products[key] = product;

                return {
                    key,
                    id: product.id,
                    name: product.name,
                    type: product.type,
                    shared: product.shared,
                    events: [
                        ...(events?.new || []),
                        ...(events?.shared.filter(n => n.optimal) || []),
                    ],
                };
            });

            console.log('products', products);
            console.log('events before', allEvents);
            const events = await communicateWithWorker(allEvents || []);
            console.log('events after', events);

            return {
                products,
                events,
            };
        },
        ...options,
    });
};

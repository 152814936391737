import React, { useCallback, useMemo, useState } from 'react';
import {
    CapacityOptionTypes,
    PricingType,
    Product,
    UserGroupType,
    useDeleteProduct,
    useShareProduct,
} from '@travelity/api';
import { Box, Divider, Stack } from '@mui/material';
import VisibilityIcon from '@mui/icons-material/Visibility';
import ContentCopyRoundedIcon from '@mui/icons-material/ContentCopyRounded';
import ShareIcon from '@mui/icons-material/Share';
import DeleteIcon from '@mui/icons-material/Delete';
import { useTranslation } from 'react-i18next';
import {
    Card,
    IconButton,
    IconButtonGroup,
    Tag,
    SquareCard,
    ConfirmationDialog,
} from '@travelity/ui';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import PaymentsOutlinedIcon from '@mui/icons-material/PaymentsOutlined';
import Diversity3OutlinedIcon from '@mui/icons-material/Diversity3Outlined';
import DirectionsRoundedIcon from '@mui/icons-material/DirectionsRounded';
import { durationToString } from '@travelity/utils';
import { useNavigate } from 'react-router-dom';

import { useSnackbar } from 'notistack';
import { Route } from '../route';
import { OverlayWithReason } from '../overlay-with-reason';
import { TeamsModal } from '../teams-modal';
import { useUserContext } from '../../contexts/user';

function numberWithSpaces(x: number) {
    const parts = x.toString().split('.');
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
    return parts.join('.');
}

export interface ProductItemProps {
    product: Product;
    isSelected?: boolean;
    refetch: () => void;
}

const ProductItem: React.FC<ProductItemProps> = ({
    product,
    refetch,
    isSelected,
}) => {
    const { t } = useTranslation('product');
    const { enqueueSnackbar } = useSnackbar();

    const [deletingProduct, setDeletingProduct] = useState<string>();

    const { mutate: removeProduct } = useDeleteProduct({
        onSuccess: () => {
            setDeletingProduct(undefined);
            refetch();
            enqueueSnackbar(
                `Deleted the product "${product.productInfo.name}"`,
                {
                    variant: 'success',
                }
            );
        },
        onError: () => {
            enqueueSnackbar(
                `Failed to delete the product "${product.productInfo.name}"`,
                {
                    variant: 'error',
                }
            );
        },
    });

    const { user } = useUserContext();
    const selectedTeams = product.sharedTeams.filter(
        ({ id }) => id !== user?.id
    );

    const duration = durationToString(product.schedule.duration || {});
    const navigate = useNavigate();

    const minPrice = useMemo(() => {
        const prices =
            product.financial?.items
                .map(item => {
                    const base = item.price.base || 0;
                    if (item.type === PricingType.PER_PERSON) {
                        return (item.price.perPerson || 0) + base;
                    }
                    if (item.type === PricingType.PER_PRODUCT) {
                        return (item.price.perProduct || 0) + base;
                    }
                    return (
                        (Math.min(
                            ...Object.values(item.price.counts || {}).filter(
                                v => !!v
                            )
                        ) || 0) + base
                    );
                })
                .filter(v => !!v) || [];

        return prices?.length ? Math.min(...prices) : undefined;
    }, [product]);

    const minPriceFormatted = minPrice
        ? `${numberWithSpaces(minPrice)} ${(product.financial?.currency || '')
              .toUpperCase()
              .substring(0, 3)}`
        : '-';

    const minCapacity = useMemo(() => {
        const capacities = product.capacity.capacities.map(capacity => {
            return capacity.type === CapacityOptionTypes.FLAT
                ? capacity.min
                : 1;
        });

        return Math.min(...capacities);
    }, [product]);

    const routs = useMemo(() => {
        const list = [];

        product.route.stops.forEach(stop => {
            list.push(stop.name);
        });

        if (product.route.stops[0]?.repeatLocation) {
            list.push(product.route.stops[0].name);
        }
        return list;
    }, [product]);

    const [productToShare, setProductToShare] = useState<string>();

    const { mutate: shareProduct, isLoading } = useShareProduct({
        onSuccess: () => {
            setProductToShare(undefined);
            refetch();
            enqueueSnackbar('Product sharing updated successfully', {
                variant: 'success',
            });
        },
        onError: () => {
            enqueueSnackbar(
                `Failed to update sharing of the product "${product.productInfo.name}"`,
                {
                    variant: 'error',
                }
            );
        },
    });

    const confirmShare = useCallback(
        (teams: { id: string; type: UserGroupType }[]) => {
            const added = teams
                .filter(
                    ({ id: teamId }) =>
                        !product.sharedTeams.find(({ id }) => id === teamId)
                )
                .map(group => ({ group }));

            const removed = product.sharedTeams
                .filter(
                    ({ id }) => !teams.find(({ id: teamId }) => id === teamId)
                )
                .filter(({ id }) => id !== user?.id)
                .map(group => ({ group }));

            shareProduct({
                productId: productToShare as string,
                requestBody: {
                    shared_with: added,
                    stop_sharing_with: removed,
                },
            });
        },
        [productToShare]
    );

    const canCreate = user?.roleAccess.product?.create;

    return (
        <>
            <Card
                sx={{
                    height: '185px',
                }}
                isSelected={isSelected}
                direction="row"
                justifyContent="space-evenly"
                buttons={
                    <IconButtonGroup>
                        <IconButton
                            icon={<VisibilityIcon fontSize="small" />}
                            href={`/products/${product.id}`}
                            tooltip="Preview"
                        />
                        {canCreate && (
                            <>
                                <Divider sx={{ mx: 0.75 }} />
                                <IconButton
                                    icon={
                                        <ContentCopyRoundedIcon fontSize="small" />
                                    }
                                    onClick={() =>
                                        navigate(
                                            `/products/add/${product.type}`,
                                            {
                                                state: {
                                                    ...product,
                                                    productInfo: {
                                                        ...product.productInfo,
                                                        name: '',
                                                    },
                                                },
                                            }
                                        )
                                    }
                                    tooltip="Duplicate"
                                />
                            </>
                        )}
                        {product.permissions.canShare && (
                            <>
                                <Divider sx={{ mx: 0.75 }} />
                                <IconButton
                                    icon={<ShareIcon fontSize="small" />}
                                    onClick={() =>
                                        setProductToShare(product.id)
                                    }
                                    tooltip="Share"
                                />
                            </>
                        )}
                        {product.permissions.canDelete && (
                            <>
                                <Divider sx={{ mx: 0.75 }} />
                                <IconButton
                                    icon={<DeleteIcon fontSize="small" />}
                                    onClick={() =>
                                        setDeletingProduct(product.id)
                                    }
                                    hoverColor="error.main"
                                    tooltip="Delete"
                                />
                            </>
                        )}
                    </IconButtonGroup>
                }
            >
                <Stack
                    sx={{
                        width: '50%',
                        filter: !product.active ? 'blur(2px)' : undefined,
                    }}
                >
                    <Stack
                        direction="row"
                        justifyContent="space-between"
                        alignItems="center"
                        sx={{
                            px: 2,
                            height: '46px',
                            py: '10px',
                            bgcolor: '#F4F6FA',
                            borderRadius: '12px 0px 0px 0px',
                        }}
                    >
                        <Box
                            component="span"
                            title={product.productInfo.name}
                            sx={{
                                color: '#8F97AA',
                                pr: 1,
                                overflow: 'hidden',
                                whiteSpace: 'nowrap',
                                textOverflow: 'ellipsis',
                            }}
                        >
                            {product.productInfo.name}
                        </Box>
                        <Tag label="Type" values={[t(product.type)]} />
                    </Stack>
                    <Stack
                        sx={{
                            grow: 2,
                            pl: 2,
                            pr: 2 + (3 - 1),
                            py: '10px',
                            height: '139px',
                        }}
                        direction="row"
                        justifyContent="space-evenly"
                        gap={1}
                    >
                        <SquareCard
                            title="Duration"
                            value={duration}
                            Icon={AccessTimeIcon}
                        />
                        <SquareCard
                            title="Min Price"
                            value={minPriceFormatted}
                            Icon={PaymentsOutlinedIcon}
                        />
                        <SquareCard
                            title="Min Capacity"
                            value={`${minCapacity}`}
                            Icon={Diversity3OutlinedIcon}
                        />
                    </Stack>
                </Stack>
                <Stack
                    sx={{
                        width: '50%',
                        borderLeft: '2px solid #C9CEDC',
                        position: 'relative',
                        filter: !product.active ? 'blur(2px)' : undefined,
                    }}
                >
                    <Stack
                        direction="row"
                        alignItems="center"
                        gap={2}
                        sx={{
                            px: 2,
                            height: '46px',
                            py: '10px',
                            bgcolor: '#F4F6FA',
                            borderRadius: '0px 12px 0px 0px',
                        }}
                    >
                        <DirectionsRoundedIcon sx={{ color: '#8F97AA' }} />
                        <Box component="span" sx={{ color: '#8F97AA' }}>
                            Route
                        </Box>
                    </Stack>
                    <Stack sx={{ height: '139px', overflow: 'hidden' }}>
                        <Route stops={routs} />
                    </Stack>
                    {routs.length > 3 && (
                        <Box
                            sx={{
                                position: 'absolute',
                                bottom: 0,
                                width: 1,
                                height: '24px',
                                background: 'rgba(244, 246, 250, 0.9)',
                                borderTop: '#C9CEDC solid 1px',
                                textAlign: 'center',
                                borderRadius: '0 0 12px 0',
                                lineHeight: '18px',
                            }}
                        >
                            <Box
                                onClick={() =>
                                    navigate(`/products/${product.id}`, {
                                        state: 2,
                                    })
                                }
                                component="span"
                                sx={{
                                    textDecoration: 'underline',
                                    cursor: 'pointer',
                                    fontSize: '12px',
                                    color: '#2B395B',
                                }}
                            >
                                See All Stops ({routs.length})
                            </Box>
                        </Box>
                    )}
                </Stack>
                {!product.active && (
                    <OverlayWithReason
                        title="Inactive"
                        reason={`${product.productInfo.name} is inactive, because we are configuring its availability calendar. It can take couple of minutes.`}
                    />
                )}
            </Card>
            <ConfirmationDialog
                title="Are you sure you want to delete this product?"
                content="This will delete this product permanently. You cannot undo
                    this action."
                confirmText="Delete"
                open={!!deletingProduct}
                handleCancel={() => setDeletingProduct(undefined)}
                handleConfirm={() =>
                    removeProduct({ productId: deletingProduct as string })
                }
            />
            {productToShare && (
                <TeamsModal
                    handleCancel={() => setProductToShare(undefined)}
                    handleConfirm={confirmShare}
                    open
                    selectedTeams={selectedTeams}
                    isLoading={isLoading}
                />
            )}
        </>
    );
};

export default ProductItem;

import React, { useState, useRef, useEffect } from 'react';
import { useTheme } from '@mui/material/styles';
import {
    Avatar,
    Box,
    ClickAwayListener,
    List,
    ListItemAvatar,
    ListItem,
    ListItemButton,
    ListItemText,
    Paper,
    Popper,
    Typography,
} from '@mui/material';

import { User } from '@travelity/api';
import { useAuth0 } from '@auth0/auth0-react';
import PersonIcon from '@mui/icons-material/Person';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import MainCard from '../../../../components/main-card/main-card';
import Transitions from '../../../../components/transitions/transitions';
import { useNavigateAbsolute } from '../../../../hooks';

function ProfileSection({ user }: { user?: User }) {
    const theme = useTheme();
    const navigate = useNavigateAbsolute();
    const { user: authUser } = useAuth0();
    const { logout } = useAuth0();

    const [selectedIndex, setSelectedIndex] = useState(-1);
    const [open, setOpen] = useState<number>(0);
    /**
     * anchorRef is used on different components and specifying one type leads to other components throwing an error
     * */
    const anchorRef = useRef<HTMLLIElement | null>(null);
    const handleLogout = async () => {
        logout({
            logoutParams: {
                returnTo: window.location.origin,
            },
        });
    };

    const handleClose = (
        event: React.MouseEvent<HTMLDivElement> | MouseEvent | TouchEvent
    ) => {
        if (
            anchorRef.current &&
            anchorRef.current.contains(event.target as Node)
        ) {
            return;
        }
        setOpen(0);
    };

    const handleListItemClick = (
        event: React.MouseEvent<HTMLDivElement>,
        index: number,
        route = ''
    ) => {
        setSelectedIndex(index);
        handleClose(event);

        if (route && route !== '') {
            navigate(route);
        }
    };
    const handleToggle = (e: React.MouseEvent) => {
        setOpen(prevOpen =>
            prevOpen ? 0 : e.currentTarget?.clientWidth || 198
        );
    };

    const prevOpen = useRef(open);
    useEffect(() => {
        if (prevOpen.current && !open) {
            anchorRef.current?.focus();
        }

        prevOpen.current = open;
    }, [open]);

    return (
        <>
            <ListItem
                sx={{
                    width: 'auto',
                    cursor: 'pointer',
                    pl: 1.5,
                    borderRadius: '12px',
                    py: 0,
                    border: 'transparent 1px solid',
                    ...(open && {
                        border: '#DFE1ED 1px solid',
                        boxShadow: '0px 0px 10px 0px rgba(178, 185, 205, 0.40)',
                    }),
                }}
                ref={anchorRef}
                aria-controls={open ? 'nav-item-grow' : undefined}
                aria-haspopup="true"
                onClick={handleToggle}
            >
                <ListItemAvatar>
                    <Avatar
                        alt={authUser?.name}
                        sx={{
                            width: 40,
                            height: 40,
                            fontSize: '14px',
                            bgcolor: open ? '#7682A4' : '#B2B9CD',
                        }}
                        color="inherit"
                        src={user?.avatar}
                    >
                        <PersonIcon />
                    </Avatar>
                </ListItemAvatar>
                <ListItemText
                    sx={{
                        my: '2px',
                        '& .MuiListItemText-primary': {
                            color: '#2B395B',
                            fontWeight: 600,
                        },
                        '& .MuiListItemText-secondary': {
                            color: '#949BAC',
                        },
                    }}
                    primary={
                        user?.fullName || authUser?.nickname || authUser?.name
                    }
                    secondary={authUser?.email}
                />
                <ExpandMoreIcon />
            </ListItem>

            <Popper
                placement="bottom-end"
                open={!!open}
                anchorEl={anchorRef.current}
                role={undefined}
                transition
                disablePortal
                popperOptions={{
                    modifiers: [
                        {
                            name: 'offset',
                            options: {
                                offset: [0, 4],
                            },
                        },
                    ],
                }}
            >
                {({ TransitionProps }) => (
                    <Transitions in={open} {...TransitionProps}>
                        <Paper
                            sx={{
                                borderRadius: '12px',
                                border: '1px solid #DFE1ED',
                                background: '#FFF',
                                width: open,
                                boxShadow:
                                    '0px 0px 10px 0px rgba(178, 185, 205, 0.40)',
                            }}
                        >
                            <ClickAwayListener onClickAway={handleClose}>
                                <MainCard
                                    border={false}
                                    elevation={16}
                                    content={false}
                                    boxShadow
                                    shadow={theme.shadows[16]}
                                >
                                    <Box>
                                        <List
                                            component="nav"
                                            sx={{
                                                width: '100%',
                                                backgroundColor:
                                                    theme.palette.background
                                                        .paper,
                                                borderRadius: '10px',
                                                [theme.breakpoints.down('md')]:
                                                    {
                                                        minWidth: '100%',
                                                    },
                                                '& .MuiListItemButton-root': {
                                                    mt: 0.5,
                                                },
                                            }}
                                        >
                                            <ListItemButton
                                                sx={{
                                                    margin: '4px 16px',
                                                    borderRadius: '8px',
                                                }}
                                                selected={selectedIndex === 0}
                                                onClick={e =>
                                                    handleListItemClick(
                                                        e,
                                                        0,
                                                        '/me/personal-details'
                                                    )
                                                }
                                            >
                                                <ListItemText
                                                    sx={{ color: '#7682A4' }}
                                                    primary={
                                                        <Typography variant="body2">
                                                            Profile Settings
                                                        </Typography>
                                                    }
                                                />
                                            </ListItemButton>
                                            <ListItemButton
                                                sx={{
                                                    margin: '4px 16px',
                                                    borderRadius: '8px',
                                                }}
                                                selected={selectedIndex === 1}
                                                onClick={e =>
                                                    handleListItemClick(
                                                        e,
                                                        1,
                                                        '/me/settings'
                                                    )
                                                }
                                            >
                                                <ListItemText
                                                    sx={{ color: '#7682A4' }}
                                                    primary={
                                                        <Typography variant="body2">
                                                            App Settings
                                                        </Typography>
                                                    }
                                                />
                                            </ListItemButton>
                                            <ListItemButton
                                                sx={{
                                                    margin: '4px 16px',
                                                    borderRadius: '8px',
                                                }}
                                                selected={selectedIndex === 4}
                                                onClick={handleLogout}
                                            >
                                                <ListItemText
                                                    sx={{ color: '#7682A4' }}
                                                    primary={
                                                        <Typography variant="body2">
                                                            Logout
                                                        </Typography>
                                                    }
                                                />
                                            </ListItemButton>
                                        </List>
                                    </Box>
                                </MainCard>
                            </ClickAwayListener>
                        </Paper>
                    </Transitions>
                )}
            </Popper>
        </>
    );
}

export default React.memo(ProfileSection);

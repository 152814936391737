import { useMemo } from 'react';
import {
    useMutation,
    UseMutationOptions,
    useQuery,
    useQueryClient,
} from '@tanstack/react-query';
import { useAuth0 } from '@auth0/auth0-react';
import { User } from './user.types';
import {
    currencyAbbrToCurrencyName,
    subscriptionKeys,
    userDtoToUser,
} from './user.converters';
import {
    ProjectService,
    RegisterUsersReqDto,
    UpdateUserReqDto,
    UpdateUserResDto,
    UploadAvatarUsersReq1Dto,
    UsersService,
} from '../../requests';
import { CustomMutationOptions, CustomQueryOptions } from '../common.types';

const useUserKey = 'useUserKey';

export const useRegister = (
    options: CustomMutationOptions<
        RegisterUsersReqDto,
        ReturnType<typeof UsersService.registerUsers>
    > = {}
) => {
    const { getAccessTokenSilently } = useAuth0();
    // const queryClient = useQueryClient();

    return useMutation({
        mutationFn: async data => {
            const token = await getAccessTokenSilently();
            const authorization = `Bearer ${token}`;
            const response = await UsersService.registerUsers(
                authorization,
                data
            );
            // queryClient.setQueryData<GetUserResDto>(
            //     [useUserKey],
            //     () => response
            // );
            return response;
        },
        ...options,
    });
};

export const useProject = (
    key?: string,
    options: CustomQueryOptions<
        ReturnType<typeof ProjectService.getProject>
    > = {}
) => {
    return useQuery({
        queryKey: [],
        queryFn: async () => {
            return ProjectService.getProject(subscriptionKeys[key]);
        },
        enabled: !!key,
        cacheTime: 0,
        ...options,
    });
};

export const useUser = (
    options: CustomQueryOptions<ReturnType<typeof UsersService.getUser>> = {}
) => {
    const { getAccessTokenSilently } = useAuth0();

    const { data: userDto, ...rest } = useQuery({
        queryFn: async () => {
            const token = await getAccessTokenSilently();
            const authorization = `Bearer ${token}`;
            return UsersService.getUser(authorization);
        },
        queryKey: [useUserKey],
        retry: false,
        cacheTime: 10,
        ...options,
    });

    const updateDate = userDto?.last_updated?.at || userDto?.created?.at;
    const data: User | undefined = useMemo(() => {
        if (!userDto) {
            return undefined;
        }

        return userDtoToUser(userDto);
    }, [updateDate]);

    return {
        data,
        ...rest,
    };
};

export const useUpdateUser = (
    options: CustomMutationOptions<
        UpdateUserReqDto,
        ReturnType<typeof UsersService.updateUser>
    > = {}
) => {
    const { getAccessTokenSilently } = useAuth0();
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: async data => {
            const token = await getAccessTokenSilently();
            const authorization = `Bearer ${token}`;
            return UsersService.updateUser(authorization, data);
        },
        ...options,
        onSuccess: (...args) => {
            queryClient.setQueryData([useUserKey], args[0]);
            options.onSuccess?.(...args);
        },
        onError: (error, ...args) => {
            // @ts-ignore
            if (error?.status === 409) {
                options?.onSuccess?.({} as UpdateUserResDto, ...args);
            } else {
                options?.onError?.(error, ...args);
            }
        },
    });
};

export const useUploadAvatarUser = (
    options: CustomMutationOptions<
        UploadAvatarUsersReq1Dto,
        ReturnType<typeof UsersService.uploadAvatarUsers>
    > = {}
) => {
    const { getAccessTokenSilently } = useAuth0();
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: async data => {
            const token = await getAccessTokenSilently();
            const authorization = `Bearer ${token}`;
            return UsersService.uploadAvatarUsers(authorization, data);
        },
        ...options,
        onSuccess: (...args) => {
            queryClient.setQueryData([useUserKey], args[0]);
            options?.onSuccess?.(...args);
        },
    });
};

export const useUpdateCurrency = (
    options?: Omit<
        UseMutationOptions<
            Awaited<ReturnType<typeof UsersService.updateCurrencyUsers>>,
            unknown,
            {
                currency: string;
                rate: number;
            },
            unknown
        >,
        'mutationFn'
    >
) => {
    const queryClient = useQueryClient();
    const { getAccessTokenSilently } = useAuth0();
    return useMutation(
        async ({ currency, rate }) => {
            const token = await getAccessTokenSilently();
            const authorization = `Bearer ${token}`;
            return UsersService.updateCurrencyUsers(authorization, {
                name: currencyAbbrToCurrencyName(currency),
                abbr: currency,
                rate,
            });
        },
        {
            ...options,
            onSuccess: (...args) => {
                queryClient.setQueryData([useUserKey], args[0]);
                options?.onSuccess?.(...args);
            },
        }
    );
};

export const useRemoveCurrency = (
    options?: Omit<
        UseMutationOptions<
            Awaited<ReturnType<typeof UsersService.updateCurrencyUsers>>,
            unknown,
            {
                currency: string;
            },
            unknown
        >,
        'mutationFn'
    >
) => {
    const queryClient = useQueryClient();
    const { getAccessTokenSilently } = useAuth0();
    return useMutation(
        async ({ currency }) => {
            const token = await getAccessTokenSilently();
            const authorization = `Bearer ${token}`;
            return UsersService.updateCurrencyUsers(authorization, {
                name: currencyAbbrToCurrencyName(currency),
                abbr: currency,
                rate: 0,
            });
        },
        {
            ...options,
            onSuccess: (...args) => {
                queryClient.setQueryData([useUserKey], args[0]);
                options?.onSuccess?.(...args);
            },
        }
    );
};

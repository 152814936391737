/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { CancelEventsResAccessDto } from './CancelEventsResAccessDto';
import type { CancelEventsResBookingsItemDto } from './CancelEventsResBookingsItemDto';
import type { CancelEventsResOperationsDto } from './CancelEventsResOperationsDto';
import type { CancelEventsResProductDto } from './CancelEventsResProductDto';
import type { EventStatus } from './EventStatus';
import type { PeriodData } from './PeriodData';
import type { UpdateUserResCreatedDto } from './UpdateUserResCreatedDto';

export type CancelEventsResDto = {
    /**
     * Type of the resource
     */
    resource_type?: CancelEventsResDto.resource_type;
    /**
     * Identifier of the document in the database.
     */
    id: string;
    /**
     * Details about who has created the database record and when.
     */
    created: UpdateUserResCreatedDto;
    /**
     * Details about who and when made the most recent update to the record.
     */
    last_updated?: UpdateUserResCreatedDto;
    /**
     * Details about who and when deleted/archived the record.
     */
    deleted?: UpdateUserResCreatedDto;
    /**
     * Contains info about the cancellation of the resource
     */
    cancelled?: UpdateUserResCreatedDto;
    /**
     * Details about all activities happening to the record
     */
    lifecycle: Array<UpdateUserResCreatedDto>;
    /**
     * For shared events, indicates whether more bookings can be accepted
     */
    locked?: boolean;
    /**
     * Product selected for the event
     */
    product?: CancelEventsResProductDto;
    /**
     * Timestamp which represents date and time of the event.
     */
    date?: PeriodData;
    /**
     * Event operations
     */
    operations?: CancelEventsResOperationsDto;
    /**
     * Automatically computed status of the booking.
     */
    status: EventStatus;
    /**
     * Access to the shared product
     */
    access?: CancelEventsResAccessDto;
    /**
     * Access to the shared product
     */
    bookings?: Array<CancelEventsResBookingsItemDto>;
};

export namespace CancelEventsResDto {

    /**
     * Type of the resource
     */
    export enum resource_type {
        EVENT = 'event',
    }


}

import React, {
    SyntheticEvent,
    useCallback,
    useMemo,
    useRef,
    useState,
} from 'react';
import { useController, useFormContext } from 'react-hook-form';
import {
    Box,
    Divider,
    FormControl,
    FormHelperText,
    InputAdornment,
    InputLabel,
    MenuItem as MuiMenuItem,
    Select as MuiSelect,
    SelectProps as MuiSelectProps,
} from '@mui/material';
import { xor, get } from 'lodash';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import MuiOutlinedInput from '@mui/material/OutlinedInput';
import SearchIcon from '@mui/icons-material/Search';
import Checkbox from '@mui/material/Checkbox';
import { CheckedIcon, UncheckedIcon } from '@travelity/ui';
import ListItemText from '@mui/material/ListItemText';

export interface SearchSelectOption {
    value: string;
    label: string;

    [key: string]: any;
}

export interface SearchSelectProps extends MuiSelectProps {
    width?: string;
    size?: 'small' | 'medium';
    name: string;
    label: string;
    placeholder?: string;
    required?: boolean;
    options: SearchSelectOption[];
    showErrors?: boolean;
    MenuItem?: React.FC<any>;
}

const DefaultMenuItem: React.FC<{ label: string }> = props => {
    return (
        <Box
            sx={{
                lineHeight: '12px',
                py: '8px',
                px: '8px',
            }}
        >
            {props.label}
        </Box>
    );
};

const SearchSelect: React.FC<SearchSelectProps> = ({
    width,
    size,
    options,
    name,
    required,
    label,
    placeholder,
    showErrors,
    disabled,
    multiple,
    MenuItem = DefaultMenuItem,
    ...selectProps
}) => {
    const {
        control,
        formState: { errors },
    } = useFormContext();

    const { field } = useController({
        name,
        control,
        defaultValue: multiple ? [] : undefined,
    });

    const [open, setOpen] = useState(false);
    const [searchText, setSearchText] = useState('');

    const filteredOptions = useMemo(() => {
        return searchText
            ? options.filter(({ label }) => {
                  return label.toLowerCase().includes(searchText.toLowerCase());
              })
            : options;
    }, [options, searchText]);

    const onClose = useCallback((e: SyntheticEvent<Element, Event>) => {
        if ((e.target as Element).id === 'search') return;
        setOpen(false);
        setSearchText('');
    }, []);

    const onChange = useCallback(
        (value: string) => {
            if (multiple) {
                field.onChange(xor(field.value, [value]));
            } else {
                field.onChange(value);
                setOpen(false);
                setSearchText('');
            }
        },
        [field, multiple]
    );

    const error =
        showErrors && (get(errors, name)?.message as string | undefined);

    const ref = useRef();
    const inputWidth = ref.current ? (ref.current as any).clientWidth : 0;
    console.log('inputWidth', inputWidth);

    return (
        <FormControl
            sx={{ width: width || '388px' }}
            error={!!error}
            size={size}
            disabled={disabled}
        >
            <MuiSelect
                open={open}
                onOpen={() => setOpen(true)}
                onClose={onClose}
                required={!!required}
                inputProps={{ required: false }}
                label={label}
                displayEmpty
                ref={ref}
                IconComponent={KeyboardArrowDownIcon}
                sx={{
                    ...(selectProps.sx ? selectProps.sx : {}),
                    '&&& legend': placeholder
                        ? {
                              maxWidth: '100%',
                          }
                        : {},
                    '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                        border: '1px solid #DFE1ED',
                    },
                    '&& .MuiOutlinedInput-notchedOutline': {
                        ...(open && {
                            borderBottom: 'none',
                            borderBottomLeftRadius: 0,
                            borderBottomRightRadius: 0,
                        }),
                    },
                }}
                renderValue={selectedValue => {
                    const selected = multiple
                        ? selectedValue.length
                        : selectedValue;
                    if (!selected && placeholder) {
                        return (
                            <Box
                                component="span"
                                sx={{
                                    color: '#949BAC',
                                }}
                            >
                                {placeholder}
                            </Box>
                        );
                    }

                    return multiple
                        ? options
                              .filter(({ value }) =>
                                  selectedValue.includes(value)
                              )
                              .map(({ label }) => label)
                              .join(', ')
                        : options.find(({ value }) => value === selectedValue)
                              ?.label;
                }}
                disabled={disabled}
                multiple={multiple}
                {...selectProps}
                value={field.value || ''}
                MenuProps={{
                    sx: { zIndex: 100000 },
                    PaperProps: {
                        sx: {
                            width: inputWidth,
                            maxHeight: 180,
                            boxShadow: 0,
                            border: '1px solid #DFE1ED',
                            borderTop: 0,
                            borderRadius: '32px',
                            borderTopLeftRadius: 0,
                            borderTopRightRadius: 0,
                            marginTop: 0,
                            p: 2,
                            pt: 0,
                        },
                    },
                    MenuListProps: {
                        sx: {
                            borderTop: '#DFE1ED 1px solid',
                            py: 1,
                        },
                    },
                    slotProps: {
                        backdrop: {
                            sx: {
                                opacity: '0.2 !important',
                            },
                        },
                    },
                    disableAutoFocusItem: true,
                }}
            >
                <MuiOutlinedInput
                    size="small"
                    autoFocus
                    fullWidth
                    id="search"
                    value={searchText}
                    onKeyDown={e => e.stopPropagation()}
                    onChange={e => setSearchText(e.target.value)}
                    sx={{
                        mb: 1,
                    }}
                    inputProps={{
                        sx: {
                            '&&&': { pl: 0 },
                        },
                    }}
                    startAdornment={
                        <InputAdornment position="start">
                            <SearchIcon sx={{ color: '#B2B9CD' }} />
                        </InputAdornment>
                    }
                />
                {!multiple &&
                    filteredOptions.map((option, i) => (
                        <Box sx={{ pb: 0.5 }} key={option.label}>
                            {i !== 0 && <Divider />}
                            <MuiMenuItem
                                sx={{
                                    fontSize: '12px',
                                    color: '#2B395B',
                                    borderRadius: '8px',
                                    mt: '4px',
                                    py: '4px',
                                    px: '4px',
                                    maxWidth: 320,
                                    '&:hover': {
                                        backgroundColor: '#EAF2F4',
                                    },
                                    ...((multiple &&
                                        field.value?.includes(option.value)) ||
                                    (!multiple && field.value === option.value)
                                        ? {
                                              backgroundColor: '#D7E7EB',
                                          }
                                        : {}),
                                }}
                                key={option.value}
                                onClick={() => onChange(option.value)}
                            >
                                <MenuItem {...option} />
                            </MuiMenuItem>
                        </Box>
                    ))}
                {multiple &&
                    filteredOptions.map((option, i) => (
                        <MuiMenuItem
                            sx={{
                                ':before':
                                    i > 0
                                        ? {
                                              borderTop: '1px solid #DFE1ED',
                                              content: '""',
                                              position: 'absolute',
                                              width: 1,
                                              top: 0,
                                          }
                                        : undefined,
                                ':hover': {
                                    backgroundColor: 'transparent',
                                },
                                '&.Mui-focusVisible': {
                                    backgroundColor: 'transparent',
                                },
                                '&&&.Mui-selected': {
                                    backgroundColor: 'transparent',
                                },
                                pl: 0,
                            }}
                            key={option.value}
                            value={option.value}
                            onClick={() => onChange(option.value)}
                            disableRipple
                        >
                            <Checkbox
                                checkedIcon={<CheckedIcon />}
                                icon={<UncheckedIcon />}
                                checked={
                                    field.value?.indexOf(option.value) > -1
                                }
                            />
                            <ListItemText primary={option.label} />
                        </MuiMenuItem>
                    ))}
            </MuiSelect>
            <InputLabel
                required={!!required}
                shrink
                sx={{
                    '&.Mui-focused': {
                        color: '#949BAC',
                    },
                }}
            >
                {label}
            </InputLabel>
            {error && <FormHelperText>{error}</FormHelperText>}
        </FormControl>
    );
};

export default SearchSelect;

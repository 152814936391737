import { useController, useFormContext } from 'react-hook-form';
import {
    DatePicker as MuiDatePicker,
    DatePickerProps as MuiDatePickerProps,
} from '@mui/x-date-pickers/DatePicker';
import React, { useEffect } from 'react';
import _ from 'lodash';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import {
    PickersDay as MuiPickersDay,
    PickersDayProps,
} from '@mui/x-date-pickers';
import { startOfDay } from 'date-fns';
import {
    IconButton,
    TextFieldProps as MuiTextFieldProps,
    Tooltip,
} from '@mui/material';

export type DatePickerProps = MuiDatePickerProps<Date> & {
    name: string;
    disableBeforeField?: string;
    multiple?: boolean;
    label: string;
    required?: boolean;
    showErrors?: boolean;
    iconTooltip?: string;
    TextFieldProps?: Partial<MuiTextFieldProps>;
    disableBefore?: Date;
    disableAfter?: Date;
};

const findIndexDate = (dates: Date[], date: Date) => {
    const dateTime = date.getTime();
    return dates?.findIndex(item => item.getTime() === dateTime);
};

const PickerDay: React.FC<PickersDayProps<Date>> = props => {
    // @ts-ignore
    const { values, ...rest } = props;
    const dateTime = rest.day.getTime();
    const selected = !!values?.find(
        (item: Date | undefined) => item?.getTime() === dateTime
    );
    return <MuiPickersDay {...rest} selected={selected} />;
};

const DatePicker: React.FC<DatePickerProps> = ({
    name,
    disableBeforeField,
    required,
    multiple,
    label,
    showErrors,
    iconTooltip,
    TextFieldProps = {},
    disableBefore,
    disableAfter,
    ...rest
}) => {
    const {
        watch,
        control,
        formState: { errors },
    } = useFormContext();

    const {
        field: { value, onChange },
    } = useController({
        name,
        control,
        defaultValue: multiple ? [] : undefined,
    });

    const disableBeforeValue = disableBeforeField
        ? watch(disableBeforeField)
        : undefined;
    useEffect(() => {
        if (disableBeforeValue && value && disableBeforeValue > value) {
            onChange(disableBeforeValue);
        }
    }, [disableBeforeValue, onChange, value]);

    const error = showErrors && _.get(errors, name)?.message;

    const disableBeforeDate = disableBeforeValue || disableBefore;

    return (
        <MuiDatePicker
            value={multiple ? undefined : value}
            onChange={newValue => {
                console.log(newValue);
                if (!newValue) return;
                if (!multiple) {
                    onChange(newValue);
                } else {
                    const array = [...value];
                    const date = startOfDay(newValue);
                    const index = findIndexDate(array, date);
                    if (index >= 0) {
                        array.splice(index, 1);
                    } else {
                        array.push(date);
                    }
                    onChange(array);
                }
            }}
            closeOnSelect={!multiple}
            sx={{
                '& input': {
                    width: '204px',
                },
            }}
            slots={{
                openPickerButton: iconTooltip
                    ? props => (
                          <Tooltip title={iconTooltip}>
                              <IconButton {...props} />
                          </Tooltip>
                      )
                    : undefined,
                openPickerIcon: CalendarMonthIcon,
                day: PickerDay,
            }}
            label={label}
            format="dd.MM.yyyy"
            timezone="UTC"
            referenceDate={disableBeforeDate}
            shouldDisableDate={
                disableBeforeDate || disableAfter
                    ? date => {
                          if (
                              disableBeforeDate &&
                              date.getTime() - disableBeforeDate.getTime() < 0
                          )
                              return true;
                          if (
                              disableAfter &&
                              date.getTime() - disableAfter.getTime() > 0
                          )
                              return true;
                          return false;
                      }
                    : undefined
            }
            {...rest}
            slotProps={_.defaultsDeep(
                {
                    popper: {
                        sx: {
                            zIndex: 100000,
                        },
                    },
                    openPickerIcon: {
                        sx: {
                            color: '#B2B9CD',
                        },
                    },
                    textField: {
                        'data-testid': `picker-${name}`,
                        error: !!error,
                        helperText: error,
                        fullWidth: true,
                        sx: { width: 'auto' },
                        InputLabelProps: {
                            required: !!required,
                            shrink: !!TextFieldProps.placeholder,
                        },
                        ...TextFieldProps,
                    },
                    day: {
                        values: multiple ? value : [value],
                        sx: {
                            '&:active': {
                                backgroundColor: 'primary.main',
                                color: '#FFF',
                            },
                            '&.Mui-selected': {
                                backgroundColor: 'primary.main',

                                '&:focus': {
                                    backgroundColor: 'primary.main',
                                },
                                '&:hover': {
                                    backgroundColor: 'primary.main',
                                },
                            },
                            '&.MuiPickersDay-today': {
                                border: 0,
                                backgroundColor: 'transparent',
                            },
                        },
                    },
                },
                rest.slotProps
            )}
        />
    );
};

export default DatePicker;

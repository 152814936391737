/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { BookingStatus } from '../models/BookingStatus';
import type { CreateBookingReq1Dto } from '../models/CreateBookingReq1Dto';
import type { CreateBookingReqDto } from '../models/CreateBookingReqDto';
import type { CreateBookingReqProductDto } from '../models/CreateBookingReqProductDto';
import type { CreateBookingRes1Dto } from '../models/CreateBookingRes1Dto';
import type { CreateBookingResDto } from '../models/CreateBookingResDto';
import type { CreateBookingResFinancialsDto } from '../models/CreateBookingResFinancialsDto';
import type { DiscountData } from '../models/DiscountData';
import type { GetBookingRes1Dto } from '../models/GetBookingRes1Dto';
import type { GetBookingResDto } from '../models/GetBookingResDto';
import type { GetBookingsResDto } from '../models/GetBookingsResDto';
import type { UpdateBookingNotesBookingsReqDto } from '../models/UpdateBookingNotesBookingsReqDto';
import type { UpdateBookingSourceBookingsReq1Dto } from '../models/UpdateBookingSourceBookingsReq1Dto';
import type { UpdateBookingSourceBookingsReqDto } from '../models/UpdateBookingSourceBookingsReqDto';
import type { UpdateCustomersBookingsReq1Dto } from '../models/UpdateCustomersBookingsReq1Dto';
import type { UpdateCustomersBookingsReqDto } from '../models/UpdateCustomersBookingsReqDto';
import type { UpdateEventBookingsRes1Dto } from '../models/UpdateEventBookingsRes1Dto';
import type { UpdateEventBookingsResDto } from '../models/UpdateEventBookingsResDto';
import type { UpdateProductOptionsBookingsReqDto } from '../models/UpdateProductOptionsBookingsReqDto';
import type { UpdateStatusBookingsReqDto } from '../models/UpdateStatusBookingsReqDto';
import type { UpdateTransactionsBookingsReqDto } from '../models/UpdateTransactionsBookingsReqDto';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class BookingsService {

    /**
     * List/Filter Bookings
     * @param authorization Bearer token (JWT) used for endpoint caller authentication
     * @param action action being filtered
     * @param atStart start/end of the time range being filtered
     * @param atEnd start/end of the time range being filtered
     * @param byId name of the person who's action is being filtered
     * @param byName name of the person who's action is being filtered
     * @param productTypes list of filtered product types
     * @param statuses list of filtered statuses
     * @param orderNumber Number of the order to which the bookings belong
     * @param eventId ID of the event to which the booking belongs
     * @param startTs Timestamp of start datetime
     * @param endTs Timestamp of end datetime
     * @param textSearch Search term
     * @param pageNumber ID/Number of the page to open
     * @param pageSize number of items returned
     * @returns GetBookingsResDto Successful Response
     * @throws ApiError
     */
    public static getBookings(
authorization: string,
action?: 'created' | 'last_updated' | 'deleted',
atStart?: number,
atEnd?: number,
byId?: string,
byName?: string,
productTypes?: Array<string>,
statuses?: Array<BookingStatus>,
orderNumber?: string,
eventId?: string,
startTs?: number,
endTs?: number,
textSearch?: string,
pageNumber?: number,
pageSize: number = 20,
): CancelablePromise<GetBookingsResDto> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/bookings',
            headers: {
                'authorization': authorization,
            },
            query: {
                'action': action,
                'at_start': atStart,
                'at_end': atEnd,
                'by_id': byId,
                'by_name': byName,
                'product_types': productTypes,
                'statuses': statuses,
                'order_number': orderNumber,
                'event_id': eventId,
                'start_ts': startTs,
                'end_ts': endTs,
                'text_search': textSearch,
                'page_number': pageNumber,
                'page_size': pageSize,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Create Booking
     * @param authorization Bearer token (JWT) used for endpoint caller authentication
     * @param requestBody 
     * @returns any Successful Response
     * @throws ApiError
     */
    public static createBooking(
authorization: string,
requestBody: (CreateBookingReqDto | CreateBookingReq1Dto),
): CancelablePromise<(CreateBookingResDto | CreateBookingRes1Dto)> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/v1/bookings',
            headers: {
                'authorization': authorization,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Fetch Booking
     * @param bookingId ID of the booking being fetched
     * @param authorization Bearer token (JWT) used for endpoint caller authentication
     * @returns any Successful Response
     * @throws ApiError
     */
    public static getBooking(
bookingId: string,
authorization: string,
): CancelablePromise<(GetBookingResDto | GetBookingRes1Dto)> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/v1/bookings/{booking_id}',
            path: {
                'booking_id': bookingId,
            },
            headers: {
                'authorization': authorization,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Move Booking from one event to another
     * @param bookingId ID of the booking being moved
     * @param eventId ID of the event the booking is moved to
     * @param authorization Bearer token (JWT) used for endpoint caller authentication
     * @returns any Successful Response
     * @throws ApiError
     */
    public static updateEventBookings(
bookingId: string,
eventId: string,
authorization: string,
): CancelablePromise<(UpdateEventBookingsResDto | UpdateEventBookingsRes1Dto)> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/api/v1/bookings/{booking_id}/event/{event_id}',
            path: {
                'booking_id': bookingId,
                'event_id': eventId,
            },
            headers: {
                'authorization': authorization,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Update customer details
     * @param bookingId ID of the booking being moved
     * @param authorization Bearer token (JWT) used for endpoint caller authentication
     * @param requestBody 
     * @returns any Successful Response
     * @throws ApiError
     */
    public static updateCustomersBookings(
bookingId: string,
authorization: string,
requestBody: (UpdateCustomersBookingsReqDto | UpdateCustomersBookingsReq1Dto),
): CancelablePromise<(UpdateEventBookingsResDto | UpdateEventBookingsRes1Dto)> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/api/v1/bookings/{booking_id}/customers',
            path: {
                'booking_id': bookingId,
            },
            headers: {
                'authorization': authorization,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Update booked product options
     * @param bookingId ID of the booking being updated
     * @param authorization Bearer token (JWT) used for endpoint caller authentication
     * @param requestBody 
     * @returns any Successful Response
     * @throws ApiError
     */
    public static updateProductOptionsBookings(
bookingId: string,
authorization: string,
requestBody: UpdateProductOptionsBookingsReqDto,
): CancelablePromise<(UpdateEventBookingsResDto | UpdateEventBookingsRes1Dto)> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/api/v1/bookings/{booking_id}/product_options',
            path: {
                'booking_id': bookingId,
            },
            headers: {
                'authorization': authorization,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Update booking status
     * @param bookingId ID of the booking being moved
     * @param authorization Bearer token (JWT) used for endpoint caller authentication
     * @param requestBody 
     * @returns any Successful Response
     * @throws ApiError
     */
    public static updateStatusBookings(
bookingId: string,
authorization: string,
requestBody: UpdateStatusBookingsReqDto,
): CancelablePromise<(UpdateEventBookingsResDto | UpdateEventBookingsRes1Dto)> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/api/v1/bookings/{booking_id}/status',
            path: {
                'booking_id': bookingId,
            },
            headers: {
                'authorization': authorization,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Update booking source
     * @param bookingId ID of the booking being updated
     * @param authorization Bearer token (JWT) used for endpoint caller authentication
     * @param requestBody 
     * @returns any Successful Response
     * @throws ApiError
     */
    public static updateBookingSourceBookings(
bookingId: string,
authorization: string,
requestBody: (UpdateBookingSourceBookingsReqDto | UpdateBookingSourceBookingsReq1Dto),
): CancelablePromise<(UpdateEventBookingsResDto | UpdateEventBookingsRes1Dto)> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/api/v1/bookings/{booking_id}/source',
            path: {
                'booking_id': bookingId,
            },
            headers: {
                'authorization': authorization,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Update bookings transactions
     * @param bookingId ID of the booking being moved
     * @param authorization Bearer token (JWT) used for endpoint caller authentication
     * @param requestBody 
     * @returns any Successful Response
     * @throws ApiError
     */
    public static updateTransactionsBookings(
bookingId: string,
authorization: string,
requestBody: UpdateTransactionsBookingsReqDto,
): CancelablePromise<(UpdateEventBookingsResDto | UpdateEventBookingsRes1Dto)> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/api/v1/bookings/{booking_id}/transactions',
            path: {
                'booking_id': bookingId,
            },
            headers: {
                'authorization': authorization,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Update booking notes
     * @param bookingId ID of the booking being updated
     * @param authorization Bearer token (JWT) used for endpoint caller authentication
     * @param requestBody 
     * @returns any Successful Response
     * @throws ApiError
     */
    public static updateBookingNotesBookings(
bookingId: string,
authorization: string,
requestBody: UpdateBookingNotesBookingsReqDto,
): CancelablePromise<(UpdateEventBookingsResDto | UpdateEventBookingsRes1Dto)> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/api/v1/bookings/{booking_id}/notes',
            path: {
                'booking_id': bookingId,
            },
            headers: {
                'authorization': authorization,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Update discount on the booking
     * @param bookingId ID of the booking being updated
     * @param authorization Bearer token (JWT) used for endpoint caller authentication
     * @param requestBody 
     * @returns any Successful Response
     * @throws ApiError
     */
    public static updateDiscountBookings(
bookingId: string,
authorization: string,
requestBody: DiscountData,
): CancelablePromise<(UpdateEventBookingsResDto | UpdateEventBookingsRes1Dto)> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/api/v1/bookings/{booking_id}/discount',
            path: {
                'booking_id': bookingId,
            },
            headers: {
                'authorization': authorization,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Compute price of a booking
     * @param authorization Bearer token (JWT) used for endpoint caller authentication
     * @param requestBody 
     * @returns CreateBookingResFinancialsDto Successful Response
     * @throws ApiError
     */
    public static computePriceBookings(
authorization: string,
requestBody: CreateBookingReqProductDto,
): CancelablePromise<CreateBookingResFinancialsDto> {
        return __request(OpenAPI, {
            method: 'PATCH',
            url: '/api/v1/bookings/compute_price',
            headers: {
                'authorization': authorization,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

}

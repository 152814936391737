import { array, mixed, object, string } from 'yup';
import { Country, Nationality } from '@travelity/api';

export const nameContactsSchema = object().shape({
    fullName: string().required(),
    occupation: mixed().required(),
    emails: array(),
    numbers: array(),
});

export const birthPasswordSchema = object().shape({
    birthPlace: string(),
    passportN: string(),
    issuedBy: string(),
    birthDate: string()
        .nullable()
        .transform((curr, orig) => (orig === '' ? null : curr))
        .matches(
            /^(0[1-9]|[12][0-9]|3[01])\.(0[1-9]|1[012])\.(19|20)\d\d+$/,
            'Is not in correct format'
        ),
    issuedAt: string()
        .nullable()
        .transform((curr, orig) => (orig === '' ? null : curr))
        .matches(
            /^(0[1-9]|[12][0-9]|3[01])\.(0[1-9]|1[012])\.(19|20)\d\d+$/,
            'Is not in correct format'
        ),
    expiresAt: string()
        .nullable()
        .transform((curr, orig) => (orig === '' ? null : curr))
        .matches(
            /^(0[1-9]|[12][0-9]|3[01])\.(0[1-9]|1[012])\.(19|20)\d\d+$/,
            'Is not in correct format'
        ),
    nationality: mixed()
        .nullable()
        .transform((curr, orig) =>
            !curr || orig === '' ? null : curr.toLowerCase()
        )
        .oneOf(Object.values(Nationality)),
    citizenship: mixed()
        .nullable()
        .transform((curr, orig) =>
            !curr || orig === '' ? null : curr.toLowerCase()
        )
        .oneOf(Object.values(Country)),
});

import React, { useCallback, useEffect } from 'react';
import { useForm } from '@travelity/form';
import { Box, Typography } from '@mui/material';
import {
    AddProductForm,
    addProductFormToAddProductDto,
    ProductType,
    useAddProduct,
} from '@travelity/api';

import { useParams } from 'react-router-dom';
import { LoadingOverlay } from '@travelity/ui';
import { useSnackbar } from 'notistack';
import StepContent from './step-content';
import { ProductDetailsForm } from '../../../components/product-details';
import { ProductCapacityForm } from '../../../components/product-capacity-form';
import { ProductRouteForm } from '../../../components/product-route-form';
import { ProductScheduleForm } from '../../../components/product-schedule-form';
import { ProductFinancingForm } from '../../../components/product-financing-form';
import { ProductOptionForm } from '../../../components/product-option-form';
import { useStepperContext } from '../../../components/stepper';
import { useHelperContext } from '../../../contexts/helper-context';
import { ProductConfigurationForm } from '../../../components/product-configuration';
import { useNavigateAbsolute } from '../../../hooks';

export interface PreviewStepProps {}

const PreviewStep: React.FC<PreviewStepProps> = () => {
    const { state } = useStepperContext<AddProductForm>();
    const navigate = useNavigateAbsolute();
    const { enqueueSnackbar } = useSnackbar();
    const { mutate: create, isLoading } = useAddProduct({
        onSuccess: res => {
            navigate('/products');
            if (res) {
                enqueueSnackbar(`Created a product "${res.productInfo.name}"`, {
                    variant: 'success',
                });
            }
        },
    });

    const { setInfo } = useHelperContext();
    useEffect(() => {
        setInfo();
    }, []);

    const { productType } = useParams();

    const onSave = useCallback(
        (e: React.MouseEvent) => {
            e.preventDefault();
            create(
                addProductFormToAddProductDto({
                    ...state,
                    type: productType as ProductType,
                })
            );
        },
        [state]
    );
    const { Form: ConfigurationForm } = useForm({
        defaultValues: state.configuration,
    });
    const { Form: DetailsForm } = useForm({
        defaultValues: state.productInfo,
    });
    const { Form: RouteForm } = useForm({
        defaultValues: state.route,
    });
    const { Form: CapacityForm } = useForm({
        defaultValues: state.capacity,
    });
    const { Form: ScheduleForm } = useForm({
        defaultValues: state.schedule,
    });
    const { Form: FinancialInformationForm } = useForm({
        defaultValues: state.financial,
    });
    const { Form: OptionsForm } = useForm({
        defaultValues: state.options,
    });

    return (
        <>
            <StepContent title="Preview" errors={[]} onClick={onSave}>
                <Box sx={{ width: '451px' }}>
                    {(!!state.configuration.autoscaled ||
                        !!state.configuration.shared) && (
                        <>
                            <Typography
                                sx={{
                                    color: '#36869C',
                                    fontWeight: 600,
                                    mb: 2,
                                }}
                                variant="subtitle1"
                            >
                                Product Configuration
                            </Typography>
                            <ConfigurationForm>
                                <ProductConfigurationForm
                                    preview
                                    hideDisabled
                                />
                            </ConfigurationForm>
                        </>
                    )}
                    <Typography
                        sx={{ color: '#36869C', fontWeight: 600, mt: 3, mb: 2 }}
                        variant="subtitle1"
                    >
                        Product Details
                    </Typography>
                    <DetailsForm>
                        <ProductDetailsForm
                            preview
                            type={productType as ProductType}
                        />
                    </DetailsForm>
                    <Typography
                        sx={{ color: '#36869C', fontWeight: 600, mt: 3, mb: 2 }}
                        variant="subtitle1"
                    >
                        Route
                    </Typography>
                    <RouteForm>
                        <ProductRouteForm preview showFull />
                    </RouteForm>
                    <Typography
                        sx={{ color: '#36869C', fontWeight: 600, mt: 3, mb: 2 }}
                        variant="subtitle1"
                    >
                        Capacity
                    </Typography>
                    <CapacityForm>
                        <ProductCapacityForm preview showFull />
                    </CapacityForm>
                    <Typography
                        sx={{ color: '#36869C', fontWeight: 600, mt: 3, mb: 2 }}
                        variant="subtitle1"
                    >
                        Schedule
                    </Typography>
                    <ScheduleForm>
                        <ProductScheduleForm preview showFull />
                    </ScheduleForm>
                    <Typography
                        sx={{ color: '#36869C', fontWeight: 600, mt: 3, mb: 2 }}
                        variant="subtitle1"
                    >
                        Pricing and Financials
                    </Typography>
                    <FinancialInformationForm>
                        <ProductFinancingForm
                            preview
                            showFull
                            capacities={state.capacity?.capacities}
                            schedules={state.schedule?.items}
                        />
                    </FinancialInformationForm>
                    {!!state.options.items?.length && (
                        <>
                            <Typography
                                sx={{
                                    color: '#36869C',
                                    fontWeight: 600,
                                    mt: 3,
                                    mb: 2,
                                }}
                                variant="subtitle1"
                            >
                                Product Options
                            </Typography>
                            <OptionsForm>
                                <ProductOptionForm
                                    preview
                                    showFull
                                    capacities={state.capacity?.capacities}
                                    schedules={state.schedule?.items}
                                />
                            </OptionsForm>
                        </>
                    )}
                </Box>
            </StepContent>
            {isLoading && (
                <LoadingOverlay
                    title="Data being saved. Thank you for waiting."
                    subTitle="It’ll just take a moment."
                />
            )}
        </>
    );
};

export default React.memo(PreviewStep);

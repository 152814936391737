/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { BookingStatus } from './BookingStatus';
import type { GetBookingsResItem0EventDto } from './GetBookingsResItem0EventDto';
import type { GetBookingsResItem0ProductDto } from './GetBookingsResItem0ProductDto';
import type { GetEventsResItem0BookingsItemAccessDto } from './GetEventsResItem0BookingsItemAccessDto';
import type { GetEventsResItem0BookingsItemFinancialsDto } from './GetEventsResItem0BookingsItemFinancialsDto';
import type { GetEventsResItem0BookingsItemNotesDto } from './GetEventsResItem0BookingsItemNotesDto';
import type { GetEventsResItem0BookingsItemOrderDto } from './GetEventsResItem0BookingsItemOrderDto';
import type { GetEventsResItem0BookingsItemSource0Dto } from './GetEventsResItem0BookingsItemSource0Dto';
import type { GetEventsResItem0BookingsItemSource1Dto } from './GetEventsResItem0BookingsItemSource1Dto';
import type { GetEventsResItem0BookingsItemTransactionsDto } from './GetEventsResItem0BookingsItemTransactionsDto';
import type { GetTeamsResItemCreatedDto } from './GetTeamsResItemCreatedDto';

export type GetOrdersResItemBookingsItem0Dto = {
    /**
     * Type of the resource
     */
    resource_type: GetOrdersResItemBookingsItem0Dto.resource_type;
    /**
     * Source of the booking
     */
    resource_id?: string;
    /**
     * Identifier of the document in the database.
     */
    id: string;
    /**
     * Details about who has created the database record and when.
     */
    created: GetTeamsResItemCreatedDto;
    /**
     * Details about who and when made the most recent update to the record.
     */
    last_updated?: GetTeamsResItemCreatedDto;
    /**
     * Details about who and when deleted/archived the record.
     */
    deleted?: GetTeamsResItemCreatedDto;
    /**
     * Contains info about the cancellation of the resource
     */
    cancelled?: GetTeamsResItemCreatedDto;
    /**
     * Details about all activities happening to the record
     */
    lifecycle?: Array<GetTeamsResItemCreatedDto>;
    /**
     * Source of the booking
     */
    source: (GetEventsResItem0BookingsItemSource0Dto | GetEventsResItem0BookingsItemSource1Dto);
    /**
     * Order reference
     */
    product: GetBookingsResItem0ProductDto;
    /**
     * Booked event
     */
    event?: GetBookingsResItem0EventDto;
    /**
     * Order reference
     */
    order: GetEventsResItem0BookingsItemOrderDto;
    /**
     * List of transactions
     */
    transactions?: GetEventsResItem0BookingsItemTransactionsDto;
    /**
     * List of transactions
     */
    financials: GetEventsResItem0BookingsItemFinancialsDto;
    /**
     * List of notes of some importance to the booking
     */
    notes?: GetEventsResItem0BookingsItemNotesDto;
    /**
     * Automatically computed status of the booking.
     */
    status: BookingStatus;
    /**
     * Access to the shared product
     */
    access: GetEventsResItem0BookingsItemAccessDto;
};

export namespace GetOrdersResItemBookingsItem0Dto {

    /**
     * Type of the resource
     */
    export enum resource_type {
        BOOKING = 'booking',
    }


}

/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { CancelEventsResBookingsItemSource1ReferenceDto } from './CancelEventsResBookingsItemSource1ReferenceDto';
import type { CancelOrdersResAccessDto } from './CancelOrdersResAccessDto';
import type { CancelOrdersResBookingsItem0Dto } from './CancelOrdersResBookingsItem0Dto';
import type { CancelOrdersResBookingsItem1Dto } from './CancelOrdersResBookingsItem1Dto';
import type { CancelOrdersResPriceDto } from './CancelOrdersResPriceDto';
import type { CancelOrdersResSummaryDto } from './CancelOrdersResSummaryDto';
import type { OrderStatus } from './OrderStatus';
import type { UpdateUserResCreatedDto } from './UpdateUserResCreatedDto';

export type CancelOrdersResDto = {
    /**
     * Type of the resource
     */
    resource_type?: CancelOrdersResDto.resource_type;
    /**
     * Identifier of the document in the database.
     */
    id: string;
    /**
     * Details about who has created the database record and when.
     */
    created: UpdateUserResCreatedDto;
    /**
     * Details about who and when made the most recent update to the record.
     */
    last_updated?: UpdateUserResCreatedDto;
    /**
     * Details about who and when deleted/archived the record.
     */
    deleted?: UpdateUserResCreatedDto;
    /**
     * Contains info about the cancellation of the resource
     */
    cancelled?: UpdateUserResCreatedDto;
    /**
     * Details about all activities happening to the record
     */
    lifecycle: Array<UpdateUserResCreatedDto>;
    /**
     * Order number which will be displayed to the customer
     */
    number?: string;
    /**
     * Information about the customer who booked the event.
     */
    customer?: CancelEventsResBookingsItemSource1ReferenceDto;
    /**
     * Price information.
     */
    price?: CancelOrdersResPriceDto;
    /**
     * Status of the order
     */
    status?: OrderStatus;
    /**
     * List of traveler preferences
     */
    bookings?: Array<(CancelOrdersResBookingsItem0Dto | CancelOrdersResBookingsItem1Dto)>;
    /**
     * Access to the shared product
     */
    access?: CancelOrdersResAccessDto;
    /**
     * Order summary information.
     */
    summary?: CancelOrdersResSummaryDto;
};

export namespace CancelOrdersResDto {

    /**
     * Type of the resource
     */
    export enum resource_type {
        ORDER = 'order',
    }


}

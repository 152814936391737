import { useMemo } from 'react';
import { FilterOption, FilterTypes, FilterValue } from './filters.types';

export const useHasFilter = (
    values: Record<string, FilterValue>,
    options: FilterOption[]
) => {
    return useMemo(() => {
        return !!Object.keys(values).filter(
            name =>
                values[name] &&
                options.find(o => name === o.name) &&
                options.find(o => name === o.name)?.type !== FilterTypes.SEARCH
        ).length;
    }, [values, options]);
};

import React, { useMemo, useState } from 'react';
import { Divider } from '@mui/material';
import { useParams } from 'react-router-dom';
import {
    orderFilterToRequestBodyConverter,
    OrderStatuses,
    useOrder,
    useOrdersLazy,
} from '@travelity/api';
import { OrderItem } from '../../components/order-item';

import OrderEditForm from './components/order-preview';
import { OrderItemSkeleton } from '../../components/order-item-skeleton';
import { Filters } from '../../components/filters';
import {
    FilterOption,
    FilterTypes,
} from '../../components/filters/filters.types';
import {
    List,
    ListItems,
    ListMain,
    ListSidebar,
    ListTitle,
} from '../../components/list-layout';
import { useLoadOnScroll } from '../../hooks';
import { useHasFilter } from '../../components/filters/filters.hooks';

const orderFilters: FilterOption[] = [
    {
        name: 'statuses',
        label: 'Statuses',
        type: FilterTypes.DROPDOWN,
        multiple: true,
        selectText: 'Please, select the value for order status',
        options: [
            {
                value: OrderStatuses.OPEN,
                label: 'Open',
            },
            {
                value: OrderStatuses.CONFIRMED,
                label: 'Confirmed',
            },
            {
                value: OrderStatuses.CANCELLED,
                label: 'Cancelled',
            },
        ],
    },
    {
        name: 'createdAt',
        label: 'Created At',
        type: FilterTypes.DATES,
        disable: used =>
            used.includes('updatedAt') ||
            used.includes('updatedBy') ||
            used.includes('deletedAt') ||
            used.includes('deletedBy'),
        selectText: 'Please, select the dates for created at filter',
    },
    {
        name: 'createdBy',
        label: 'Created By',
        type: FilterTypes.KEYWORD,
        disable: used =>
            used.includes('updatedAt') ||
            used.includes('updatedBy') ||
            used.includes('deletedAt') ||
            used.includes('deletedBy'),
        selectText: 'Type user name or email',
    },
    {
        name: 'updatedAt',
        label: 'Updated At',
        type: FilterTypes.DATES,
        disable: used =>
            used.includes('createdAt') ||
            used.includes('createdBy') ||
            used.includes('deletedAt') ||
            used.includes('deletedBy'),
        selectText: 'Please, select the dates for updated at filter',
    },
    {
        name: 'updatedBy',
        label: 'Updated By',
        type: FilterTypes.KEYWORD,
        disable: used =>
            used.includes('createdAt') ||
            used.includes('createdBy') ||
            used.includes('deletedAt') ||
            used.includes('deletedBy'),
        selectText: 'Type user name or email',
    },
    {
        name: 'deletedAt',
        label: 'Canceled At',
        type: FilterTypes.DATES,
        disable: used =>
            used.includes('createdAt') ||
            used.includes('createdBy') ||
            used.includes('updatedAt') ||
            used.includes('updatedBy'),
        selectText: 'Please, select the dates for deleted at filter',
    },
    {
        name: 'deletedBy',
        label: 'Canceled By',
        type: FilterTypes.KEYWORD,
        disable: used =>
            used.includes('createdAt') ||
            used.includes('createdBy') ||
            used.includes('updatedAt') ||
            used.includes('updatedBy'),
        selectText: 'Type user name or email',
    },
    {
        name: 'searchText',
        label: 'Search',
        type: FilterTypes.SEARCH,
        selectText: 'Search product names, customer name, etc...',
    },
];

function Orders() {
    const { orderId } = useParams();

    const [filters, setFilters] = useState({});
    const hasFilters = useHasFilter(filters, orderFilters);

    const {
        data: orders,
        isLoading,
        refetch,
        hasNextPage,
        fetchNextPage,
        isFetchingNextPage,
    } = useOrdersLazy(
        useMemo(() => orderFilterToRequestBodyConverter(filters), [filters])
    );
    // useMemo(() => orderFilterToRequestBodyConverter(filters), [filters])
    const { data: order, isLoading: isOrderLoading } = useOrder(orderId);

    const { onScroll } = useLoadOnScroll({
        hasNextPage: !!hasNextPage && !isFetchingNextPage,
        fetchNextPage,
    });

    return (
        <List>
            <ListMain
                isLoading={isLoading}
                SkeletonComponent={OrderItemSkeleton}
            >
                <ListTitle title="All Orders" />
                <Divider sx={{ mb: 0.25 }} />
                <Filters
                    values={filters}
                    setValues={setFilters}
                    options={orderFilters}
                />
                <ListItems
                    noItemsText="Filter results will be here"
                    scrollbarProps={{
                        onScrollY: onScroll,
                    }}
                    items={orders?.length}
                    isLoading={isLoading}
                    entityName="order"
                    entityNamePlural="orders"
                    subtractHeight={72 + 1 + (hasFilters ? 116 : 60)}
                >
                    {orders?.map(o => (
                        <OrderItem
                            key={o.id}
                            order={o}
                            refetch={refetch}
                            isSelected={o.id === orderId}
                        />
                    ))}
                    {hasNextPage && <OrderItemSkeleton />}
                </ListItems>
            </ListMain>
            <ListSidebar
                isEmpty={!orderId}
                placeholderText="No order is selected for preview"
            >
                {order && (
                    <OrderEditForm order={order} isLoading={isOrderLoading} />
                )}
            </ListSidebar>
        </List>
    );
}

export default Orders;
